import { useUpload } from '@/hooks/useUpload';
import {
  addingNewCaptureState,
  groupedByHostCaptureListState,
} from '@/state/captureState';
import { currentUserInfoState } from '@/state/userState';
import { ProcessServerConfigFunction } from 'filepond';
import { User } from 'firebase/auth';
import { useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ImgSize } from '../capture/types';
import FileUploadModal from '../modal/FileUploadModal';
import ArrowUpShare from '../icon/ArrowUpShare';
import ArrowDown from '../icon/ArrowDown';
import { useClickOutside } from '@/hooks/useClickOutside';
import AddProjectModal from '../modal/AddProjectModal';
import CreateCaptureIcon from '../icon/CreateCaptureIcon';
import AddProjectIcon from '../icon/AddProjectIcon';
import { useLocation } from 'react-router-dom';
import SignUp from './SignUp';

type Props = {
  group?: string;
  projectName?: string;
};

const HeaderButton: React.FC<Props> = ({ group, projectName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCreateProjectOpen, setIsCreateProjectOpen] = useState(false);
  const { uploadImage, uploadPDF } = useUpload(group, projectName);
  const setAddingNew = useSetRecoilState(addingNewCaptureState);
  const url = window.URL || window.webkitURL;
  const captureListAtHost = useRecoilValue(groupedByHostCaptureListState);
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const [isDropdownShow, setIsDropdownShow] = useState(false);
  const ref = useRef(null);
  const location = useLocation();
  const [progress, setProgress] = useState<Number | null>(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  useClickOutside(ref, () => {
    if (isDropdownShow) {
      setIsDropdownShow(false);
    }
  });

  const handleProcess: ProcessServerConfigFunction = async (
    _fieldName,
    file,
    _metadata,
    load,
    error,
    progress,
    _abort
  ) => {
    if (file.type === 'application/pdf') {
      try {
        await uploadPDF(file, file.name, (path: string) => {
          // navigate(path);
          load('done');
          closeModal();
          setAddingNew(true);
          setProgress(null);
        }, (progress: number) => {
          setProgress(progress);
        });
      } catch (err) {
        error('UPLOAD IS FAILED');
        console.log(err);
      }
    } else if (file.type.match(/^image/)) {
      try {
        const img = new Image();
        img.onload = async function () {
          const imgSize: ImgSize = {
            width: img.width,
            height: img.height,
          };
          await uploadImage(
            file,
            file.name,
            imgSize,
            progress,
            (path: string) => {
              // navigate(path);
              load('done');
              closeModal();
              setAddingNew(true);
            }
          );
        };
        img.src = url.createObjectURL(file);
      } catch (err) {
        error('UPLOAD IS FAILED');
        console.log(err);
      }
    } else {
      error('This file type is not allowed here.');
    }
  };
  const disabled = location.pathname.includes('/project');

  if (currentUserInfo.isAnonymous) {
    return <SignUp />;
  }

  return (
    <button
      className={`flex items-center px-6 justify-between py-2 mx-4 sm:hidden border-100 custom-green-btn hover:opacity-100 bg-green-updated relative ${'cursor-pointer'}`}
      ref={ref}
      onClick={() => {
        setIsDropdownShow(true);
      }}
    >
      {/* <Popover
          className={`sm:order-first w-2`}
          // onClick={handleClick}
        >
          <Popover.Button
            className={`has-tooltip disabled relative flex justify-center items-center px-2 rounded-3xl text-black bg-white text-sm leading-6 rounded hover:opacity-100 sm:h-full  sm:px-3 sm:py-3`}
          >
            Create a Project
            <span className="tooltip w-72 mt-14 tool-tip-txt shadow-2xl">
              {
                'limit Exceeded'
              }
            </span>
          </Popover.Button>
        </Popover> */}
      {/* <button
        className={`w-full flex items-center justify-between text-sm font-bold ${
          limitExceed ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
        disabled={limitExceed}
      > */}
      Create
      <span className="ml-2.5">
        {isDropdownShow ? (
          <ArrowUpShare width={20} height={20} />
        ) : (
          <ArrowDown width={20} height={20} />
        )}
      </span>
      {/* </button> */}
      <div className="w-full h-full">
        {isOpen && (
          <FileUploadModal
            isReplaceImage={false}
            isOpen={isOpen}
            closeModal={closeModal}
            onProcess={handleProcess}
            group={group}
            progress={progress}
          />
        )}
        {isCreateProjectOpen && (
          <AddProjectModal
            isOpen={isCreateProjectOpen}
            closeModal={() => setIsCreateProjectOpen(false)}
          />
        )}
      </div>
      {isDropdownShow && (
        <div className="absolute left-0 top-12">
          <div
            className="w-52 bg-white border border-solid border-black divide-gray-100 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            tabIndex={1}
          >
            <div className="py-2" role="none">
              <button
                className={
                  'text-black font-medium px-5 py-2 h-12 group flex items-center w-full text-sm hover:bg-grey-200 dropdown-item sider-bar-text'
                }
                role="menuitem"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <span className="flex items-center gap-2">
                  <CreateCaptureIcon /> Screen Capture
                </span>
              </button>
              <button
                className={`text-black font-medium px-5 py-2 h-12 group flex items-center w-full text-sm hover:bg-grey-200 dropdown-item sider-bar-text ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                  }`}
                role="menuitem"
                disabled={disabled}
                onClick={() => {
                  setIsCreateProjectOpen(true);
                }}
              >
                <span className="flex items-center gap-2">
                  <AddProjectIcon />
                  Project Folder
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </button>
  );
};

export default HeaderButton;
