type Props = {
  classname: string;
  strokeColor?: string;
};

const AnnotateIcon = ({ classname, strokeColor }: Props) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 20.6667L4.67285 11.0254C5.40722 9.09772 7.93356 8.71661 9.1458 10.3857C9.92408 11.4573 10.7954 12.6931 11.6667 14C15.8505 20.2757 18.3333 1.33333 18.3333 1.33333"
        stroke={strokeColor ? strokeColor : '#808080'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AnnotateIcon;
