import { Dialog } from '@headlessui/react';
import { FC } from 'react';
import Template from './Template';

type Props = {
  isDeleteModalOpen: boolean;
  closeModal: () => void;
  Proceed: (event: any) => void;
  deleteType: string;
};

const DeleteModal: FC<Props> = ({
  isDeleteModalOpen,
  closeModal,
  Proceed,
  deleteType,
}) => {
  return (
    <Template isOpen={isDeleteModalOpen} closeModal={closeModal}>
      <div className="inline-block DeleteModal w-full py-12 px-16 max-w-2xl  overflow-hidden text-left align-middle transition-all transform rounded-xl bg-white sm:mt-6 default-modal sm:mx-5">
        <Dialog.Title as="h3" className="text-2xl text-gray-900 leading-8">
          <div className="flex justify-center">
            <div className="flex -space-x-2 overflow-hidden">
              <svg
                width="124"
                height="108"
                viewBox="0 0 124 108"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M118.276 64.065C119.993 60.8325 120.976 57.1575 120.976 53.25C120.976 43.3185 114.686 34.8645 105.852 31.533C105.852 31.5225 105.854 31.512 105.854 31.5C105.854 14.103 91.636 0 74.0976 0C59.3022 0 46.9068 10.0515 43.3803 23.6355C41.1483 22.908 38.7697 22.5 36.2927 22.5C24.6609 22.5 15.0872 31.1895 13.7761 42.378C5.90664 44.0295 0 50.9535 0 59.25C0 68.7765 7.78629 76.5 17.3902 76.5C17.6579 76.5 17.9225 76.482 18.1857 76.467C18.1796 76.7295 18.1463 76.986 18.1463 77.25C18.1463 94.233 32.0253 108 49.1463 108C58.8803 108 67.5573 103.542 73.2417 96.579C76.5352 99.9165 81.1141 102 86.1951 102C93.2268 102 99.3119 98.025 102.324 92.223C103.915 92.7255 105.607 93 107.366 93C116.552 93 124 85.6125 124 76.5C124 71.5335 121.777 67.0905 118.276 64.065Z"
                  fill="#ABFF57"
                />
                <path
                  d="M43.8537 67.5001H52.5488C56.3066 68.6251 59.3537 65.6026 59.3537 61.8751C59.3537 58.3756 56.659 55.5316 53.2218 55.1926C53.255 54.9211 53.3049 54.6556 53.3049 54.3751C53.3049 50.6476 50.2578 47.6251 46.5 47.6251C44.3255 47.6251 42.4126 48.6541 41.1665 50.2291C40.9548 46.2751 37.6855 43.1251 33.6464 43.1251C29.4712 43.1251 26.0854 46.4836 26.0854 50.6251C26.0854 51.2941 26.2018 51.9301 26.3682 52.5481C25.3974 51.3826 23.9487 50.6251 22.3049 50.6251C19.6117 50.6251 17.4145 52.6276 17.0803 55.2076C16.8066 55.1746 16.5389 55.1251 16.2561 55.1251C12.4983 55.1251 9.45126 58.1476 9.45126 61.8751C9.45126 65.6026 12.4983 68.6251 16.2561 67.5001H30.622H39.3171V69.0001H43.8537V67.5001Z"
                  fill="white"
                />
                <path
                  d="M34.0245 42C29.438 42 25.7075 45.7005 25.7075 50.25C25.7075 50.274 25.7075 50.2965 25.7075 50.3205C24.7971 49.788 23.7567 49.5 22.6831 49.5C19.9142 49.5 17.525 51.3915 16.849 54.0075C16.7779 54.003 16.7069 54 16.6343 54C11.948 54 8.24311 58.251 9.23511 63.0705C9.96097 66.597 13.3014 69 16.9307 69H47.1652C47.5826 69 47.9213 68.664 47.9213 68.25C47.9213 67.836 47.5826 67.5 47.1652 67.5H16.8717C13.9683 67.5 11.2962 65.577 10.7156 62.7555C9.92165 58.9005 12.8856 55.5 16.6343 55.5C16.8112 55.5 16.9821 55.5255 17.153 55.548L17.3662 55.5765C17.3979 55.581 17.4282 55.5825 17.4599 55.5825C17.8319 55.5825 18.1586 55.305 18.2085 54.927C18.4973 52.6875 20.4208 51 22.6831 51C24.0214 51 25.2886 51.6015 26.1626 52.65C26.3108 52.827 26.5271 52.923 26.7463 52.923C26.8643 52.923 26.9822 52.896 27.0911 52.839C27.4072 52.6785 27.5659 52.32 27.4752 51.9795C27.2333 51.0795 27.1697 50.376 27.2529 49.656C27.6158 46.4655 30.2153 43.7925 33.4408 43.524C37.2984 43.2015 40.5859 46.116 40.7886 49.893C40.8052 50.205 41.0154 50.4735 41.3163 50.568C41.3919 50.592 41.4691 50.604 41.5462 50.604C41.773 50.604 41.9938 50.5005 42.139 50.316C43.3064 48.8445 45.0318 48 46.8782 48C50.2141 48 52.927 50.691 52.927 54C52.927 54.1755 52.9013 54.345 52.8786 54.5145L52.8498 54.726C52.8257 54.927 52.8831 55.128 53.0101 55.2855C53.1372 55.443 53.3232 55.5435 53.5243 55.563C57.081 55.914 59.6366 59.2065 58.823 62.8605C58.206 65.64 55.5355 67.5 52.6654 67.5H43.6648C43.2474 67.5 42.9087 67.836 42.9087 68.25C42.9087 68.664 43.2474 69 43.6648 69H52.6442C56.3748 69 59.7636 66.444 60.3791 62.793C61.0777 58.6485 58.3467 54.963 54.4346 54.183C54.4376 54.123 54.4392 54.0615 54.4392 54C54.4392 49.8645 51.0473 46.5 46.8782 46.5C45.0998 46.5 43.4107 47.124 42.0649 48.246C41.1606 44.64 37.8882 42 34.0245 42Z"
                  fill="black"
                />
                <path
                  d="M31.3781 52.8749C29.2565 52.8749 27.4328 54.3734 26.973 56.3864C26.4181 56.0534 25.7845 55.8749 25.1403 55.8749C23.3226 55.8749 21.8165 57.2204 21.5821 58.9769C21.2751 58.9094 20.9832 58.8749 20.6974 58.8749C18.3853 58.8749 16.4467 60.6749 16.2849 62.9729C16.2712 63.1799 16.427 63.3584 16.6357 63.3734C16.6448 63.3734 16.6538 63.3734 16.6629 63.3734C16.8595 63.3734 17.0258 63.2219 17.0394 63.0254C17.174 61.1189 18.78 59.6234 20.6974 59.6234C21.0437 59.6234 21.4082 59.6864 21.8134 59.8139C21.8467 59.8244 21.8815 59.8289 21.9148 59.8289C21.9979 59.8289 22.0811 59.7989 22.1507 59.7479C22.2474 59.6774 22.3049 59.5544 22.3049 59.4344C22.3049 57.8834 23.5767 56.6219 25.1403 56.6219C25.8162 56.6219 26.4786 56.8739 27.0018 57.3314C27.0729 57.3929 27.1621 57.4259 27.2513 57.4259C27.2997 57.4259 27.3466 57.4169 27.3919 57.3974C27.525 57.3449 27.6157 57.2219 27.6263 57.0794C27.7806 55.1414 29.4273 53.6219 31.375 53.6219C31.6896 53.6219 32.0177 53.6729 32.4064 53.7824C32.4411 53.7929 32.4774 53.7974 32.5122 53.7974C32.677 53.7974 32.8252 53.6879 32.8736 53.5244C32.9311 53.3249 32.8147 53.1179 32.6135 53.0609C32.1599 52.9349 31.7652 52.8749 31.3781 52.8749Z"
                  fill="black"
                />
                <path
                  d="M52.3718 55.1251C50.3666 55.1251 48.5792 56.4706 48.0257 58.3981C47.9683 58.5976 48.0847 58.8046 48.2858 58.8616C48.3206 58.8721 48.3554 58.8766 48.3886 58.8766C48.5535 58.8766 48.7047 58.7686 48.7516 58.6036C49.2128 56.9971 50.7008 55.8766 52.3703 55.8766C52.5472 55.8766 52.7181 55.8976 52.8874 55.9201C52.9056 55.9231 52.9222 55.9246 52.9404 55.9246C53.1233 55.9246 53.2866 55.7866 53.3124 55.5991C53.3411 55.3936 53.1959 55.2046 52.9887 55.1776C52.7876 55.1491 52.582 55.1251 52.3718 55.1251Z"
                  fill="black"
                />
                <path
                  d="M73.0949 21.6001C74.1474 21.6001 75.0048 22.4491 75.0048 23.4946V28.6501H58.0682V23.4931C58.0682 22.4491 58.9241 21.5986 59.9781 21.5986H73.0949V21.6001ZM73.0949 19.5001H59.9766C57.7537 19.5001 55.9512 21.2881 55.9512 23.4946V30.7501H77.1219V23.4946C77.1219 21.2881 75.3194 19.5001 73.0949 19.5001Z"
                  fill="black"
                />
                <path
                  d="M49.8373 91.9501C47.1441 91.9501 44.9272 90 44.7911 87.51L40.4753 40.05H92.5992L88.2849 87.4725C88.1458 90 85.9289 91.9501 83.2357 91.9501H49.8373Z"
                  fill="#F7F5EE"
                />
                <path
                  d="M91.4392 41.1L87.2308 87.3795L87.2278 87.417L87.2263 87.4545C87.1204 89.3865 85.3678 90.9 83.2356 90.9H49.8387C47.7066 90.9 45.9539 89.3865 45.8481 87.4545L45.8466 87.417L45.8435 87.3795L41.6336 41.1H91.4392ZM93.7559 39H93.7635H93.7559ZM93.7559 39H39.3169L43.734 87.567C43.9004 90.612 46.5845 93 49.8387 93H83.2356C86.4898 93 89.1725 90.612 89.3388 87.567L93.7559 39Z"
                  fill="black"
                />
                <path
                  d="M39.317 39.45C37.3981 39.45 35.839 37.9035 35.839 36C35.839 34.0965 37.3981 32.55 39.317 32.55H43.6434L44.7201 29.709C45.449 27.7905 46.9113 26.55 48.4477 26.55H84.6254C86.1618 26.55 87.6226 27.7905 88.353 29.7105L89.4297 32.55H93.7561C95.675 32.55 97.2341 34.0965 97.2341 36C97.2341 37.9035 95.675 39.45 93.7561 39.45H39.317Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M84.6254 27.6C85.7157 27.6 86.7908 28.5735 87.3624 30.081L88.1821 32.2395L88.6977 33.6H90.163H93.756C95.0898 33.6 96.1755 34.677 96.1755 36C96.1755 37.323 95.0898 38.4 93.756 38.4H39.317C37.9832 38.4 36.8975 37.323 36.8975 36C36.8975 34.677 37.9832 33.6 39.317 33.6H42.91H44.3753L44.8909 32.2395L45.7105 30.081C46.2822 28.5735 47.3573 27.6 48.4476 27.6H84.6254ZM84.6254 25.5H48.4476C46.45 25.5 44.6233 26.988 43.7296 29.3415L42.91 31.5H39.317C36.8113 31.5 34.7804 33.5145 34.7804 36C34.7804 38.4855 36.8113 40.5 39.317 40.5H93.756C96.2617 40.5 98.2926 38.4855 98.2926 36C98.2926 33.5145 96.2617 31.5 93.756 31.5H90.163L89.3434 29.3415C88.4497 26.988 86.623 25.5 84.6254 25.5Z"
                  fill="black"
                />
                <path
                  d="M88.621 33.0001H44.6098C44.1924 33.0001 43.8537 32.6641 43.8537 32.2501C43.8537 31.8361 44.1924 31.5001 44.6098 31.5001H88.621C89.0383 31.5001 89.3771 31.8361 89.3771 32.2501C89.3771 32.6641 89.0383 33.0001 88.621 33.0001Z"
                  fill="black"
                />
                <path
                  d="M69.2766 64.8854L80.2309 54.0194C81.0959 53.1629 81.0959 51.7724 80.2309 50.9144C79.366 50.0564 77.9657 50.0564 77.1007 50.9144L66.1463 61.7804L55.192 50.9144C54.327 50.0564 52.9267 50.0564 52.0618 50.9144C51.1968 51.7724 51.1968 53.1614 52.0618 54.0194L63.0161 64.8854L52.0618 75.7514C51.1968 76.6079 51.1968 77.9984 52.0618 78.8564C52.9267 79.7144 54.327 79.7144 55.192 78.8564L66.1463 67.9904L77.1007 78.8564C77.9657 79.7144 79.366 79.7144 80.2309 78.8564C81.0959 77.9984 81.0959 76.6094 80.2309 75.7514L69.2766 64.8854Z"
                  fill="#FF0000"
                />
                <path
                  d="M53.6269 80.2502C52.8346 80.2502 52.089 79.9442 51.528 79.3877C50.967 78.8312 50.6585 78.0932 50.6585 77.3057C50.6585 76.5182 50.967 75.7787 51.528 75.2222L61.9486 64.8857L51.528 54.5477C50.3697 53.3987 50.3697 51.5312 51.528 50.3837C52.6879 49.2332 54.5706 49.2362 55.7259 50.3837L66.1464 60.7202L76.5685 50.3837C77.7283 49.2332 79.611 49.2362 80.7663 50.3837C81.9246 51.5327 81.9246 53.4002 80.7663 54.5477L70.3458 64.8857L80.7663 75.2222C81.9246 76.3712 81.9246 78.2387 80.7663 79.3862C79.6095 80.5337 77.7268 80.5367 76.5685 79.3862L66.1464 69.0497L55.7259 79.3862C55.1649 79.9442 54.4209 80.2502 53.6269 80.2502ZM66.1464 66.9302L77.6376 78.3272C78.185 78.8702 79.1467 78.8732 79.6972 78.3272C79.9724 78.0542 80.1236 77.6912 80.1236 77.3057C80.1236 76.9187 79.9709 76.5557 79.6972 76.2827L68.2075 64.8857L79.6972 53.4872C80.2658 52.9232 80.2658 52.0082 79.6972 51.4442C79.1301 50.8832 78.2046 50.8802 77.6376 51.4442L66.1464 62.8412L54.6568 51.4442C54.1093 50.8997 53.1476 50.8997 52.5971 51.4442C52.3219 51.7172 52.1707 52.0802 52.1707 52.4657C52.1707 52.8527 52.3234 53.2157 52.5971 53.4887L64.0868 64.8872L52.5971 76.2842C52.3219 76.5572 52.1707 76.9202 52.1707 77.3072C52.1707 77.6927 52.3234 78.0557 52.5971 78.3287C53.1476 78.8732 54.1078 78.8717 54.6568 78.3287L66.1464 66.9302Z"
                  fill="black"
                />
                <path
                  d="M15.878 88.5001H12.8536C12.4362 88.5001 12.0975 88.1641 12.0975 87.7501C12.0975 87.3361 12.4362 87.0001 12.8536 87.0001H15.878C16.2954 87.0001 16.6341 87.3361 16.6341 87.7501C16.6341 88.1641 16.2969 88.5001 15.878 88.5001Z"
                  fill="white"
                />
                <path
                  d="M106.232 71.25C106.232 71.25 108.601 71.25 111.525 71.25C114.448 71.25 116.817 68.8995 116.817 66C116.817 63.3285 114.798 61.149 112.197 60.8175C112.24 60.549 112.281 60.2805 112.281 60C112.281 57.1005 109.911 54.75 106.988 54.75C105.427 54.75 104.039 55.4325 103.071 56.4975C102.491 53.7855 100.067 51.75 97.1587 51.75C93.8182 51.75 91.1099 54.4365 91.1099 57.75C91.1099 58.0365 91.1553 58.311 91.1946 58.587C90.5474 58.071 89.7353 57.75 88.8416 57.75C86.9846 57.75 85.4467 59.0805 85.1292 60.8325C84.8585 60.7905 84.5878 60.75 84.305 60.75C81.3819 60.75 79.0123 63.1005 79.0123 66C79.0123 68.8995 81.3819 71.25 84.305 71.25C87.2281 71.25 95.6465 71.25 95.6465 71.25V72H106.232V71.25Z"
                  fill="white"
                />
                <path
                  d="M108.878 64.4997C108.669 64.4997 108.5 64.3317 108.5 64.1247C108.5 62.2902 110.005 60.7977 111.854 60.7977C111.905 60.8112 112.969 60.7962 113.735 61.0017C113.936 61.0557 114.056 61.2612 114.001 61.4607C113.947 61.6617 113.74 61.7802 113.539 61.7247C112.867 61.5447 111.875 61.5447 111.856 61.5477C110.422 61.5477 109.256 62.7042 109.256 64.1247C109.256 64.3317 109.087 64.4997 108.878 64.4997Z"
                  fill="black"
                />
                <path
                  d="M111.524 72H106.232C105.814 72 105.476 71.664 105.476 71.25C105.476 70.836 105.814 70.5 106.232 70.5H111.524C114.026 70.5 116.061 68.481 116.061 66C116.061 63.756 114.36 61.848 112.102 61.5615C111.899 61.5345 111.716 61.4295 111.594 61.2675C111.471 61.1055 111.42 60.9015 111.452 60.702C111.487 60.471 111.524 60.24 111.524 60C111.524 57.519 109.489 55.5 106.988 55.5C105.702 55.5 104.511 56.0325 103.632 57C103.443 57.2085 103.147 57.297 102.876 57.222C102.601 57.1485 102.391 56.9295 102.332 56.6535C101.816 54.2475 99.6401 52.5 97.1585 52.5C94.24 52.5 91.8659 54.855 91.8659 57.75C91.8659 57.9645 91.8976 58.17 91.9279 58.377C91.9717 58.6815 91.8326 59.034 91.5619 59.187C91.2912 59.34 90.9631 59.364 90.7211 59.172C90.1692 58.7325 89.5204 58.5 88.8415 58.5C87.3807 58.5 86.1316 59.5365 85.873 60.966C85.8004 61.3665 85.4254 61.635 85.0126 61.5735C84.7797 61.5375 84.5468 61.5 84.3049 61.5C81.8037 61.5 79.7683 63.519 79.7683 66C79.7683 68.481 81.8037 70.5 84.3049 70.5H106.697C107.115 70.5 107.453 70.836 107.453 71.25C107.453 71.664 107.115 72 106.697 72H84.3049C80.969 72 78.2561 69.309 78.2561 66C78.2561 62.691 80.969 60 84.3049 60C84.3941 60 84.4803 60.003 84.568 60.009C85.1941 58.23 86.8923 57 88.8415 57C89.3692 57 89.8834 57.0915 90.3703 57.27C90.6198 53.7705 93.5686 51 97.1585 51C99.9274 51 102.4 52.6905 103.439 55.161C104.467 54.414 105.71 54 106.988 54C110.324 54 113.037 56.691 113.037 60C113.037 60.072 113.035 60.1425 113.031 60.213C115.666 60.885 117.573 63.2535 117.573 66C117.573 69.309 114.86 72 111.524 72Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </Dialog.Title>
        <div className="flex">
          <div className="text-center">
            <h4 className="pt-8">{`Are you sure you want to delete the ${deleteType}?`}</h4>
            <p className="pt-3">This action cannot be undone later.</p>
          </div>
        </div>
        <div className="pt-6">
          <div className="flex space-x-3  flex-wrap justify-center items-baseline sm:justify-between">
            <button className="cancel-modal-btn" onClick={closeModal}>
              Cancel
            </button>
            <button
              className="delete-modal-btn"
              onClick={async (event: any) => {
                await closeModal();
                await Proceed(event);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default DeleteModal;
