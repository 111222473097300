const ArrowBackword = () => (
  <svg
    className="mx-auto min-arrow cursor-pointer"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="25"
      y="25"
      width="24"
      height="24"
      rx="12"
      transform="rotate(-180 25 25)"
      fill="white"
    />
    <path d="M21 5H5V21H21V5Z" fill="white" fillOpacity="0.01" />
    <path
      d="M6.93298 13H18.933"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9329 17L6.93292 13L10.9329 9"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="25"
      y="25"
      width="24"
      height="24"
      rx="12"
      transform="rotate(-180 25 25)"
      stroke="black"
    />
  </svg>
);

export default ArrowBackword;
