const TickSmall = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className="cursor-pointer"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
      fill="#F2F2F2"
    />
    <path
      d="M7.5 12L10.875 15L16.5 9"
      stroke="#808080"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 22.5C6.20101 22.5 1.5 17.799 1.5 12H0.5C0.5 18.3513 5.64873 23.5 12 23.5V22.5ZM22.5 12C22.5 17.799 17.799 22.5 12 22.5V23.5C18.3513 23.5 23.5 18.3513 23.5 12H22.5ZM12 1.5C17.799 1.5 22.5 6.20101 22.5 12H23.5C23.5 5.64873 18.3513 0.5 12 0.5V1.5ZM12 0.5C5.64873 0.5 0.5 5.64873 0.5 12H1.5C1.5 6.20101 6.20101 1.5 12 1.5V0.5Z"
      fill="#808080"
    />
  </svg>
);
export default TickSmall;
