type Props = {
    width?: number,
    height?: number
    customClass?: string
    rotate?: number
}
const ImageCursordefault = (props: Props) => (
    <svg
        className={`${props.customClass}`}
        // style={{ margin: '0.5rem' }}
        width={`${props.width ? props.width : 32}`}
        height={`${props.height ? props.height : 32}`}
        transform={`rotate(${props.rotate ? props.rotate : '0'})`}
        viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3565_135412)">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.2962 14.2964L24.7657 15.3992C26.8994 15.6239 28.8113 14.0764 29.0361 11.9427C29.2608 9.80895 27.7133 7.89706 25.5796 7.67232L13.43 6.39263C9.37966 5.96602 5.96582 9.37985 6.39243 13.4302L7.67212 25.5798C7.89686 27.7135 9.80875 29.261 11.9425 29.0363C14.0762 28.8115 15.6237 26.8996 15.399 24.7659L14.2962 14.2964Z" fill="#FF38A4" />
        </g>
        <defs>
            <clipPath id="clip0_3565_135412">
                <rect width="32" height="32" fill="white" />

            </clipPath>
        </defs>
    </svg>


);

export default ImageCursordefault;