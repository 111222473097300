const ArrowWithOutCirle = () => (
  <svg
    width="11"
    height="12"
    className="cursor-pointer mr-2"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http:www.w3.org/2000/svg"
  >
    <path
      d="M5.5 1L1 5.5L5.5 9.75V7C9 7 10.5 10.75 10.5 10.75C10.5 6.5 9.25 3.75 5.5 3.75V1Z"
      stroke="#808080"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ArrowWithOutCirle;
