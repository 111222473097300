import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import {
  appState,
  deletingExpiredCapturesState,
  hasAuthProblemState,
  isAppLoadingState,
} from '@/state/appState';
import { currentUserIDState, currentUserInfoState } from '@/state/userState';
import * as firebase from '@/lib/firebase/auth';
import * as firestore from '@/lib/firebase/firestore';
import { isNeedEmailState } from '@/state/userState';
import type { User, UserCredential } from 'firebase/auth';
import { syncWithExtensionState } from '@/state/uiState';
import { useIntercom } from './useIntercom';
import { getAuth, signOut as signOutFirebase } from 'firebase/auth';
import toast from 'react-hot-toast';
import * as chromeExtension from '@/lib/chromeExtension';
import { useMixpanel } from './useMixpanel';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useOrganization } from '@clerk/clerk-react';
import { httpsCallable } from 'firebase/functions';

type LinkToGoogleConfig = {
  /**
   * Using appState blocking app flow
   */
  blockingMode: boolean;
};

export const useAuth = () => {
  const mixpanel = useMixpanel();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const isNeedEmail = useRecoilValue(isNeedEmailState);
  const setSyncWithExtension = useSetRecoilState(syncWithExtensionState);
  const setHasAuthProblem = useSetRecoilState(hasAuthProblemState);
  const isToastShown = useRef(false);

  const [isReady, setIsReady] = useRecoilState(appState);
  const [isAppLoading, setIsAppLoading] = useRecoilState(isAppLoadingState);
  const [deletingExpiredCaptures, setDeletingExpiredCaptures] = useRecoilState(deletingExpiredCapturesState);

  const [currentUserID, setCurrentUserID] = useRecoilState(currentUserIDState);
  const [currentUserInfo, setCurrentUserInfo] =
    useRecoilState(currentUserInfoState);

  const intercom = useIntercom();
  const auth = getAuth();
  const { organization } = useOrganization()

  useEffect(() => {
    if(!location.search.includes('license_key=')){
      isToastShown.current = true;
    }
  }, [location.search]);

  const setAuth = async (user: any) => {
    setCurrentUserID(user.uid);

    let storedUserInfo: any = {};
    if (user?.uid) {

      storedUserInfo = await getStoredUserInfo(user.uid);
      if (storedUserInfo == false) {
        const payload = {
          photoURL: null,
          ...user,
          isAnonymous: false,
          linkedinUrl: '',
          twitterUrl: '',
          plan: 'free', //free, pro
          stripeSubscriptionId: null, //free, pro
          planTeam: null, //team, null
          stripeSubscriptionIdTeam: null, //team, null
          stripeCustomerId: null,
          cancelledOn: null,
          expiresOn: null,
          appSumoLicenseKey: location.search.includes('license_key=') ? searchParams.get('license_key') : null,
          // appSumoLicenseKey: null,
          tier: location.search.includes('tier=') ? searchParams.get('tier') : null,
          // tier: null,
          licenseStatus: location.search.includes('status=') ? searchParams.get('status') : null,
          // licenseStatus: null,
          teamCancelledOn: null,
          teamExpiresOn: null,
        };

        // console.log("payload ::", payload)
        const result = await firestore.setUser(user.uid, payload);

        if(location.search.includes('license_key=')){
          searchParams.delete('license_key');
          searchParams.delete('tier');
          searchParams.delete('status');
          setSearchParams(searchParams);
        }
      } else {

      console.log("Check license key set authentication")
        if(location.search.includes('license_key=')){
          const licenseKey = searchParams.get('license_key')
          const licenseTier = searchParams.get('tier')
          const licenseStatus = searchParams.get('status')

          const {data: isUsedLicenseKey}: any = await httpsCallable(
            firebase.functions,
            'isUsedLicenseKey'
            )(licenseKey);
            
          if(!isUsedLicenseKey){
            await firestore.updateUser(user.uid, { appSumoLicenseKey: licenseKey ?? null, tier: licenseTier?? null, licenseStatus: licenseStatus?? null})
             if(!isToastShown.current){
               toast.success(
                "Your appSumo license key has been set successfully"
              )
             }
             searchParams.delete('license_key');
             searchParams.delete('tier');
             searchParams.delete('status');
             setSearchParams(searchParams);
          }else{
            if(!isToastShown.current) {

              toast.error(
                "License key already belongs to a user."
              )
            }
            searchParams.delete('license_key');
            searchParams.delete('tier');
            searchParams.delete('status');
            setSearchParams(searchParams);
          }
        }

        storedUserInfo.isAnonymous = false;
      }
    }
    if (user.isAnonymous) {
      const result = await firestore.getAnonymousPopulation();
      user.displayName = `Guest-${result}`;
      chromeExtension.sendMessage({
        type: 'clerkUserState',
        data: { isSignedInWithClerk: false, uid: null },
      });
    } else {
      if (user && user.email) {
        mixpanel.setUser(user.email, user.displayName || '', user.uid);
        if (user?.photoURL) {
          mixpanel.setAvatar(user.photoURL);
        }
      }
    
      chromeExtension.sendMessage({
        type: 'clerkUserState',
        data: { isSignedInWithClerk: true, 
          uid: user.uid, 
          isOrganization: organization ? true : false,
          organizationId: organization?.id ?? null
        },
      });
    }

    setCurrentUserInfo({
      photoURL: user?.photoURL
        ? user.photoURL
        : storedUserInfo?.photoURL ?? null,
      email: storedUserInfo?.email || '',
      ...user,
      linkedinUrl: storedUserInfo?.linkedinUrl || '',
      twitterUrl: storedUserInfo?.twitterUrl || '',
      plan: storedUserInfo?.plan || 'free', //free, pro
      planTeam: storedUserInfo?.planTeam || null, //team, null
      stripeSubscriptionId: storedUserInfo?.stripeSubscriptionId || null, //free, pro
      stripeSubscriptionIdTeam: storedUserInfo?.stripeSubscriptionIdTeam || null, //team, null
      stripeCustomerId: storedUserInfo?.stripeCustomerId || null,
      cancelledOn: storedUserInfo?.cancelledOn || null,
      expiresOn: storedUserInfo?.expiresOn || null,
      appSumoLicenseKey: storedUserInfo?.appSumoLicenseKey ??  null,
      tier: storedUserInfo?.tier ??  null,
      licenseStatus: storedUserInfo?.licenseStatus ??  null,
      teamCancelledOn: storedUserInfo?.teamCancelledOn || null,
      teamExpiresOn: storedUserInfo?.teamExpiresOn || null,
    });

    if(storedUserInfo && Object.keys(storedUserInfo).length > 0){      
      setDeletingExpiredCaptures(true);
      const { uid } = storedUserInfo;

      try{
        const {data: response}: any = await httpsCallable(
          firebase.functions,
          'deleteExpiredCaptures'
          )({uid});
      }catch(e){
        console.log("Error on deleteExpiredCaptures function : ",e)
      }
      setDeletingExpiredCaptures(false);
    }

    if (user.isAnonymous) {
      // if (user?.email) {
      //   setCurrentUserInfo({ ...user, email: storedUserInfo.email });
      //   intercom.setting({ ...user, email: storedUserInfo.email });
      //   return false;
      // }
    } else {
      intercom.init();
      intercom.setting(user);
    }
    if (!isAppLoading) setIsAppLoading(false);
  };

  const linkToGoogle = (
    //no more used
    cb?: Function,
    onSuccessCb?: Function,
    config: LinkToGoogleConfig = { blockingMode: true }
  ) => {
    if (config.blockingMode) {
      setIsReady(false);
    }
    firebase.linkToGoogle(
      () => {
        setIsReady(true);
        cb && cb();
      },
      (user: User) => {
        onSuccessCb && onSuccessCb();
        setAuth(user);
      }
    );
  };

  const switchGoogleAccount = (credential: UserCredential) => {
    //no more used
    firebase.switchAccountWithCredentials(credential).then(() => {
      window.location.replace(window.location.origin);
    });
  };

  const signOut = () => {
    setIsReady(false);
    firebase.signOut(() => {
      // navigate('/'); // ! throws an error when the user is on the capture page
      window.location.reload();
    });
  };

  const init = () => {
    // intercom.init();
    setIsReady(true);
  };

  // const init = (cb?: Function) => { //no more used
  // intercom.init();
  // console.log(`Use Auth Hook Calling INIT function`);
  // return firebase.init(
  //   (user: User) => {
  //     console.log(`Use Auth Init User Found:`, user);
  //     setIsReady(true);
  //     // setAuth(user);
  //     // cb && cb(user);
  //   },
  //   () => {
  //     console.log(`Use Auth Setting App Is Ready true`);
  //     setIsReady(true);
  //   },
  //   () => {
  //     console.log(`Use Auth Setting Sync with Extention true`);
  //     setSyncWithExtension(true);
  //   },
  //   () => {
  //     console.log(`Use Auth Setting Auth Problem true`);
  //     setHasAuthProblem(true);
  //   }
  // );
  // };

  const getUserInfo = () => {
    return currentUserInfo;
  };

  const getStoredUserInfo = async (uid: string) => {
    const user = (await firestore.getUser(uid, (data: any) => {
      const {
        plan = 'free',
        stripeCustomerId = null,
        planTeam = null,
        stripeCustomerIdTeam = null,
        stripeSubscriptionId = null,
        cancelledOn = null,
        expiresOn = null,
        appSumoLicenseKey = null,
        tier = null,
        licenseStatus = null,
        teamCancelledOn= null,
        teamExpiresOn= null,
      } = data;
      setCurrentUserInfo((current: Capture.Creator) => ({
        ...current,
        plan,
        stripeCustomerId,
        planTeam,
        stripeCustomerIdTeam,
        stripeSubscriptionId,
        cancelledOn,
        expiresOn,
        appSumoLicenseKey,
        tier,
        licenseStatus,
        teamCancelledOn,
        teamExpiresOn,
      }));
    })) as Capture.Creator;
    return user;
  };

  const setAnonymousEmail = async (email: string) => {
    if (!isNeedEmail) return false;
    // await firestore.updateUser(currentUserID, {
    //   email,
    //   displayName: email,
    //   isAnonymous: true,
    // });
    // const userInfo = firebase.getUser();
    // console.log(userInfo);

    if (currentUserInfo) {
      setCurrentUserInfo({ ...currentUserInfo, email: email });
    }
  };

  const setAnonymousDisplayName = async (name: string) => {
    if (currentUserInfo) {
      setCurrentUserInfo({ ...currentUserInfo, displayName: name });
    }
  };

  const setAvatar = async (uid: string, photoURL: string) => {
    await firestore.updateUser(uid, { avatar: photoURL });
  };

  const setDisplayName = async (
    uid: string,
    displayName: string,
    linkedinUrl?: string,
    twitterUrl?: string
  ) => {
    let update = {
      displayName,
      linkedinUrl,
      twitterUrl,
      isDisplayNameSetted: true,
    };
    if (!linkedinUrl) delete update.linkedinUrl;
    if (!twitterUrl) delete update.twitterUrl;
    console.log(`Data Updating`, update);
    await firestore.updateUser(uid, update);
    const userInfo = firebase.getUser();
    // console.log(`User Information Fetch on Update:${JSON.stringify(userInfo)}`);
    if (userInfo) {
      let newUser = userInfo as any;
      newUser.displayName = displayName;
      setAuth(newUser);
    }
  };

  const updatePlan = () => {
    setAuth(currentUserInfo);
  };

  const createFirebaseUser = async (user: any) => {
    let payload = {
      photoURL: null,
      ...user,
      isAnonymous: false,
      linkedinUrl: '',
      twitterUrl: '',
      plan: 'free', //free, pro
      stripeSubscriptionId: null, //free, pro
      planTeam: null, //team, null
      stripeSubscriptionIdTeam: null, //team, null
      stripeCustomerId: null,
      cancelledOn: null,
      expiresOn: null,
      teamCancelledOn: null,
      teamExpiresOn: null,
    };

    if(location.search.includes('license_key=')) {
      console.log("Check license key create user")
      const appSumoLicenseKey = searchParams.get('license_key')
      const licenseTier = searchParams.get('tier')
      const licenseStatus = searchParams.get('status')
      const {data: isUsedLicenseKey}: any = await httpsCallable(
        firebase.functions,
        'isUsedLicenseKey'
        )(appSumoLicenseKey);

      if(!isUsedLicenseKey){
        payload = {...payload, appSumoLicenseKey, tier: licenseTier, licenseStatus}
        if(!isToastShown.current){
          toast.success(
           "Your appSumo license key has been set successfully"
         )
        }
        // searchParams.delete('license_key');
        // searchParams.delete('tier');
        // searchParams.delete('status');
        // setSearchParams(searchParams);
      }else{
        if(!isToastShown.current){
          toast.success(
           "License key already belongs to a user."
         )
        }
        // searchParams.delete('license_key');
        // searchParams.delete('tier');
        // searchParams.delete('status');
        // setSearchParams(searchParams)
      }
    }

    const result = await firestore.createUser(payload);

    if (result?.success == 1) {
      return { uid: result.uid, updateClerkMetaData: true };
    } else {
      toast(
        'Your account was successfully created, but redirection fail. Try to login again!'
      );
    }

    return result;
  };

  return {
    isReady,
    currentUserID,
    linkToGoogle,
    init,
    signOut,
    getUserInfo,
    isNeedEmail,
    setAnonymousEmail,
    setAnonymousDisplayName,
    getStoredUserInfo,
    setAvatar,
    setDisplayName,
    switchGoogleAccount,
    updatePlan,
    setAuth,
    createFirebaseUser,
  };
};
