import { useEffect, useState } from 'react';
import ShareModal from '../modal/ShareModal';
import { currentUserInfoState } from '@/state/userState';
import { useRecoilValue } from 'recoil';
import { User } from 'firebase/auth';
import { toast } from 'react-hot-toast';
import {
  currentCaptureInfoState,
  currentUserRoleForCaptureState,
} from '@/state/captureState';
import { useOrganization } from '@clerk/clerk-react';

const CopyLink = () => {
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState<string>('');
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const currentUserRole = useRecoilValue(currentUserRoleForCaptureState);
  const { organization } = useOrganization();

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  // console.log("currentCaptureInfo ::", currentCaptureInfo)

  const openShareModal = async () => {
    if (organization || (currentCaptureInfo?.creator.uid === currentUserInfo.uid || currentUserRole.Role !== 'ILLEGAL')) {
    // if ((currentCaptureInfo?.creator.uid === currentUserInfo.uid || currentUserRole.Role !== 'ILLEGAL')) {
      if (currentCaptureInfo?.name === '') {
        toast.error(
          "Name before Sharing \nGive your untitled Capture a name before it's shared"
        );
        return;
      } else {
        setIsOpen(true);
      }
    } else {
      try {
        await navigator.clipboard.writeText(url);
        toast.success('Capture URL copied to Clipboard!');
      } catch (err) {
        toast.error('Coping the capture URL to Clipboard error.');
      }
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="flex-1 sm:flex-none max-w-max">
      <button
        className="btn-green-outline border-100 custom-green-btn hover:opacity-100 share-modal-open"
        onClick={openShareModal}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.8333 1.66675L18.3333 9.16675L10.8333 16.2501V11.6667C5 11.6667 2.5 17.9167 2.5 17.9167C2.5 10.8334 4.58333 6.25008 10.8333 6.25008V1.66675Z"
            stroke="black"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className="xsm:hidden">Share</span>
      </button>
      <ShareModal isOpen={isOpen} closeModal={closeModal} url={url} />
    </div>
  );
};

export default CopyLink;
