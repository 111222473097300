import { useState, FC, useMemo, MouseEvent, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
  captureIsPublicState,
  captureIsPublicGroupState,
  currentCaptureIsPdfState,
  getCurrentUserAccessType,
} from '@/state/captureState';
import { useFunction } from '@/hooks/useFunction';
import Download from '../icon/Download';
import LinkIcon from '../icon/LinkIcon';
import Clipboard from '../icon/Clipboard';
import Trash from '../icon/Trash';
import Switch from '../icon/Switch';
import FileUploadModal from '../modal/FileUploadModal';
import CopyIcon from '../icon/CopyIcon';
import CopyImageIcon from '../icon/CopyImageIcon';
import NewDownloadIcon from '../icon/NewDownloadIcon';
import ReplaceIcon from '../icon/ReplaceIcon';
import TrashMenu from '../icon/TrashMenu';
import DeleteModelPopup from '../modal/DeleteModal';
import DeleteModal from '../modal/DeleteModal';
import Capture from '../capture/Capture';
import { useOrganization } from '@clerk/clerk-react';
// 'EDIT' | 'VIEW' | 'DELTE' | 'ADMIN' | 'SUPERADMIN'
type props = {
  isShareInputViewDropdownShow: boolean;
  roleSelectedDropDown: string;
  isGroupShare: boolean;
  selectedRole?: Function;
  dashboard?: boolean;
};
const ShareInputViewDropdown = (Props: props) => {
  const [isOpen, setIsOpen] = useState(false);
  const captureIsPublic = useRecoilValue(captureIsPublicState);
  const captureIsPublicGroup = useRecoilValue(captureIsPublicGroupState);
  const currentCaptureIsPdf = useRecoilValue(currentCaptureIsPdfState);
  const [showToolTip, setShowToolTip] = useState(false);
  const [roleSlected, setRoleSelected] = useState({
    role: Props.roleSelectedDropDown,
    isGroupShare: Props.isGroupShare,
  });
  const accessType = useRecoilValue(getCurrentUserAccessType);
  const { organization } = useOrganization()

  const list = useMemo(
    () => {
      const data = [
        {
          role: 'VIEW',
          text: 'Can view',
          Icon: CopyIcon,
          fn: (event: any) => {
            Props.selectedRole &&
              Props.selectedRole({
                role: 'VIEW',
                isEntireProject: roleSlected.isGroupShare,
              });
            setRoleSelected({
              role: 'VIEW',
              isGroupShare: roleSlected.isGroupShare,
            });
          },
          needPermission: false,
          classname: '',
          htmlContent: '',
          inputType: 'radio',
          toolTip: '',
        },
        {
          role: 'EDIT',
          text: 'Can edit',
          Icon: CopyIcon,
          fn: (event: any) => {
            Props.selectedRole &&
              Props.selectedRole({
                role: 'EDIT',
                isEntireProject: roleSlected.isGroupShare,
              });
            setRoleSelected({
              role: 'EDIT',
              isGroupShare: roleSlected.isGroupShare,
            });
          },
          needPermission: !(
            accessType === 'CAPTURE-OWNER' ||
            accessType === 'EDIT-PROJECT-ACCESS' ||
            accessType === 'EDIT-CAPUTRE-ACCESS'
          ),
          classname: '',
          htmlContent: '',
          inputType: 'radio',
          toolTip: '',
        },
        {
          text: '',
          Icon: '',
          needPermission: !(
            accessType === 'CAPTURE-OWNER' ||
            accessType === 'EDIT-PROJECT-ACCESS' ||
            accessType === 'VIEW-PROJECT-ACCESS'
          ),
          classname: '',
          htmlContent: '<br class="my-2">',
          toolTip: '',
        },
        {
          text: 'Entire project access',
          Icon: CopyIcon,
          fn: (event: any) => {
            Props.selectedRole &&
              Props.selectedRole({
                role: roleSlected.role,
                isEntireProject: !roleSlected.isGroupShare,
              });
            setRoleSelected((old) => ({
              role: old.role,
              isGroupShare: !old.isGroupShare,
            }));
          },
          needPermission: !(
            accessType === 'CAPTURE-OWNER' ||
            accessType === 'EDIT-PROJECT-ACCESS' ||
            accessType === 'VIEW-PROJECT-ACCESS'
          ),
          classname: '',
          htmlContent: '',
          inputType: 'checkbox',
          toolTip: 'it Enable',
        },
        // { text: 'Delete',
        // Icon: TrashMenu,
        //  fn: deleteImage,
        //   needPermission: true },
        // {
        //   text: 'Modal',
        //   Icon: TrashMenu,
        //   fn: () => {
        //     setIsDeleteModalOpen(true);
        //   },
        //   needPermission: false
        // },
      ];
      // if (currentCaptureIsPdf) {
      //   data.shift();
      // }
      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roleSlected]
  );
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {Props.isShareInputViewDropdownShow && (
        <div
          key={`shareInputDropDown`}
          className="z-20 absolute top-8 right-1   w-56 mt-1 -ml-1 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:-ml-44 email-view-dropdown  "
          role="menu"
          tabIndex={0}
        >
          <div className="py-2" role="none">
            {list.map(
              (
                {
                  text,
                  Icon,
                  fn,
                  needPermission,
                  classname,
                  htmlContent,
                  role,
                  toolTip,
                  inputType,
                },
                index
              ) => {
                if (captureIsPublic && needPermission && !organization) return null;
                // if (captureIsPublic && needPermission) return null;
                return (
                  <>
                    {!htmlContent ? (
                      inputType == 'radio' ? (
                        <div key={text} className="radio-custom">
                          <label className="container">
                            <input
                              type="radio"
                              name="radio"
                              onClick={fn}
                              defaultChecked={roleSlected.role === role}
                            />

                            <span className="checkmark"></span>
                            <span className="ml-4">{text}</span>
                          </label>
                        </div>
                      ) : (
                        <div
                          onMouseEnter={() => {
                            setShowToolTip(true);
                          }}
                          onMouseLeave={() => {
                            setShowToolTip(false);
                          }}
                        >
                          <label className="container">
                            <input
                              type="checkbox"
                              checked={Props.dashboard}
                              disabled={Props.dashboard}
                              onClick={fn}
                              defaultChecked={roleSlected.isGroupShare}
                            />
                            <span
                              className={
                                Props.dashboard
                                  ? 'checkmark checked-grey'
                                  : `checkmark`
                              }
                              style={{ backgroundColor: '#e6e6e6 !important' }}
                            ></span>
                            <span className="ml-4">{text}</span>
                          </label>
                          {toolTip !== '' ? (
                            <div
                              className="input-dropDown-ToolTipe border-2 border-white absolute whitespace-nowrap shadow-2xl font-normal tracking-wider text-white bg-black bg-opacity-70 px-2 py-1 text-xxxxs rounded-tl-md rounded-tr-2xl rounded-br-2xl rounded-bl-2xl"
                              style={{ display: showToolTip ? '' : 'none' }}
                            >
                              Will give access to all <br />
                              captures in the project.
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      )
                    ) : (
                      <div className="px-4">
                        <hr className="my-3"></hr>
                      </div>
                    )}
                    {/* {!htmlContent ? (
                      <button
                        type="button"
                        key={index}
                        className={` px-5 text-gray-900 group flex rounded-md items-center w-full  text-sm hover:bg-green hover:text-black  email-view-dropdown-items`}
                        role="menuitem"
                        onClick={fn}
                      >
                        <span className={`${classname}`}>{text}</span>
                      </button>
                    ) : (
                      <div className="px-4">
                        <hr className="my-2"></hr>
                      </div>
                    )} */}
                  </>
                );
              }
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ShareInputViewDropdown;
