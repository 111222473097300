import { FC } from 'react';
import CaptureCommentReply from './CaptureCommentReply';

const CaptureCommentContent: FC<
  Capture.Reply & {
    commentIndex: number;
    isResolve?: boolean;
    isMain: boolean;
    totalReplies: Number;
    toggleReplies: Function;
    toggleReplyTextArea: Function;
    commentReplyEmojies: Capture.ReplyEmoji[];
  }
> = (props) => {
  return (
    <CaptureCommentReply index={-1} resolve={props.isResolve} {...props} />
  );
};

export default CaptureCommentContent;
