import { ColorPalettes } from 'helpers/core-constants';
import { useColors } from '@/hooks/useScreenshotEditor';
import { HexColorPicker } from 'react-colorful';

import React from 'react';
import {
  Plus,
  X,
  ArrowUp,
  ArrowUpRight,
  ArrowRight,
  ArrowDownRight,
  ArrowDown,
  ArrowDownLeft,
  ArrowLeft,
  ArrowUpLeft,
} from 'lucide-react';
import { Popover, Transition } from '@headlessui/react';
const GradientPicker = ({
  gradientColors,
  handleRemoveColor,
  handleAddColor,
  handleColorPickerChange,
  addGradientToColorPallet,
  handleGradientDirectionChange,
}: any) => {
  const gradientDirections = [
    { name: 'to top', icon: <ArrowUp size={18} color="black" /> },
    { name: 'to top right', icon: <ArrowUpRight size={18} color="black" /> },
    { name: 'to right', icon: <ArrowRight size={18} color="black" /> },
    {
      name: 'to bottom right',
      icon: <ArrowDownRight size={18} color="black" />,
    },
    { name: 'to bottom', icon: <ArrowDown size={18} color="black" /> },
    {
      name: 'to bottom left',
      icon: <ArrowDownLeft size={18} color="black" />,
    },
    { name: 'to left', icon: <ArrowLeft size={18} color="black" /> },
    { name: 'to top left', icon: <ArrowUpLeft size={18} color="black" /> },
  ];

  return (
    <div
      className="relative  overflow-y-auto inline-block no-scrollbar text-left w-full  bg-white px-3  "
      style={{
        maxHeight: 'calc(100vh - 200px)',
        paddingBottom: '781px',
      }}
    >
      <div className="">
        <div>
          <>
            <h1 className="mt-4 font-medium text-lg">Gradient Colors</h1>
            <div className="grid grid-cols-6 gap-2 mt-5 ">
              <button
                className="relative w-10 h-10 rounded-full cursor-pointer border-2 border-gray-800"
                onClick={handleAddColor}
              >
                <Plus color="black" size={18} className="ml-2" />
              </button>
              {gradientColors.map((gradient: any, index: number) => (
                <div key={index} className="relative gradient-item ">
                  <Popover>
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className="relative w-10 h-10 rounded-full cursor-pointer border-2 border-black"
                          style={{ backgroundColor: gradient.color }}
                        >
                          <span
                            className="absolute -top-1 p-0.5 -right-1 border-2 blackImportant  rounded-full"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveColor(gradient.id);
                            }}
                          >
                            <X color="white" size={12} />
                          </span>
                        </Popover.Button>

                        <Transition
                          as={React.Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel>
                            <div className="absolute top-12 left-0">
                              <HexColorPicker
                                color={gradient.color}
                                onChange={(color) => {
                                  handleColorPickerChange(color, gradient.id);
                                }}
                              />
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
              ))}
            </div>
            <div className="mt-4">
              <h2 className="text-xs font-medium text- mb-2">
                Gradient Direction
              </h2>
              <div className="flex space-x-2">
                {gradientDirections.map((direction, index) => (
                  <button
                    key={index}
                    onClick={() =>
                      handleGradientDirectionChange(direction.name)
                    }
                    className="rounded-md overflow-hidden bg-gradient-to-br border-2 border-black from-blue-500 to-blue-500 p-2 cursor-pointer flex items-center justify-center"
                    style={{
                      width: '40px',
                      height: '40px',
                      background: `linear-gradient(${
                        direction.name
                      }, ${gradientColors
                        .map((gradient: any) => gradient.color)
                        .join(', ')})`,
                    }}
                  >
                    {direction.icon}
                  </button>
                ))}
              </div>
            </div>
          </>
        </div>
        <h2 className="text-xs font-medium text- my-4">Color Palettes</h2>
        <div className="grid grid-cols-3 lg:grid-cols-4 gap-1">
          {ColorPalettes.map((palette, index) => (
            <div key={index} className="">
              <div
                className="w-full h-20 border-2 border-black rounded-lg overflow-hidden  cursor-pointer "
                onClick={() => {
                  addGradientToColorPallet(palette);
                }}
                style={{
                  background: `linear-gradient(to bottom left, ${palette
                    .map((color) => color.color)
                    .join(', ')})`,
                }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GradientPicker;
