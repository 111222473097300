import axios from 'axios';

export const useSlack = () => {
  function authUser(code: string) {
    return new Promise(async (resolve, reject) => {
      const authParams = new URLSearchParams();
      authParams.append('code', code);
      authParams.append('client_id', '4083879452020.4105146999824');
      authParams.append('client_secret', '6bcf8c020a457cc39da7e4cd5f00822d');

      await axios
        .post('https://slack.com/api/oauth.v2.access', authParams, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.data.ok) {
            // console.log(resp.data.access_token);

            localStorage.setItem('slack_access_token', resp.data.access_token);
            return resolve;
          }

          return reject;
        })
        .catch((resp) => {
          console.log(resp);
          return reject;
        });
    });
  }

  return {
    authUser,
  };
};
