type Props = {
  fillColor?: string;
};
const Box = (props: Props) => {
  return <div className="bg-white border-1 border-black rounded-xl w-11 min-h-11"></div>;
};

// const Box = (props: Props) => (
//   <svg
//     className=""
//     width="42"
//     height="42"
//     viewBox="0 0 42 42"
//     fill={props.fillColor ? props.fillColor : 'none'}
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <rect x="1" y="1" width="40" height="40" rx="8" fill="white" />
//     <rect x="1" y="1" width="40" height="40" rx="8" stroke="black" />
//   </svg>
// );

export default Box;
