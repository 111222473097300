import { FC, useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import Template from './Template';
import CircleClose from '../icon/CircleClose';
import { useCaptureInfo } from '@/hooks/useCaptureInfo';
import { useOrganization } from '@clerk/clerk-react';

type Props = {
  isOpen: boolean;
  closeModal: (e: boolean) => void;
  info: any
};

const RenameCaptureModal: FC<Props> = ({
  isOpen,
  closeModal,
  info
}) => {

  const [uploading, setUploading] = useState(false);
  const [captureName, setCaptureName] = useState('');
  const { updateCaptureInfo } = useCaptureInfo();
  const { organization } = useOrganization();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setUploading(true);

    let userId = organization ? organization.id : info.creator.uid
    // let userId = info.creator.uid

    updateCaptureInfo(userId, info.cid, { name: captureName })
      .then(() => {
        setUploading(false);
        closeModal(true);
      })
      .catch(() => {
        closeModal(false);
      });
  };


  useEffect(() => {
    setCaptureName(info.name);
  }, [info.name]);

  return (
    <Template isOpen={isOpen} closeModal={() => closeModal(false)}>
      <div
        className={`inline-block w-full max-w-xl  overflow-hidden text-left align-middle transition-all transform rounded-xl bg-white px-6  pt-6 sm:mt-6 default-modal `}
      >
        <Dialog.Title as="h3" className="text-2xl text-gray-900 leading-8">
          <div className="flex">
            <div className="w-3/4">
              <h4 className="fileupload-modal-heading">Capture Name</h4>
            </div>
            <div
              className="w-1/4 float-right"
              onClick={() => closeModal(false)}
            >
              <div className="float-right mb-5">
                <div className="flex items-center cursor-pointer">
                  <CircleClose />
                </div>
              </div>
            </div>
          </div>
          <hr />
        </Dialog.Title>
        <div className="flex">
          <div className="w-full my-10 relative">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                className=" upload-txtBox block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                autoFocus={false}
                // placeholder="Add the file URL"
                defaultValue={info.name}
                // value={name}
                onChange={(e) => {
                  setCaptureName(e.target.value);
                }}
              />

              {/* add class border-danger if error shows  */}
              {/* green button upload-success default button upload-btn-default                 */}

              <button
                //   disabled={uploading || !valueEnter}
                type="submit"
                className={`h-10 w-20 text-white rounded-lg rename-btn justify-center upload-default mx-auto mt-2`}
              >
                {uploading ? (
                  <div className="font-rubik w-full h-full flex flex-col justify-center items-center">
                    <div
                      style={{ borderTopColor: 'transparent' }}
                      className="w-5 h-5 border-1 border-black border-solid rounded-full animate-spin-fast "
                    ></div>
                  </div>
                ) : (
                  'save'
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default RenameCaptureModal;
