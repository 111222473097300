import React, { useEffect, useState } from 'react';
import {
  ArrowDown,
  ChevronRight,
  Download,
  Folder,
  MoveDown,
  MoveLeft,
  MoveRight,
  MoveUp,
  Upload,
  X,
} from 'lucide-react';
import Switch from 'react-switch';

import { useDispatch, useSelector } from 'react-redux';
import CustomSlider from '@/components/editor/slider/customSlider';
import { useImageSection } from '@/hooks/useScreenshotEditor';
import CanvasSize from '@/components/editor/canvasSize';
import { removeImage, resetEditor, setImage } from 'store/slice/editor.slice';
import { Switch as HeadlessSwitch } from '@headlessui/react';
import { RootState } from 'store';
import { alignImages } from 'helpers/core-constants';
import ElementsSection from '../elementsSection';
import ReactConfetti from 'react-confetti';
import { getDownloadURL, getStorage, listAll, ref } from 'firebase/storage';
import { useRecoilValue } from 'recoil';
import { pathState } from '@/state/appState';
import ResetOriginalIcon from '@/components/icon/ResetOriginalIcon';

interface ControlSectionProps {
  handleFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  image: any;
  handleExport: (width: number, height: number) => void;
}

const ControlSection: React.FC<ControlSectionProps> = ({
  handleFileInputChange,
  image,
  handleExport,
}) => {
  const {uid, cid} = useRecoilValue(pathState)
  const {
    imageRadius,
    canvasRadius,
    handleImageRadiusChange,
    handleCanvasRadiusChange,
    setGridOverlayState,
    gridOverlay,
    setWaterMarkState,
    waterMark,
    handleShadowChange,
    imageShadow,
    threeD,
    handleRotateX,
    handleRotateY,
    translateX,
    translateY,
    handleTranslateX,
    handleTranslateY,
    imageScale,
    handleImageScale,
    handleContentScale,
    contentScale,
    handleActiveTab,
    backgroundToggleDispatch,
  } = useImageSection();
  const dispatch = useDispatch();
  const { width, height, background } = useSelector(
    (state: RootState) => state.editor
  );
  const [showConfetti, setShowConfetti] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleExportWithConfetti = () => {
    handleExport(width, height);
    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 2 seconds
  };

  const handleSetDefaultImage = async () => {
    const storage = getStorage();

    const processImage = async (imageUrl: any) => {
      const response = await fetch(imageUrl);
      const originalBlob = await response.blob();
      const reader = new FileReader();
  
      reader.onload = () => {
        const img = new window.Image();
        img.src = reader.result as string;
        img.onload = () => {
          dispatch(setImage(img));
        };
      };
  
      reader.readAsDataURL(originalBlob);
    };
  
    const findImageRecursively : any = async (currentRef : any) => {
      const result = await listAll(currentRef);
      for (const itemRef of result.items) {
        if (itemRef.name === cid) {
          return await getDownloadURL(itemRef);
        }
      }
      for (const folderRef of result.prefixes) {
        const foundUrl = await findImageRecursively(folderRef);
        if (foundUrl) return foundUrl;
      }
      return null;
    };
  
    try {
      // Try to get the image from the original path
      const originalImageUrl = await getDownloadURL(ref(storage, `captures/${uid}/non-project/${cid}`));
      await processImage(originalImageUrl);
    } catch (error) {
      console.log("Original path not found, searching all folders...");
      // If the original path does not exist, search all folders
      const rootRef = ref(storage, `captures/${uid}`);
      const foundImageUrl = await findImageRecursively(rootRef);
      if (foundImageUrl) {
        await processImage(foundImageUrl);
      } else {
        console.error("Image not found in any folder.");
      }
    }
  };

  const handleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleRemoveImage = () => {
    dispatch(removeImage());
  };

  const handleRotateLeft = () => {
    handleRotateY(threeD.rotateY - 10);
  };

  const handleRotateRight = () => {
    handleRotateY(threeD.rotateY + 10);
  };

  const handleRotateUp = () => {
    handleRotateX(threeD.rotateX + 10);
  };

  const handleRotateDown = () => {
    handleRotateX(threeD.rotateX - 10);
  };

  useEffect(() => {
    return () => {
      dispatch(resetEditor())
    }
  },[])

  return (
    <div className="overflow-y-auto  ">
      <div className="pb-24 px-2">
        <div className=" flex gap-4 mt-2">
          <div className="relative inline-block text-left">
            <div className=" flex-col gap-4 mt-2">
              <button
                type="button"
                className="cursor-pointer mb-1  border-slate-800  border-black border-2 text-base px-8 py-1 rounded-3xl flex items-center justify-center text-black hover:bg-green"
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
                onClick={handleSetDefaultImage}
              >
                {/* <Download className="mr-2" />  */}
                <span className="mr-2">
                  <ResetOriginalIcon  />
                </span>
                Reset to Original
              </button>
              {/* <button
                type="button"
                className="cursor-pointer  border-slate-800  border-black border-2 text-base px-8 py-1 rounded-3xl flex items-center justify-center text-black "
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
                onClick={handleExportWithConfetti}
              >
                <Download className="mr-2" /> Download Image
              </button> */}
            </div>
          </div>
          <div className="flex items-center justify-center rounded-3xl">
            {image ? (
              <div className="flex items-center ">
                <img
                  className="inline-block h-9 w-9 ml-2  rounded-sm ring-2 "
                  src={image.src}
                  alt=""
                />
                <span className=" p-0.5 text-black -ml-2 -mt-9 cursor-pointer  bg-blue-500 rounded-sm">
                  <X size={12} className="" onClick={handleRemoveImage} />
                </span>
              </div>
            ) : (
              <label
                className="inline-block h-9 w-9 ml-2 border-2 border-black  rounded-lg"
                htmlFor="fileInput"
              ></label>
            )}
          </div>
        </div>
        {/* <ElementsSection /> */}

        <div className="mt-4">
          <CustomSlider
            label="Image Radius"
            value={imageRadius}
            onChange={handleImageRadiusChange}
            min={0}
            max={100}
            step={1}
          />
        </div>
        <div className="">
          <CustomSlider
            label="Canvas Radius"
            value={canvasRadius}
            min={0}
            max={100}
            step={1}
            onChange={handleCanvasRadiusChange}
          />
        </div>
        <div className="">
          <CustomSlider
            label="Image Shadow"
            value={imageShadow}
            min={0}
            max={12}
            step={1}
            onChange={handleShadowChange}
          />
        </div>
        <div className="">
          <CustomSlider
            label="Image Position X"
            value={translateX}
            min={-150}
            max={150}
            step={1}
            onChange={handleTranslateX}
          />
        </div>
        <div className="">
          <CustomSlider
            label="Image Position Y"
            value={translateY}
            min={-150}
            max={150}
            step={1}
            onChange={handleTranslateY}
          />
        </div>
        <div className="">
          <CustomSlider
            label="Image Size"
            value={imageScale}
            min={0.1}
            max={2}
            step={0.1}
            onChange={handleImageScale}
          />
        </div>
        {/* <div className="">
          <CustomSlider
            label="Content Size"
            value={contentScale}
            min={0.1}
            max={2}
            step={0.1}
            onChange={handleContentScale}
          />
        </div> */}

        <div>
          <div className="flex flex-col  bottom-card p-3 mr-2  rounded-xl  mt-6 items-center justify-center border-r border-black mb-5">
            <div className="grid grid-cols-2 gap-4 ">
              <div>
                <div className="flex flex-row items-center justify-between w-full">
                  <h1 className="text-xs text-black text-start w-full ">
                    3D Image
                  </h1>
                </div>
                <div className=" mt-4 flex flex-row justify-center items-center  rounded-2xl ">
                  <button
                    className="border border-black rounded-md p-3 m-1 cursor-pointer flex justify-center items-center "
                    onClick={handleRotateLeft}
                  >
                    <MoveLeft size={8} />
                  </button>

                  <div className="flex flex-col justify-center items-center">
                    <button
                      className="border border-black rounded-md p-3 m-1 cursor-pointer flex justify-center items-center "
                      onClick={handleRotateUp}
                    >
                      <MoveUp size={8} />
                    </button>

                    <button
                      className="border border-black rounded-md p-3 m-1 cursor-pointer flex justify-center items-center "
                      onClick={handleRotateDown}
                    >
                      <MoveDown size={8} />
                    </button>
                  </div>

                  <button
                    className="border border-black rounded-md p-3 m-1 cursor-pointer flex justify-center items-center "
                    onClick={handleRotateRight}
                  >
                    <MoveRight size={8} />
                  </button>
                </div>
              </div>
              <div>
                <div className="flex flex-row items-center justify-between w-full">
                  <h1 className="text-xs text-black text-start w-full ">
                    Image Position
                  </h1>
                </div>
                <div className="h-[70px] w-full mt-4 grid grid-cols-3 gap-1 items-center justify-center  rounded-md">
                  {alignImages.map((item, index) => (
                    <div
                      key={index}
                      className={
                        'flex items-center justify-center h-7 w-9 cursor-pointer rounded-md transform-gpu transition-all duration-300 hover:scale-110 ' +
                        (translateX === item.translateX &&
                        translateY === item.translateY
                          ? ' bg-black rounded-md'
                          : 'border border-gray-800 ')
                      }
                      style={{ alignSelf: 'center', justifySelf: 'center' }}
                      onClick={() => {
                        handleTranslateX(item.translateX);
                        handleTranslateY(item.translateY);
                      }}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <input
          id="fileInput"
          type="file"
          accept="image/*"
          onChange={handleFileInputChange}
          className="hidden"
        />
        {/* <div className="flex flex-col items-center justify-center mt-6">
          <button
            className="w-full flex items-center  justify-between bg-transparent border border-slate-800 py-4 px-6 hover:bg-slate-900 hover:bg-opacity-20 transition-all duration-200  text-[10px] text-gray-700 font-medium text-sm "
            onClick={() => handleActiveTab('text')}
          >
            <span className="mr-2">Text Section</span>
            <ChevronRight className=" text-black mr-3" size={15} />
          </button>
        </div> */}

        <div className=" grid grid-cols-2 items-start justify-start mt-5">
          <div className="flex items-center justify-start">
            <label
              className="text-[12px] font-medium text-black mr-2"
              htmlFor="airplane-mode"
            >
              Watermark
            </label>

            <Switch
              onChange={setWaterMarkState}
              checked={waterMark}
              offColor="#767577"
              onColor="#0000"
              offHandleColor="#f4f3f4"
              onHandleColor="#f4f3f4"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={10}
              width={48}
              className="react-switch"
            />
          </div>
          <div className="flex items-center justify-start">
            <label
              className="text-[12px] font-medium text-black mr-2"
              htmlFor="airplane-mode"
            >
              Grid Overlay
            </label>

            <Switch
              onChange={setGridOverlayState}
              checked={gridOverlay}
              offColor="#767577"
              onColor="#000000"
              offHandleColor="#f4f3f4"
              onHandleColor="#f4f3f4"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={10}
              width={48}
              className="react-switch"
            />
          </div>
          <div className="flex mt-3 items-center justify-start">
            <label
              className="text-[12px] font-medium text-black mr-2"
              htmlFor="airplane-mode"
            >
              Background
            </label>

            <Switch
              onChange={backgroundToggleDispatch}
              checked={background}
              offColor="#767577"
              onColor="#000000"
              offHandleColor="#f4f3f4"
              onHandleColor="#f4f3f4"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={10}
              width={48}
              className="react-switch"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlSection;
