import React, { FC, useState } from 'react';
import Template from './Template';
import CircleClose from '../icon/CircleClose';
import { Dialog } from '@headlessui/react';
import { addProject } from '@/lib/firebase/firestore';
import { useRecoilValue } from 'recoil';
import { currentUserInfoState } from '@/state/userState';
import { UserInfo } from 'firebase/auth';
import { useOrganization } from '@clerk/clerk-react';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

const AddProjectModal: FC<Props> = ({ isOpen, closeModal }) => {
  const [name, setName] = useState('');
  const userInfo = useRecoilValue(currentUserInfoState) as UserInfo;
  const { organization } = useOrganization();

  const onSubmit = async () => {
    if (!organization) {
    await addProject(userInfo.uid, name);
    closeModal();
    return;
    }
    if (organization) {
      await addProject(organization.id, name);
      closeModal();
      return;
    }
  };

  return (
    <Template isOpen={isOpen} closeModal={closeModal}>
      <div
        className={`inline-block w-full max-w-xl overflow-hidden text-left align-middle transition-all transform rounded-xl bg-white py-6 sm:mt-6 default-modal `}
      >
        <Dialog.Title as="h3" className="text-2xl text-gray-900 leading-8 px-6">
          <div className="flex mb-6">
            <div className="w-3/4 flex flex-col">
              <h4 className="fileupload-modal-heading">
                Create project folder
              </h4>
              <span className="text-xs">
                Organize your captures by creating project folders.
              </span>
            </div>
            <div className="w-1/4 float-right" onClick={closeModal}>
              <div className="float-right mb-5">
                <div className="flex items-center cursor-pointer">
                  <CircleClose />
                </div>
              </div>
            </div>
          </div>
        </Dialog.Title>
        <div className="w-full h-px opacity-10 bg-black" />
        <div className="flex flex-col px-6 mt-6">
          <div className="relative">
            <input
              placeholder="Untitled project"
              className="py-2 px-6 w-full text-sm text-gray-900 bg-gray-50 rounded-3xl border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
            />
            <div className="absolute w-px h-3.5 bg-black left-4 top-3" />
          </div>
          <div className="w-full flex items-center mt-8 gap-4 justify-end">
            <button
              className="font-semibold sm:hidden hover:opacity-60 border border-black border-solid py-2 w-28 rounded-3xl"
              onClick={closeModal}
              style={{ boxShadow: '1px 1px 0px #000000' }}
            >
              Cancel
            </button>
            <button
              className={`h-10 w-28 text-white rounded-lg justify-center upload-default upload-success`}
              onClick={onSubmit}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default AddProjectModal;
