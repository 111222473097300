import { useEffect } from 'react';
import { useRecoilValue, useRecoilCallback, useSetRecoilState } from 'recoil';
import { currentUserIDState, currentUserInfoState } from '@/state/userState';
import { captureListSharedState, captureListState, currentCaptureInfoState } from '@/state/captureState';

import * as firebase from '@/lib/firebase/firestore';
import { User } from 'firebase/auth';
import { pathState } from '@/state/appState';
import { useInvitation } from '@/hooks/useInvitation';
import { isSharedWithMeReadyState, showMyProjectState } from '@/state/uiState';

let unsub: Function | null = null;

const GetCaptureListSharedWithMe = () => {
    const currentUserID = useRecoilValue(currentUserIDState);
    const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
    const { cid } = useRecoilValue(pathState);
    const showSharedWithMe = useRecoilValue(showMyProjectState)
    const setSharedReady = useSetRecoilState(isSharedWithMeReadyState)
    const { setCurrentCaptureShareInviteList } = useInvitation()
    const getCaptureList = useRecoilCallback(
        ({ set }) =>
            () => {
                firebase.getListSharesWithMeCapture(currentUserInfo?.email || '', set, (list: Capture.Info[]) => {
                    set(captureListSharedState, list);
                    set(isSharedWithMeReadyState, true)
                });

                return firebase.getSubShared(currentUserInfo?.email || '', set, (data: Capture.Info[]) => {
                    set(captureListSharedState, (old) => data);
                    set(isSharedWithMeReadyState, true)
                    if (cid) setCurrentCaptureShareInviteList(cid)
                });
            },
        [currentUserInfo.email]
    );

    useEffect(() => {
        if (!currentUserInfo.email) return;

        if (unsub) {
            unsub();
        }
        unsub = getCaptureList();
        return () => {
            unsub && unsub();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserInfo.email]);
    return null;

};

export default GetCaptureListSharedWithMe;
