import { useRecoilValue } from 'recoil';
import cn from 'classnames';

import { useTool } from '@/hooks/tool/useTool';
import { useBoundingBox } from '@/hooks/tool/useBoundingBox';

import { currentUserInfoState } from '@/state/userState';

import { User } from '@firebase/auth';
import toast from 'react-hot-toast';
import { canCommentState, currentUserRoleForCaptureState } from '@/state/captureState';
import { useMarker } from '../context/MarkerContext';

const ToolMenu = () => {
  const { handleClickToolMenu, getListTool, currentTool } = useTool();
  const { setIsBoundingBoxShow } = useBoundingBox();
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const canComment = useRecoilValue(canCommentState);
  const { markerArea } = useMarker();
  const currentUserRoleForCapture = useRecoilValue(
    currentUserRoleForCaptureState
  );
 
  return (
    <ul
      className={`list-tool rounded-3xl bg-white`}
      style={{ boxShadow: '1px 1px 0px #000000' }}
    >
      {getListTool().map(({ Icon, name, description }, index) => {
        return (
          <li
            className={`list-tool-item flex items-center p-3 py-2 hover:bg-green ${
              name === currentTool && 'bg-green border-black border-1'
            } ${
              currentUserInfo.isAnonymous &&
              (canComment ? index > 1 : true) &&
              'opacity-30'
            }`}
            // style={{ height: name === currentTool ? 44 : 50 }}
            key={name}
            onClick={async (e) => {
              if(name === 'ANNOTATE' && currentUserRoleForCapture?.Role === "VIEW" ) return
              e.stopPropagation();
              if (currentUserInfo.isAnonymous) {
                if (!(canComment && (name == 'CHAT' || name == 'MOVE'))) {
                  toast(
                    'You must be logged in to make edits to screen captures.'
                  );
                  return;
                }
              }

              if (name !== 'ANNOTATE' && markerArea) {
                 await markerArea.close();
              }
              handleClickToolMenu(name);

              if (index < 2) {
                setIsBoundingBoxShow(false);
              } else {
                setIsBoundingBoxShow(true);
              }
            }}
          >
            <div className="flex items-center gap-2.5">
              <div className="relative fill-black">
                <span className="tooltip-left-tooltip-text bg-black">
                  {description.toLowerCase()}
                </span>
                <Icon
                  classname={cn(
                    'stroke-current',
                    name === 'BLUR' && 'fill-current',
                    name === currentTool ? 'text-black' : 'text-gray-400'
                  )}
                  strokeColor={name === currentTool ? "#000000" : "#808080"}
                />
              </div>
              <span
                className="font-semibold"
                style={{ color: name === currentTool ? '#000000' : '#808080' }}
              >
                {description}
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default ToolMenu;
