import { FC } from 'react';

interface props {
  width?: number;
  height?: number;
  opacity?: number;
}

const RecordingDotIcon: FC<props> = ({
  height = 8,
  width = 8,
  opacity = 1,
}) => {
  return (
    <svg
      width={height}
      height={width}
      opacity={opacity}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill="#FF0000" />
    </svg>
  );
};

export default RecordingDotIcon;
