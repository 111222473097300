import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { pathState } from '@/state/appState';
import { useCaptureInfo } from '@/hooks/useCaptureInfo';
import { useInvitation } from '@/hooks/useInvitation';
import {
  currentCaptureInfoState,
  currentCaptureShareInvitListState,
  currentUserRoleForCaptureState,
  shareListWithRoles,
} from '@/state/captureState';
import { currentUserInfoState } from '@/state/userState';
import { User } from 'firebase/auth';
import { saveShareInvite, updateShareInvite } from '@/lib/firebase/firestore';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { userSubscriptionEnum } from '@/hooks/useGetUserSubscription';
import { showMyProjectState } from '@/state/uiState';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/lib/firebase/auth';

let unsub: Function | null = null;

const Capture = () => {
  const navigate = useNavigate();
  const { uid, cid } = useRecoilValue(pathState);
  const { getCaptureInfoSub } = useCaptureInfo();
  const { setCurrentCaptureShareInviteList } = useInvitation();
  const currentUserInfo = useRecoilValue(currentUserInfoState);
  const currentCaptureInviteList = useRecoilValue(
    currentCaptureShareInvitListState
  );
  const currentCaputreRole = useRecoilValue(currentUserRoleForCaptureState);
  let sharedList = useRecoilValue(shareListWithRoles);
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const showMyProject = useRecoilValue(showMyProjectState);

  const captureCreatorHasPlanOrTier = async(uid : string) => {
    const { data }: any = await httpsCallable(
      functions,
      'getUserPlanOrTier'
    )({uid});

    if(!data) return false;

    const { hasPlanOrTier } = data;
    return hasPlanOrTier
  }

  useEffect(() => {
    if(!uid) return
    const checkPlanOrTier = async () => {
      // if (currentUserInfo?.plan === userSubscriptionEnum.pro || currentUserInfo?.tier === "1") return;
      // console.log("currentUserInfo?.uid ===", currentUserInfo?.uid)
      // console.log("currentCaptureInfo ===", currentCaptureInfo)
  
      const hasPlanOrTier = await captureCreatorHasPlanOrTier(uid);
      if (hasPlanOrTier) return;
  
      if (currentCaptureInfo?.expiredAt) {
        if (currentCaptureInfo.expiredAt < Math.floor(new Date().getTime() / 1000)) {
          navigate('/');
        }
      }
    };
  
    checkPlanOrTier();
  }, [currentCaptureInfo, uid]);

  useEffect(() => {
    if (!uid || !cid) return;
    unsub && unsub();
    // console.log("currentCaptureInfo ::", currentCaptureInfo)
    // const funcPram = organization ? organization.id : uid;
    const funcPram = uid;
    unsub = getCaptureInfoSub(funcPram, cid);
    if (cid) {
      setCurrentCaptureShareInviteList(cid);
    }

    return () => {
      unsub && unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, cid]);

  const setCaptureGroup = async () => {
    try {
      if (currentCaptureInfo === null || !cid || !uid) return;
      if (!sharedList || sharedList.length == 0) return;

      let found = _.filter(sharedList, {
        cid: currentCaptureInfo?.cid,
        uid: currentCaptureInfo?.creator.uid,
      });

      if (found.length === 0) {
        let foundGroup = _.filter(sharedList, {
          groupName: currentCaptureInfo?.group,
          isGroupShare: true,
          uid: currentCaptureInfo?.creator.uid,
          IniviteStatus: 'ACCEPTED',
        });

        found = foundGroup;
        if (!found || found.length === 0) return;

        let shareModel: Capture.ShareModel & any = {
          email: currentUserInfo?.email || '',
          IniviteStatus: 'ACCEPTED',
          Role: found[0].Role,
          cid: currentCaptureInfo?.cid || '',
          creator: {
            uid: currentCaptureInfo?.creator.uid || '',
            avatar: currentCaptureInfo?.creator.avatar || '',
            displayName: currentCaptureInfo?.creator.displayName,
            email: currentCaptureInfo?.creator.email,
            isAnonymous:
              currentCaptureInfo?.creator.isAnonymous === true ? true : false,
          },
          groupName: currentCaptureInfo?.group || '',
          isGroupShare: true,
          updatedAt: dayjs().unix(),
          createdAt: dayjs().unix(),
          message: '',
          displayName: currentUserInfo.displayName || '',
          uid: currentUserInfo.uid,
          updatedBy: currentUserInfo.uid,
        };

        await saveShareInvite(shareModel);
      }
    } catch (error) {
      console.log(`Error on= ${error}`);
    }
  };

  useEffect(() => {
    let updateShareInviteStatus = async () => {
      await setCaptureGroup();
    };

    updateShareInviteStatus();

    if (!currentUserInfo.isAnonymous && cid && uid) {
      if (
        currentCaptureInfo?.creator.uid !== currentUserInfo.uid &&
        currentCaputreRole.InviteStatus === 'SENT'
      ) {
        updateShareInvite(
          currentUserInfo.email || '',
          cid,
          {
            IniviteStatus: 'ACCEPTED',
            uid: currentUserInfo.uid,
            displayName: currentUserInfo.displayName,
          },
          uid
        );
      }
    }
  }, [cid, uid, currentCaptureInviteList]);

  return null;
};

export default Capture;
