import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import clsx from 'clsx';

import { useFunction } from '@/hooks/useFunction';
import { useToolObject } from '@/hooks/tool/useToolObject';

import { showCommentsTabState } from '@/state/uiState';
import { currentCaptureInfoState, getCurrentUserAccessType, imgRefState } from '@/state/captureState';
import { responsiveDesignState } from '@/state/responsiveState';

import Title from './Title';
import Dropdown from './Dropdown';
import Creator from './Creator';
import CopyLink from './CopyLink';
import Container from '@/components/tool/Container';
import MoreIcon from '../icon/MoreIcon';
import EditorCircle from './EditorCircle';
import { useMarkerTools } from '@/hooks/tool/useMarkerTool';
import { useMarker } from '../context/MarkerContext';

const Header = () => {
  const {contextImgRef, showHeaderToolbar} = useMarker();
  const { setToolState } = useToolObject();
  const location = useLocation();
  const navigate = useNavigate();

  const [responsiveDesign, setResponsiveDesign] = useRecoilState(
    responsiveDesignState
  );
  // const imgRefFromRecoil = useRecoilValue(imgRefState)

  const setShowCommentTab = useSetRecoilState(showCommentsTabState);
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const { showMarkerArea } = useMarkerTools({imgRef: contextImgRef});


  const responsiveCommentsToggle = () => {
    setResponsiveDesign({
      ...responsiveDesign,
      comments: true,
      sidebar: false,
      // welcome: false,
    });

    setShowCommentTab(true);
  };

  const { comments } = responsiveDesign;

  const [isAnnotationDropdownShow, setIsAnnotationDropdownShow] =
    useState(false);
  const [isDropdownShow, setIsDropdownShow] = useState(false);

  const { copyLinkToClipboard } = useFunction();

  const ref = useRef<HTMLDivElement | null>(null);
  const refAnnotation = useRef<HTMLDivElement | null>(null);
  const currentAccessType = useRecoilValue(getCurrentUserAccessType);

  let allowedAnnotation =
    currentAccessType !== 'VIEW-CAPUTRE-ACCESS' &&
    currentAccessType !== 'VIEW-PROJECT-ACCESS';

  const clickOutside = (e: Event) => {
    // inside click
    if (ref.current && ref?.current.contains(e.target as Node)) return false;

    // outside click && already opened
    if (isDropdownShow) return handleClickTripleDots();
  };

  const clickOutsideAnnotationDropdown = (e: Event) => {
    // inside click
    if (
      refAnnotation.current &&
      refAnnotation?.current.contains(e.target as Node)
    )
      return false;

    // outside click && already opened
    if (isAnnotationDropdownShow) return handleClickAnnotationDropdown();
  };

  let cursorNotAllowedClass = !allowedAnnotation ? 'cursor-not-allowed' : '';
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (location.search.startsWith('?link=true')) {
      navigate(location.pathname);
      timer = setTimeout(copyLinkToClipboard, 500);
    }
    document.addEventListener('mousedown', clickOutside);
    document.addEventListener('mousedown', clickOutsideAnnotationDropdown);

    return () => {
      timer && clearTimeout(timer);
      document.removeEventListener('mousedown', clickOutside);
      document.removeEventListener('mousedown', clickOutsideAnnotationDropdown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownShow, isAnnotationDropdownShow]);

  const tripleDotStyle = clsx({
    'opacity-70': !isDropdownShow,
    'opacity-100': isDropdownShow,
  });

  const handleClickTripleDots = async () => {
    setIsDropdownShow((isDropdownShow) => !isDropdownShow);
    await setToolState();
  };

  const handleClickAnnotationDropdown = () => {
    setIsAnnotationDropdownShow(
      (isAnnotationDropdownShow) => !isAnnotationDropdownShow
    );
  };

  // if(!contextImgRef) return false

  return (
    <div
      className={`px-8 custom-p-26 bg-white header sm:p-0 sm:px-0 md:px-0 ${comments && 'sm:hidden'
        }`}
    >
      <div className="mobile-nav flex flex-row">
        <div className="w-1/3 flex items-center justify-center md:w-2/3 full-wdith title-div ">
          <div className="flex-1 inline-flex items-center sm:h-8">
            <span className="relative">
              <Title />
            </span>
          </div>
        </div>

        <div className="w-1/3 flex items-center justify-center sm:w-full sm:justify-around sm:px-2">
          <div
            className={`flex items-center justify-center h-1.5  cursor-pointer desktop-header ${cursorNotAllowedClass} sm:hidden`}
          >
            {
              showHeaderToolbar &&
            <Container />
            }
          </div>
          <span className="hidden sm:block">
            <img
              src="/Comments.svg"
              alt=""
              onClick={responsiveCommentsToggle}
              className="cursor-pointer hamburger-icon"
            />
          </span>
        </div>

        <div className="w-1/3 flex flex-row items-center justify-end sm:w-full mid-width sm:hidden">

        {/* {contextImgRef?.current && <div className="flex flex-row right-2 min-w-max sm:absolute sm:top-5 sm:right-2 justify-end">
            {process.env.REACT_APP_NODE_ENV === 'development' ? (
              <div className="flex-1 self-center mr-8"></div>
            ) : null}
            <button onClick={() => {
              if (contextImgRef.current && currentCaptureInfo?.url) {
                console.log("currentCaptureInfo?.url ====>>", currentCaptureInfo?.url)
                // console.log("imgRefFromRecoil ====>>", imgRefFromRecoil)

                // Reset the image source to the original URL before opening marker area
                contextImgRef.current.src = currentCaptureInfo?.url;
              }
              console.log("contextImgRef.current ===", contextImgRef.current)
              showMarkerArea(contextImgRef.current)
              }}>show markers area</button>
          </div>} */}

          <div className="flex flex-row right-2 min-w-max sm:absolute sm:top-5 sm:right-2 justify-end">
            {process.env.REACT_APP_NODE_ENV === 'development' ? (
              <div className="flex-1 self-center mr-8"></div>
            ) : null}
            <Creator />
          </div>

          <EditorCircle />
          <CopyLink />

          <span className="hidden sm:block">
            <img
              src="/hamburger.svg"
              alt=""
              onClick={() => {
                setResponsiveDesign({
                  ...responsiveDesign,
                  responsive: true,
                  sidebar: true,
                  welcome: false,
                  comments: false,
                });
              }}
              className="cursor-pointer hamburger-icon"
            />
          </span>

          <div className="desktop-share-dots flex sm:flex-row items-center sm:justify-between">
            <div
              className={`flex items-center justify-center cursor-pointer  ${tripleDotStyle}`}
            >
              <div
                className="relative flex items-center justify-center cursor-pointer sm:static "
                onClick={handleClickTripleDots}
                tabIndex={1}
                ref={ref}
              >
                <div
                  className={`w-11 h-11 flex items-center justify-center border-1.5 border-black rounded-full ${isDropdownShow ? 'bg-green-updated' : 'hover:bg-gray-100'
                    }`}
                >
                  <MoreIcon />
                </div>

                <Dropdown isDropdownShow={isDropdownShow} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
