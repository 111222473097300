import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';

import { currentCaptureInfoState } from '@/state/captureState';
import { sidebarState } from '@/state/uiState';
import { pathState } from '@/state/appState';

import { useClickOutside } from '@/hooks/useClickOutside';
import { useCaptureInfo } from '@/hooks/useCaptureInfo';

import ImageRenderer from '../ImageRenderer';
import DropdownCapture from '../sidebar/DropdownCapture';
import ThreeVerticalDots from '../icon/ThreeVerticalDotsIcon';
import DeleteModal from '../modal/DeleteModal';
import { useFunction } from '@/hooks/useFunction';
import { currentUserIDState, currentUserInfoState } from '@/state/userState';
import { useGetUserSubscription } from '@/hooks/useGetUserSubscription';
import { useShowNotifyMsg } from '../notify';

interface CaptureItemProps {
  capture: Capture.Info & { isExpired?: boolean; };
  sequenceNumber: number;
  onChoose?: (capture: Capture.Info) => void;
}

const CaptureItem: FC<CaptureItemProps> = ({
  capture,
  sequenceNumber,
  onChoose = () => { },
}) => {
  const { updateCaptureInfo, getCaptureInfoSub } = useCaptureInfo();
  const { deleteImage } = useFunction();

  const captureTitleRef = useRef<HTMLInputElement>(null);
  const threeDotsRef = useRef<HTMLDivElement>(null);

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [showRestoreDropdown, setShowRestoreDropdown] =
    useState<boolean>(false);
  const [captureName, setCaptureName] = useState<string>('');
  const [isCaptureNameEditing, setIsCaptureNameEditing] =
    useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { uid, cid } = useRecoilValue(pathState);
  const isSidebarShow = useRecoilValue(sidebarState);
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const currentUserID = useRecoilValue(currentUserIDState);
  const currentUserInfo = useRecoilValue(currentUserInfoState);


  const { subscription } = useGetUserSubscription(currentUserID);

  let unsub: Function | null = null;

  // Focus input after allowing its editing
  useEffect(() => {
    if (isCaptureNameEditing) captureTitleRef.current?.focus();
  }, [isCaptureNameEditing]);

  useEffect(() => {
    const handleChangingTitleApprove = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && isCaptureNameEditing) {
        setIsCaptureNameEditing(false);
        changeTitle(captureName);
      } else if (captureName !== capture?.name && !isCaptureNameEditing) {
        setCaptureName(capture?.name ? capture.name : 'Untitled Capture');
      }
    };

    window.addEventListener('keydown', handleChangingTitleApprove);

    return () => {
      window.removeEventListener('keydown', handleChangingTitleApprove);
    };
  }, [captureName]);

  useEffect(() => {
    setCaptureName(capture?.name || 'Untitled Capture');
  }, [capture?.name]);

  const changeTitle = async (title: string) => {
    if (title === '') {
      setCaptureName('Untitled Capture');
    }
    unsub && unsub();
    await updateCaptureInfo(uid, cid, { name: title });
    unsub = getCaptureInfoSub(uid, cid);

    return () => {
      unsub && unsub();
    };
  };

  const approveNewCaptureName = async () => {
    if (!isCaptureNameEditing) return;
    console.log('Click outside');

    setIsCaptureNameEditing(false);

    changeTitle(captureName);
  };

  useClickOutside(captureTitleRef, approveNewCaptureName);
  const { onButtonClick, showDiv: showNotifyMsg } = useShowNotifyMsg();

  const getDateSinceCreation = useCallback((capture: Capture.Info & { isExpired?: boolean }) => {
    if (subscription === "free" && currentUserInfo?.tier !== "1" && (capture?.expiresInXDays ?? 0) < 8 && capture?.isExpired != true) return "Expires soon";
    return dayjs.unix(capture.createdAt).fromNow().replaceAll('minutes', 'mins')
      .length > 11
      ? dayjs
        .unix(capture.createdAt)
        .fromNow()
        .replaceAll('minutes', 'mins')
        .slice(0, 11) + '...'
      : dayjs.unix(capture.createdAt).fromNow().replaceAll('minutes', 'mins');
  }, [subscription]);

  return (
    <div
      className={`flex flex-row relative w-full py-4 px-6 hover:bg-green-updated ${capture.cid === currentCaptureInfo?.cid && 'bg-green'
        }`}
    >
      <div
        className={`sidebar-capture-img sidebar-img min-w-14
                    flex justify-center items-center bg-gray-400
                    rounded-lg border-2 border-solid border-gray-100
                    overflow-hidden cursor-pointer`}
        onClick={() => {
          // if (daysSinceCreation !== undefined && daysSinceCreation !== null) {
          // daysSinceCreation < 20 && subscription == 1 && onChoose(capture);
          // }
          // console.log("UUUUUUUUUUU========", subscription)
          // if (subscription === 2) return onChoose(capture);
          onChoose(capture);
          // console.log('capture.isDeleted ========', capture.isDeleted);
          // !capture.isDeleted && onChoose(capture);
        }}
      >
        <ImageRenderer
          className="object-cover h-10 w-full bg-gray-200 "
          src={capture.url}
          key={capture.url}
          alt="Project image"
        />
        <div className="sidebar-comment-count absolute top-14">
          <span className="max-w-min truncate">{sequenceNumber}</span>
        </div>
      </div>

      {isSidebarShow && (
        <div className="flex flex-row justify-between">
          <div className="flex flex-col ml-3">
            <div className="flex flex-row items-center justify-between">
              <input
                ref={captureTitleRef}
                className="font-poppins text-sm font-medium truncate"
                type="text"
                value={captureName}
                onChange={(e) => setCaptureName(e.currentTarget.value)}
                disabled={!isCaptureNameEditing}
              />
            </div>

            <div className="comments-sidebar flex flex-row py-1">
              <p>
                {capture.comments ? capture.comments.length : '0'} Comments ·{' '}
                <span className={`${getDateSinceCreation(capture) === "Expires soon" ? "text-red-600" : ""}`}>{getDateSinceCreation(capture)}</span>
              </p>
            </div>
          </div>
          <div className="relative">
            {
              capture.cid === currentCaptureInfo?.cid && (
                <div
                  ref={threeDotsRef}
                  className="rounded-full w-min cursor-pointer ml-4 hover:bg-gray-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDropdown((prev) => !prev);
                  }}
                >
                  <ThreeVerticalDots key={'dots-1'} />
                </div>
              )
              // : (
              //   <div
              //     ref={threeDotsRef}
              //     className="rounded-full w-min cursor-pointer ml-4 hover:bg-gray-100"
              //     onClick={(e) => {
              //       e.stopPropagation();
              //       setShowRestoreDropdown((prev) => !prev);
              //     }}
              //   >
              //     <ThreeVerticalDots key={'dots-2'} />
              //   </div>
              // )
            }
            <DropdownCapture
              show={showDropdown}
              showRestore={showRestoreDropdown}
              capture={capture}
              onClose={() => {
                setShowDropdown(false);
                // setShowRestoreDropdown(false);
              }}
              onChangeNameClick={(e) => {
                e.stopPropagation();
                setIsCaptureNameEditing(true);
                setShowDropdown(false);
                // setShowRestoreDropdown(false);
              }}
              onCaptureDelete={() => setIsDeleteModalOpen(true)}
              onRestore={() => {
                onButtonClick();
              }}
              onRestoreDelete={() => { }}
              showNotifyMsg={showNotifyMsg}
              styles={{
                position: threeDotsRef.current?.getBoundingClientRect()!,
              }}
            />
          </div>
        </div>
      )}
      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(false)}
        Proceed={() => deleteImage(false)}
        deleteType="Capture"
      />
    </div>
  );
};

export default memo(CaptureItem);
