import SignInOut from './sidebar/SignInOut';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { sidebarState } from '@/state/uiState';
import { currentUserInfoState } from '@/state/userState';
import { responsiveDesignState } from '@/state/responsiveState';
import type { User } from 'firebase/auth';
import { useEffect, useRef, useState } from 'react';
import FileUploadModal from './modal/FileUploadModal';
import { useUpload } from '@/hooks/useUpload';
import type { ProcessServerConfigFunction } from 'filepond';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import {
  captureListAtHostState,
  currentCaptureInfoState,
} from '@/state/captureState';
import { useAuth } from '@/hooks/useAuth';
import axios from 'axios';
import { ImgSize } from './capture/types';
import FileUpload from './FileUpload';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/lib/firebase/auth';

const Upload = () => {
  const setSidebar = useSetRecoilState(sidebarState);
  const [isOpen, setIsOpen] = useState(false);
  const { uploadImage, uploadPDF } = useUpload();
  const navigate = useNavigate();
  const url = window.URL || window.webkitURL;
  const [progress, setProgress] = useState<Number | null>(null);

  const closeModal = () => {
    setIsOpen(false);
  };
  const handleProcess: ProcessServerConfigFunction = async (
    _fieldName,
    file,
    _metadata,
    load,
    error,
    progress,
    _abort
  ) => {
    if (file.type === 'application/pdf') {
      try {
        await uploadPDF(file, file.name, (path: string) => {
          navigate(path);
          load('done');
          setProgress(null);
        }, (progress: number) => {
          setProgress(progress);
        });
      } catch (err) {
        error('UPLOAD IS FAILED');
        console.log(err);
      }
    } else if (file.type.match(/^image/)) {
      try {
        const img = new Image();
        img.onload = async function () {
          const imgSize: ImgSize = {
            width: img.width,
            height: img.height,
          };

          await uploadImage(
            file,
            file.name,
            imgSize,
            progress,
            (path: string) => {
              // console.log(path);
              navigate(path);
              load('done');
            }
          );
        };
        img.src = url.createObjectURL(file);
      } catch (err) {
        error('UPLOAD IS FAILED');
        console.log(err);
      }
    } else {
      error('This file type is not allowed here.');
    }
  };

  const handleUpload = () => {
    setSidebar(true);
    setIsOpen(true);
  };

  return (
    <>
      <div
        className="w-full h-full flex justify-center items-center cursor-pointer"
        onClick={handleUpload}
      >
        Upload
      </div>
      <FileUploadModal
        isReplaceImage={false}
        isOpen={isOpen}
        closeModal={closeModal}
        onProcess={handleProcess}
        progress={progress}
      />
    </>
  );
};

const Empty = () => {
  const navigate = useNavigate();
  const isReplaceImage = false;
  const url = window.URL || window.webkitURL;
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const [isLoaded, setIsLoaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [valueEnter, setValueEnter] = useState(false);
  const [valideUrl, setValideUrl] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const urlRef = useRef<HTMLInputElement | null>(null);
  const [urlErrorMessage, setUrlErrorMessage] = useState(
    'Invalid link. Please, try again!'
  );
  const captureListAtHost = useRecoilValue(captureListAtHostState);

  const { responsive } = useRecoilValue(responsiveDesignState);

  let group = '';
  let projectName;
  let publicAccess = true;
  if (captureListAtHost.length > 0) {
    group = captureListAtHost[0].group;
    projectName = captureListAtHost[0].projectName || '';
    publicAccess = captureListAtHost[0]?.publicAccess === true ? true : false;
  }
  const { uploadImage, uploadPDF } = useUpload(group, projectName);
  let token = isReplaceImage ? '' : null;
  const { getUserInfo } = useAuth();
  const user = getUserInfo() as User;
  const currentCap = useRecoilValue(currentCaptureInfoState);
  const setCurrentState = useSetRecoilState(currentCaptureInfoState);

  const setLoadin = () => {
    setIsLoaded(true);
  };
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    currentUserInfo.isAnonymous
      ? setLoadin()
      : (timer = setTimeout(setLoadin, 1000));

    return () => {
      timer && clearTimeout(timer);
    };
  }, []);

  const urlPatternValidation = () => {
    const expression =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);
    return regex.test(urlRef.current?.value as string);
  };
  //    Handle Submit

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (isReplaceImage && currentCap && currentCap?.url) {
      const url = new URL(currentCap?.url);
      token = url.searchParams.get('token');
    }

    if (!urlPatternValidation()) {
      setValideUrl(urlPatternValidation());
      return false;
    }
    setValideUrl(true);
    setUploading(true);
    // console.log(`Current User Id ${user.uid} `);

    const payload = {
      url: urlRef.current?.value,
      user: user,
      // group: isReplaceImage ? currentCap?.group : null,
      isReplaceImage: isReplaceImage,
      currentCap: isReplaceImage
        ? {
          cid: currentCap?.cid,
          uid: currentCap?.creator.uid,
          token: token,
          group: currentCap?.group,
        }
        : null,
    }

    try {
      const { data }: any = await httpsCallable(functions, 'uploadLiveurl')(payload);
      Loading();
      setUploading(false);

      setCurrentState(null);
      setCurrentState(currentCap);
      navigate(data.path, { replace: true });

    } catch (error: any) {
      setUploading(false);
      setValideUrl(false);
      setUrlErrorMessage(error.message);
      console.log(error);
    }
  };

  //   Handle Process

  const handleProcess: ProcessServerConfigFunction = async (
    _fieldName,
    file,
    _metadata,
    load,
    error,
    progress,
    _abort
  ) => {
    if (file.type === 'application/pdf') {
      try {
        await uploadPDF(file, file.name, (path: string) => {
          navigate(path);
          load('done');
        });
      } catch (err) {
        error('UPLOAD IS FAILED');
        console.log(err);
      }
    } else if (file.type.match(/^image/)) {
      try {
        const img = new Image();
        img.onload = async function () {
          const imgSize: ImgSize = {
            width: img.width,
            height: img.height,
          };
          await uploadImage(
            file,
            file.name,
            imgSize,
            progress,
            (path: string) => {
              navigate(path);
              load('done');
            }
          );
        };
        img.src = url.createObjectURL(file);
      } catch (err) {
        error('UPLOAD IS FAILED');
        console.log(err);
      }
    } else {
      error('This file type is not allowed here.');
    }
  };

  if (!isLoaded) return <Loading />;

  return (
    <div className="flex flex-col w-10/2 h-full justify-center text-center pb-36 ml-10 sm:ml-0 sm:mt-32">
      <div className="header1 mb-1">No captures yet.. 🙈</div>
      <div className="text-md text-black text-opacity-70">
        {!responsive && (
          <>
            Use{' '}
            <a
              className="underline text-pink"
              href="https://chrome.google.com/webstore/detail/instacap/jompclhalmbigbmjogfnfjkomponodhk"
              target="_blank"
            >
              Instacap for Chrome
            </a>{' '}
            to capture and annotate on webpages. Or &nbsp;
          </>
        )}
        <span className="underline inline-block text-pink">
          {' '}
          {currentUserInfo.isAnonymous && <SignInOut />}{' '}
        </span>{' '}
        <p>
          {!currentUserInfo.isAnonymous && 'Upload'} to collaborate in-context
          on pdf's, images and more 👍
        </p>
      </div>
      <br />
      {/* ////////////////////////////////////////////////////////////////////// */}

      <div
        onDragOver={(event) => {
          event.preventDefault();
          event.stopPropagation();
          document
            .getElementsByClassName('filepond--drop-label')[0]
            .classList.add('update-border');
          setIsDragging(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          event.stopPropagation();
          document
            .getElementsByClassName('filepond--drop-label')[0]
            .classList.remove('update-border');
        }}
        onDrop={(event) => {
          event.preventDefault();
          event.stopPropagation();
          document
            .getElementsByClassName('filepond--drop-label')[0]
            .classList.remove('update-border');
          setIsDragging(false);
        }}
      >
        <div className="flex align-center justify-center">
          <div
            className={`inline-block w-full max-w-xl  overflow-hidden text-left align-middle transition-all transform rounded-xl bg-transparent px-6  pt-6  ${!valideUrl && valueEnter ? 'pb-6' : 'pb-12'
              } sm:mt-6 `}
          >
            <h3 className="text-2xl text-gray-900 leading-8">
              <div className="flex mb-4">
                <div className="w-3/4">
                  <h4 className="fileupload-modal-heading">File Upload</h4>
                </div>
              </div>
            </h3>
            <div className="flex">
              <div className="w-full">
                <div className="file-upload-div" tabIndex={0}>
                  <div className="fileupload-dropdown">
                    <div className="container-upload mx-auto">
                      <FileUpload
                        process={handleProcess}
                        isDragging={isDragging}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="w-full relative">
                <h4 className="upload-url-txt mb-4">Or upload from a URL</h4>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    className=" upload-txtBox block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    autoFocus={false}
                    placeholder="Add the file URL"
                    ref={urlRef}
                    onChange={() => {
                      setValueEnter(urlRef.current?.value ? true : false);
                    }}
                  />

                  {/* add class border-danger if error shows  */}
                  {/* green button upload-success default button upload-btn-default                 */}

                  <button
                    disabled={uploading || !valueEnter}
                    type="submit"
                    className={`h-10 w-20 text-white rounded-lg upload-btn justify-center upload-default mx-auto mt-2 ${!valueEnter
                      ? 'upload-btn-default cursor-not-allowed'
                      : 'upload-success'
                      }   ${uploading ? 'cursor-not-allowed   ' : ''}`}
                  >
                    {uploading ? (
                      <div className="font-rubik w-full h-full flex flex-col justify-center items-center">
                        <div
                          style={{ borderTopColor: 'transparent' }}
                          className="w-5 h-5 border-1 border-black border-solid rounded-full animate-spin-fast "
                        ></div>
                      </div>
                    ) : (
                      'Upload'
                    )}
                  </button>
                </form>
              </div>
            </div>
            <div className="flex">
              {!valideUrl && valueEnter && (
                <div
                  className=" text-center text-red-700 pt-2 rounded relative"
                  role="alert"
                >
                  <span className="block sm:inline upload-error">
                    {urlErrorMessage}
                  </span>
                  <span className="absolute top-0 bottom-0 right-0 px-4 py-3"></span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* ////////////////////////////////////////////////////////////////////// */}
    </div>
  );
};

export default Empty;
