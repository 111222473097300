import React, { useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import Sidebar from '@/components/editor/sidebar';
import CanvasInputSize from '../canvasSize/canvasInputSize';
import Logo from '../../../assets/images/newlogo-white.svg';
import { useExport } from '@/hooks/useScreenshotEditor';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import CanvasSize from '../canvasSize';
import Confetti from 'react-confetti';
import { Link, useNavigate } from 'react-router-dom';
import CopyLink from '@/components/header/CopyLink';
import Dropdown from '@/components/header/Dropdown';
import MoreIcon from '@/components/icon/MoreIcon';
import { useToolObject } from '@/hooks/tool/useToolObject';
import NewEditorDropdown from '@/components/header/NewEditorDropdown';
import NewCodeEditorDropdown from '@/components/header/NewCodeEditorDropdown';
import { date } from 'yup';
import ArrowDown from '@/components/icon/ArrowDown';
import { LogOut, Save, Download } from 'lucide-react';
export default function EditorNavbar({
  handleExport,
  handleCopyImageToClipboard,
  showCanvasSize = true,
  NewCodeEditorDropdownStatus = false,
  NewEditorDropdownStatus = false,
  handleSaveImageProgress,
  handleSaveImageProgressAndExit,
}: any) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { handleFileInputChange, image } = useExport();
  const [isDropdownShow, setIsDropdownShow] = useState(false);
  const { setToolState } = useToolObject();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { height, width, name } = useSelector(
    (state: RootState) => state.editor
  );
  const handleClickTripleDots = async () => {
    setIsDropdownShow((isDropdownShow) => !isDropdownShow);
    await setToolState();
  };
  const navigate = useNavigate();
  const handleExportWithConfetti = () => {
    handleExport(width, height);
    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 2 seconds
  };
  const handleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setDrawerOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleSaveImage = (originalHandleSaveImage: any) => (event: any) => {
    event.stopPropagation();
    originalHandleSaveImage();
  };
  
  return (
    <Disclosure
      as="nav"
      className="bg-white border-b border-black z-50 fixed top-0 w-full"
    >
      {({ open }) => (
        <>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            {' '}
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex sm:flex-1 sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 items-center">
                  <Link to="/">
                    <img
                      className="h-11 w-auto"
                      src={Logo}
                      alt="Your Company"
                    />
                  </Link>
                </div>
                <p className={`has-tooltip rounded-lg ml-56 mt-1`}>
                  <input
                    className={`title-field relative outline-none text-xs text-gray-700  px-2 py-1 rounded-3xl placeholder-gray-400 focus:outline-none focus:shadow-outline`}
                    type="text"
                    value={name}
                  />
                </p>
              </div>
              <div className="flex items-center gap-3">
                {showCanvasSize && (
                  <div className="flex items-center mr-3 space-x-4 border-0 rounded-lg">
                    <CanvasInputSize />
                    <CanvasSize />
                  </div>
                )}
                <div className="relative inline-block text-left">
                  <button
                    className="btn-green-outline border-100 custom-green-btn hover:opacity-100 share-modal-open"
                    onClick={handleDropdown}
                  >
                    <span className="xsm:hidden">Save</span>
                    <ArrowDown />
                  </button>
                  {dropdownOpen && (
                    <div
                      className="origin-top-right absolute border border-black right-0 mt-2 w-56 z-30 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <div className="py-1" role="none">
                        {/* <label
                          className="text-gray-700 border-b flex px-4 py-2 text-base hover:bg-green"
                          role="menuitem"
                          onClick={handleSaveImage(handleSaveImageProgress)}
                        >
                          <Save className="mr-5" />
                          Save
                        </label> */}
                        <label
                          className="text-gray-700 flex  px-4 py-2 text-sm hover:bg-green"
                          role="menuitem"
                          onClick={handleSaveImage(
                            handleSaveImageProgressAndExit
                          )}
                        >
                          <LogOut className="mr-5" />
                          Save and Exit
                        </label>
                        <label
                          className="text-gray-700 flex  px-4 py-2 text-sm hover:bg-green"
                          role="menuitem"
                          onClick={handleExportWithConfetti}
                        >
                          <Download className="mr-5" /> 
                          Download Image
                        </label>
                      </div>
                    </div>
                  )}
                </div>

                <div className="desktop-share-dots flex sm:flex-row items-center sm:justify-between">
                  <div
                    className={`flex items-center justify-center cursor-pointer  `}
                  >
                    <div
                      className="relative flex items-center justify-center cursor-pointer sm:static "
                      onClick={handleClickTripleDots}
                      tabIndex={1}
                      // ref={ref}
                    >
                      <div
                        className={`w-11 h-11 flex items-center justify-center border-1.5 border-black rounded-full ${
                          isDropdownShow
                            ? 'bg-green-updated'
                            : 'hover:bg-gray-100'
                        }`}
                      >
                        <MoreIcon />
                      </div>
                      {NewEditorDropdownStatus === true && (
                        <NewEditorDropdown
                          isDropdownShow={isDropdownShow}
                          handleExportWithConfetti={handleExportWithConfetti}
                          handleCopyImageToClipboard={
                            handleCopyImageToClipboard
                          }
                          handleSaveImage={handleSaveImage(
                            handleSaveImageProgress
                          )}
                          handleFileInputChange={handleFileInputChange}
                        />
                      )}
                      {NewCodeEditorDropdownStatus === true && (
                        <NewCodeEditorDropdown
                          isDropdownShow={isDropdownShow}
                          handleExportWithConfetti={handleExportWithConfetti}
                          handleCopyImageToClipboard={
                            handleCopyImageToClipboard
                          }
                          handleFileInputChange={handleFileInputChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showConfetti && (
            <Confetti width={window.innerWidth} height={window.innerHeight} />
          )}
          <div className="hidden">
            <Sidebar
              handleFileInputChange={handleFileInputChange}
              handleExport={handleExport}
              width={width}
              height={height}
              image={image}
            />
          </div>
        </>
      )}
    </Disclosure>
  );
}
