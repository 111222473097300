import { FC, useEffect, useRef, useState } from 'react';
import Nav from '../header/Nav';
import HeaderButton from './HeaderButton';
import HeaderTitle from '../header/HeaderTitle';

import Logo from '../../assets/images/newlogo-white3.png';
import MenuIcon from '../../assets/icons/menu-24.png';
import headerImg from '../../assets/images/headerImage.svg';
import { User } from 'firebase/auth';
import { currentUserInfoState, profileModalState } from '@/state/userState';
import { useRecoilState, useRecoilValue } from 'recoil';
import UserAvatar from '../UserAvatar';
import AvatarDropDown from '../sidebar/avatartDropDown';
import SignIn from './SignIn';
import { Link, useParams } from 'react-router-dom';
import LearnModal from '../modal/LearnModal';
import SettingModal from '../modal/SettingModal';
import { showMyProjectState } from '@/state/uiState';
import {
  groupedByHostCaptureListSharedState,
  groupedByHostCaptureListState,
} from '@/state/captureState';
import cn from 'classnames';
import UpgradePlan from './UpgradePlan';
import { useClerkAuth } from '@/hooks/useClerkAuth';
import Teams from './Teams';
import { userSubscriptionEnum } from '@/hooks/useGetUserSubscription';

import styles from "./../../styles/index.module.scss";

type Props = {
  isExtension: boolean;
};

const NewHeader: FC<Props> = ({ isExtension }) => {
  const { isSignedIn } = useClerkAuth();

  const [isOpenNav, setNavMenu] = useState<boolean>(false);
  const [learnModal, setLearnModal] = useState<boolean>(false);
  const currentUserInfo = useRecoilValue(currentUserInfoState) as any;
  const ref = useRef<HTMLDivElement | null>(null);
  const [isDropdownShow, setIsDropdownShow] = useState(false);

  const [profileModal, setProfileModal] = useRecoilState(profileModalState);
  const openNavMenu = () => {
    setNavMenu(!isOpenNav);
  };
  const params = useParams();
  const projectId = params?.projectId;

  const showMyProject = useRecoilValue(showMyProjectState);

  const captureListAtHost = useRecoilValue(
    showMyProject
      ? groupedByHostCaptureListState
      : groupedByHostCaptureListSharedState
  );

  const captureListSharedWithMeAtHost = useRecoilValue(
    groupedByHostCaptureListSharedState
  );

  const hasCapture =
    captureListAtHost.length > 0 || captureListSharedWithMeAtHost.length > 0;

  const clickOutside = (e: Event) => {
    // inside click
    if (ref.current && ref?.current.contains(e.target as Node)) return false;

    // outside click && already opened
    if (isDropdownShow) return setIsDropdownShow(false);
  };

  const userHasTier = ({tier} : any) => ['1', '2', '3'].includes(tier);

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);

    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownShow]);

  return (
    <div
      className={cn(
        'h-screen-2/3 sm:h-auto w-full px-10 sm:px-4 lg:px-16 py-4 border-b border-black',
        (currentUserInfo.isAnonymous || isSignedIn == false) &&
        'bg-headerYellow'
      )}
    >
      {/* Navbar */}
      <div className="flex relative flex-row container-width justify-between items-center my-2">
        <div className="h-12 sm:w-1/2 flex items-center">
          <Link to={'/'}>
            <img className="w-56" src={Logo} alt="instacap-logo" />
          </Link>
        </div>
        {(!currentUserInfo.isAnonymous && isSignedIn) 
          || userHasTier({tier: currentUserInfo?.tier}) && <UpgradePlan />}
        <div className="w-full h-11 px-4 flex sm:hidden items-between justify-end">
          <Nav />
          <div className="flex items-center">
            <HeaderButton group={projectId ?? ''} />
          </div>

          {/* ///////Login    Logout   /////// */}
          <div>
            {!currentUserInfo.isAnonymous && false && (
              <div className="flex ml-4 gap-2 relative">
                {/* <div
                  className={`w-11 h-11 flex items-center justify-center border-1.5 border-black rounded-full cursor-pointer hover:bg-gray-100`}
                >
                  <MoreIcon />
                </div>
                <div
                  className={
                    'w-11 h-11 flex items-center justify-center border-1.5 border-black rounded-full hover:bg-gray-100 relative cursor-pointer'
                  }
                >
                  <BellIcon />
                  <span className="absolute right-2 top-2">
                    <NotificationDot />
                  </span>
                </div> */}
                <div
                  className={
                    'rounded-full border-2 border-solid border-black overflow-hidden avatar-lg cursor-pointer mx-auto mt-1 collapse-ava'
                  }
                  onClick={() => {
                    setIsDropdownShow(!isDropdownShow);
                  }}
                >
                  <UserAvatar
                    uid={currentUserInfo.uid}
                    width={44}
                    height={44}
                  />
                </div>
              </div>
            )}
            {!currentUserInfo.isAnonymous && false && isDropdownShow && (
              <div
                className="absolute w-56 mt-2 -ml-10 bg-white divide-y divide-gray-100 rounded-md shadow-lg avatar-dashboard-dropdown"
                role="menu"
                tabIndex={0}
                ref={ref}
              >
                <div className="" role="none">
                  {/* <SignOut /> */}
                  {/* <Header /> */}
                  <AvatarDropDown
                    isDropdownShow={true}
                    setDropdownShow={setIsDropdownShow}
                  />
                </div>
              </div>
            )}
          </div>

          {/* {currentUserInfo.isAnonymous && ( */}
          {/* {currentUserInfo.plan === userSubscriptionEnum.pro && <Teams />} */}
          <Teams/>
          <div className={`font-bold ml-4 ${styles.userProfile}`}>
            <SignIn />
          </div>
          {/* )} */}
          {/*   Login Logout  */}
        </div>

        <div className="hidden sm:block cursor-pointer" onClick={openNavMenu}>
          <img src={MenuIcon} alt="Menu" />
          {isOpenNav && <Nav />}
        </div>
      </div>
      {!hasCapture && (
        <div className="py-12 flex container-width items-center w-full">
          {/* Header Left */}
          <div className="w-1/2">
            <div className="mt-12 relative w-auto">
              <HeaderTitle />
            </div>
            <div className="h-12 w-48 sm:w-48 mb-12">
              {!isExtension ? (
                <a
                  href="https://chrome.google.com/webstore/detail/annotation-full-page-capt/jompclhalmbigbmjogfnfjkomponodhk"
                  target="blank"
                >
                  <button className="h-12 px-4 font-bold w-full rounded-3xl bg-black text-sm text-white">
                    Download Extension
                  </button>
                </a>
              ) : (
                <p className="w-96 sm:text-base text-grey dashboard-inner-text">
                  Not sure where to start?{' '}
                  <a
                    // href="https://youtu.be/I0mcCTqFX0Q"
                    // target="blank"
                    onClick={() => setLearnModal(true)}
                    className="text-pink cursor-pointer"
                  >
                    Learn how
                  </a>{' '}
                  to capture your visual feedback here.
                </p>
              )}
            </div>
          </div>
          {/* Header Right */}

          <div className="w-1/2 flex justify-center">
            <img src={headerImg} alt="instacap-logo" />
          </div>
        </div>
      )}
      {profileModal && (
        <SettingModal isOpen={true} closeModal={() => setProfileModal(false)} />
      )}
      <LearnModal isOpen={learnModal} closeModal={() => setLearnModal(false)} />
    </div>
  );
};

export default NewHeader;
