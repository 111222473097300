import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { httpsCallable } from 'firebase/functions';
import { functions } from '@/lib/firebase/auth';

import { useRecoilValue } from 'recoil';
import { currentUserIDState } from '@/state/userState';

export const useStripePortal = () => {
    const location = useLocation();
    const uid = useRecoilValue(currentUserIDState);
    const [isLoading, setIsLoading] = useState(false);

    const openStripePortal = async () => {
        try {
            setIsLoading(true);
            const payload = {
                uid,
                redirectPathname: location.pathname,
            };
            const { data }: any = await httpsCallable(functions, 'getStripePortalUrl')(payload);

            if (data && data.isSuccess && data.url) {
                window.location.href = data.url;
            } else {
                setIsLoading(false);
                console.log('Could not open billing', (data || {}).message || true);
            }

        } catch (error) {
            console.log('Could not open billing', true);
            setIsLoading(false);
        }
    };

    return { isLoading, openStripePortal };
};