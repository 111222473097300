import { FC, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon, CheckIcon } from 'lucide-react';

interface Option {
  label: string;
  value: any;
  id?: string | number;
}

interface SelectDropdownProps {
  options: Option[];
  onSelect: (value: string) => void;
  disabled: boolean;
  selectedValue: string;
}

const SelectDropdown: FC<SelectDropdownProps> = ({
  options,
  onSelect,
  disabled,
  selectedValue,
}) => {
  return (
    <Listbox value={selectedValue} onChange={onSelect} disabled={disabled}>
      <Listbox.Button className="inline-flex select-none items-center justify-center rounded-md px-4 text-[10px] font-medium bg-transparent text-white hover:bg-slate-950 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 group">
        <span>{selectedValue}</span>
        <ChevronDownIcon className="ml-2" />
      </Listbox.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options className="text-white bg-slate-950 border border-slate-800 p-2 rounded-lg shadow-lg z-50">
          {options.map((option, index) => (
            <Listbox.Option key={index} value={option.value}>
              {({ selected }) => (
                <div
                  className={`relative flex items-center px-8 py-2 z-50 rounded-md text-gray-300 font-medium focus:bg-gray-800 ${
                    selected ? 'bg-gray-950' : ''
                  }`}
                >
                  <span className="text-[10px]">{option.label}</span>
                  {selected && (
                    <CheckIcon className="absolute left-2 inline-flex items-center" />
                  )}
                </div>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
};

export default SelectDropdown;
