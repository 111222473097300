import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import ToolBar from './ToolBar';
import { useContainer } from '@/hooks/tool/useContainer';
import Loading from '../Loading';
import { isLoadingCapture } from '@/state/captureToolState';

const Container = () => {
  const isLoading = useRecoilValue(isLoadingCapture);
  const { clear } = useContainer();

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  return (
    <div>
      <div className="relative w-full flex justify-center sm:hidden">
        {isLoading && (
          <div className="tool-loading absolute top-0 left-1/4">
            <Loading />
          </div>
        )}
      </div>
      <div className="tool-bar" tabIndex={0}>
        <ToolBar />
      </div>
    </div>
  );
};

export default Container;
