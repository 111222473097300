import { FC } from 'react';

type Args = {
    className?: string,
    color?: any,
    colorDark?: string,
    center?: boolean,
    fullScreen?: boolean,
    size?: number,
};

const Loader: FC<Args> = ({ size = 56, className, color, colorDark, center, fullScreen }) => {
    return (
        <div className={`${fullScreen || center ? `flex items-center justify-center ${fullScreen ? 'h-full' : ''}` : ''} ${className || ''}`}>
            <div className="loader">
                <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"
                    width={size} height={size} viewBox="0 0 50 50">
                    <path fill="currentColor" className={color ? `text-${color} dark:text-${colorDark}` : 'text-gray-800 dark:text-gray-300'}
                        d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
                        <animateTransform attributeType="xml"
                            attributeName="transform"
                            type="rotate"
                            from="0 25 25"
                            to="360 25 25"
                            dur="0.6s"
                            repeatCount="indefinite" />
                    </path>
                </svg>
            </div>
        </div>
    );
};

export default Loader;
