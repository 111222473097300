import { useState, FC, useMemo, useRef, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { User } from 'firebase/auth';
import { useFunction } from '@/hooks/useFunction';

import { isAppLoadingState } from '@/state/appState';
import {
  currentUserIDState,
  currentUserInfoState,
  currentUserSubscriptionState,
} from '@/state/userState';

import Trash from '../icon/Trash';
import Pencil from '../icon/Pecil';
import DeleteModal from '../modal/DeleteModal';
import { useClickOutside } from '@/hooks/useClickOutside';
import { Project } from 'types/project';
import RenameProjectModal from '../modal/RenameProjectModal';
import { useGetUserSubscription } from '@/hooks/useGetUserSubscription';
import { NotifyMessage, useShowNotifyMsg } from '../notify';
import { capturesDeletedState } from '@/state/captureState';
import { updateCapture } from '@/lib/firebase/firestore';
import ArrowRestore from '../icon/ArrowRestore';
import { useOrganization } from '@clerk/clerk-react';

const DropdownProject: FC<{
  isDropdownShow: boolean;
  setDropDownShow: Function;
  group: string;
  data: Project;
  isExpired?: boolean;
  isDeleted?: boolean;
}> = ({
  isDropdownShow,
  group,
  setDropDownShow,
  data,
  isExpired,
  isDeleted,
}) => {
    const { deleteProjectCapture } = useFunction();
    const currentUserID = useRecoilValue(currentUserIDState);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [renameOpen, setRenameOpen] = useState(false);
    const [restoreOpen, setRestoreOpen] = useState(false);
    const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
    const capturesDeleted = useRecoilValue(capturesDeletedState);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const setIsAppLoading = useSetRecoilState(isAppLoadingState);
    const { onButtonClick, showDiv } = useShowNotifyMsg();
    const { subscription } = useGetUserSubscription(currentUserID);

    const { organization } = useOrganization();

    const deleteImageWithPopup = (event: any) => {
      event.stopPropagation();
      event.preventDefault();

      setDropDownShow(false);
      setIsDeleteModalOpen(true);
    };

    const list = useMemo(
      () => isDeleted ?
        [
          {
            text: 'Restore all',
            Icon: ArrowRestore,
            fn: () => setRestoreOpen(true),
            needPermission: false,
            classname: '',
            htmlContent: '',
            isLineBreak: false,
            isDisabled: capturesDeleted?.length == 0,
          },
        ] :
        [
          {
            text: 'Change name',
            Icon: Pencil,
            fn: () => setRenameOpen(true),
            needPermission: false,
            classname: '',
            htmlContent: '',
            isLineBreak: false,
          },
          {
            text: 'Delete Project',
            Icon: Trash,
            fn: deleteImageWithPopup,
            needPermission: !(data.uid === currentUserInfo.uid),
            classname: 'text-danger',
            htmlContent: '',
            isLineBreak: false,
          },
        ],
      [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        currentUserInfo,
        data,
        capturesDeleted,
      ]
    );

    // Hide dropdown on outside click
    useClickOutside(dropdownRef, () => setDropDownShow(false));

    const Proceed = async (event: any) => {
      event.stopPropagation();
      event.preventDefault();
      setIsDeleteModalOpen(false);
      await deleteProjectCapture(group);
      setIsAppLoading(false);
    };

    const closeDeleteModal = () => {
      setIsDeleteModalOpen(false);
    };

    useEffect(() => {
      if (!restoreOpen) return;
      if (isDeleted) {

        const userId = organization ? organization.id : currentUserID
        // const userId = currentUserID

        setIsAppLoading(true);
        capturesDeleted.forEach((capture) => {
          updateCapture(userId, capture.cid, {
            isDeleted: false,
          });
        });
      }

      setRestoreOpen(false);
    }, [restoreOpen]);

    return (
      <>
        {isDropdownShow && (
          <div
            ref={dropdownRef}
            className="absolute top-8  w-56 mt-2 -ml-2 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  sidebar-dropdown"
            role="menu"
            tabIndex={1}
            style={{ zIndex: 20 }}
          >
            <div className="py-2" role="none">
              {list.map(({ text, Icon, fn, needPermission, classname, isLineBreak, isDisabled = false }: any, index) => {
                if (needPermission && !organization) return null;
                // if (needPermission) return null;
                return (
                  <>
                    {!isLineBreak ? (
                      <button
                        type="button"
                        key={index}
                        className={`dropdown-item text-gray-900 group flex rounded-md items-center w-full px-2  text-sm hover:bg-green hover:text-black ${isDisabled ? 'opacity-25' : ''}`}
                        role="menuitem"
                        {...!isDisabled && { onClick: fn }}
                      >
                        <div className="flex justify-center w-6 min-w-min min-h-max mr-2">
                          <Icon width={16} height={16} />
                        </div>
                        <span className={`${classname}`}>{text}</span>
                      </button>
                    ) : (
                      <div key={index} className="container-dropdown px-4">
                        <hr className="my-2"></hr>
                      </div>
                    )}
                  </>
                );
              }
              )}
            </div>
          </div>
        )}

        <div>
          <NotifyMessage
            title={
              "As a free Plan Member, you can't restrict access to specific individual user for the project."
            }
            show={showDiv}
            wrapperClassName={''}
            childClassName={
              'absolute p-3 -bottom-6 left-10 bg-black bg-opacity-70 text-white w-96 rounded-md text-md'
            }
          />
        </div>

        <DeleteModal
          isDeleteModalOpen={isDeleteModalOpen}
          closeModal={closeDeleteModal}
          Proceed={Proceed}
          deleteType="Project"
        />
        <RenameProjectModal
          isOpen={renameOpen}
          closeModal={(e: boolean) => {
            setRenameOpen(false);
          }}
          data={data}
        />
      </>
    );
  };

export default DropdownProject;
