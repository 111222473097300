type Props = {
    width?: number,
    height?: number
}
const SignOut = (props: Props) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
        <path
            d="M7.99723 2H2V14H8"
            stroke="black"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11 11L14 8L11 5"
            stroke="black"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.33325 7.99724H13.9999"
            stroke="black"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>

);
export default SignOut;
