import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { pathState } from '@/state/appState';
import { useCaptureInfo } from '@/hooks/useCaptureInfo';
import { isGroupIdVerifiedState } from '@/state/captureState';
import { currentUserInfoState } from '@/state/userState';
import { User } from 'firebase/auth';
import { updateShareInvite } from '@/lib/firebase/firestore';

let unsub: Function | null = null;

const PublicGroup = () => {
  const { uid, cid } = useRecoilValue(pathState);
  const isGroupIdVerified = useRecoilValue(isGroupIdVerifiedState);
  const { getCaptureInfoSub } = useCaptureInfo();
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User

  useEffect(() => {
    if (!uid || !cid || !isGroupIdVerified) return;
    unsub && unsub();
    unsub = getCaptureInfoSub(uid, cid);
    if (!currentUserInfo.isAnonymous && cid && uid && isGroupIdVerified) {
      updateShareInvite(currentUserInfo.email || '', cid, { IniviteStatus: 'ACCEPTED', uid: currentUserInfo.uid, displayName: currentUserInfo.displayName }, uid)
    }
    return () => {
      unsub && unsub();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, cid, isGroupIdVerified]);

  return null;
};

export default PublicGroup;
