import { FC, memo, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { filter, uniqBy } from 'lodash';

import {
  currentCaptureInfoState,
  groupedByHostCaptureListSharedState,
  groupedByHostCaptureListState,
} from '@/state/captureState';

import CaptureItem from './CaptureItem';
import { currentUserIDState, currentUserInfoState } from '@/state/userState';
import { useGetUserSubscription } from '@/hooks/useGetUserSubscription';
import { projectListStateWithCapturesState } from '@/state/projectState';
import { pathState } from '@/state/appState';
import { useToolObject } from '@/hooks/tool/useToolObject';

type CaptureListProps = {
  sharedWithMe: boolean;
};

const CaptureList: FC<CaptureListProps> = ({ sharedWithMe }) => {
  const navigate = useNavigate();
  const {setToolStateAfterInsert} = useToolObject();

  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const groupedByHostCaptureList = useRecoilValue(
    !sharedWithMe
      ? groupedByHostCaptureListState
      : groupedByHostCaptureListSharedState
  );
  const currentUserInfo = useRecoilValue(currentUserInfoState)
  const currentUserID = useRecoilValue(currentUserIDState);
  const projectListStateWithCaptures = useRecoilValue(
    projectListStateWithCapturesState
  );
  const { subscription } = useGetUserSubscription(currentUserID);
  const {cid} = useRecoilValue(pathState)

  const [currentCapturesList, setCurrentCapturesList] = useState<
    Capture.Info[]
  >([]);
  const setCurrentState = useSetRecoilState(currentCaptureInfoState);

  useEffect(() => {
    if (currentUserID == "") return; //not prepare userId to get plan type
    const currentCapturesWithHost = groupedByHostCaptureList.find((project) => {
      const index = project[1].findIndex(
        (capture) => capture.cid === currentCaptureInfo?.cid
      );
      return index !== -1;
    }); 
  
    if (currentCapturesWithHost) {
      let captureLs = uniqBy(currentCapturesWithHost[1], 'cid');
      captureLs = filter(captureLs, (capture) => !capture.isDeleted)
      if (subscription === 'free' && currentUserInfo?.tier !== "1") {
        const currentDateUnix = Math.floor(new Date().getTime() / 1000);
        captureLs = captureLs.filter((item) => item.expiredAt && item.expiredAt > currentDateUnix);
      }

      setCurrentCapturesList(
        [...captureLs].sort((a, b) => a?.order! - b?.order! ?? false)
      );
    }
  }, [currentCaptureInfo, subscription, currentUserID, groupedByHostCaptureList, cid]);

  const handleCaptureChoose = (targetCapture: Capture.Info) => {
    navigate(`${targetCapture.creator.uid}/${targetCapture.cid}`);
    setCurrentState(targetCapture)
    setToolStateAfterInsert()
  };

  return (
    <div id="sidebar-capture-list" className="overflow-y-auto max-h-70%">
      {currentCapturesList.map((capture, captureIndex) => {
        return (
          <CaptureItem
            key={`capture ${captureIndex}`}
            capture={capture}
            sequenceNumber={captureIndex}
            onChoose={handleCaptureChoose}
          />
        );
      })}
    </div>
  );
};

export default memo(CaptureList);
