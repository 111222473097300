import { FC, memo } from 'react';

interface props {
  width?: number;
  height?: number;
  fill?: string;
  opacity?: number;
  strokeColor?: string;
}

const BoxIcon: FC<props> = ({
  height = 18,
  width = 18,
  opacity = 1,
  fill = 'none',
  strokeColor = '#808080',
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      opacity={opacity}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3504_23220)">
        <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
        <path
          d="M14.6668 4.66683L8.00016 1.3335L1.3335 4.66683V11.3335L8.00016 14.6668L14.6668 11.3335V4.66683Z"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinejoin="round"
        />
        <path
          d="M1.3335 4.6665L8.00016 7.99984"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 14.6667V8"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6667 4.6665L8 7.99984"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3337 3L4.66699 6.33333"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3504_23220">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(BoxIcon);
