import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  captureIsPublicState,
  captureIsPublicGroupState,
  currentCaptureIsPdfState,
  getCurrentUserAccessType,
} from '@/state/captureState';
import { useFunction } from '@/hooks/useFunction';

import ForCaptureIcon from '../icon/ForCaptureIcon';
import ForEntireProjectIcon from '../icon/ForEntireProjectIcon';

const CopyLinkShareDropdown: FC<{ isCopyLinkShareDropdownShow: boolean }> = ({
  isCopyLinkShareDropdownShow,
}) => {
  const captureIsPublic = useRecoilValue(captureIsPublicState);
  const captureIsPublicGroup = useRecoilValue(captureIsPublicGroupState);
  const currentCaptureIsPdf = useRecoilValue(currentCaptureIsPdfState);
  const currentAccessType = useRecoilValue(getCurrentUserAccessType);
  const { copyLinkToClipboard, copyGroupLinkToClipboard } = useFunction();

  const list = useMemo(
    () => {
      const data = [
        {
          text: 'For Entire Project',
          Icon: ForEntireProjectIcon,
          fn: copyGroupLinkToClipboard,
          needPermission: currentAccessType !== 'EDIT-PROJECT-ACCESS',
          classname: '',
          htmlContent: '',
        },
        {
          text: 'For Capture Only',
          Icon: ForCaptureIcon,
          fn: copyLinkToClipboard,
          needPermission: false,
          classname: '',
          htmlContent: '',
          disabled: false,
        },
        // { text: 'Delete',
        // Icon: TrashMenu,
        //  fn: deleteImage,
        //   needPermission: true },
        // {
        //   text: 'Modal',
        //   Icon: TrashMenu,
        //   fn: () => {
        //     setIsDeleteModalOpen(true);
        //   },
        //   needPermission: false
        // },
      ];
      // if (currentCaptureIsPdf) {
      //   data.shift();
      // }
      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [captureIsPublicGroup, currentCaptureIsPdf]
  );

  return (
    <>
      {isCopyLinkShareDropdownShow && (
        <div
          className="z-20 absolute w-56 mt-2 -ml-2 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none copy-link-dropdown"
          role="menu"
          tabIndex={0}
        >
          <div className="py-2" role="none">
            {list.map(
              (
                {
                  text,
                  Icon,
                  fn,
                  needPermission,
                  classname,
                  htmlContent,
                  disabled,
                },
                index
              ) => {
                if (captureIsPublic && needPermission) return null;
                if (currentCaptureIsPdf && text === 'Replace Image')
                  return null;
                return (
                  <>
                    {!htmlContent ? (
                      <button
                        disabled={disabled}
                        type="button"
                        key={index}
                        className={`${
                          disabled ? 'cursor-not-allowed ' : ''
                        }px-3 text-gray-900 group flex rounded-md items-center w-full px-2  text-sm ${
                          disabled ? 'bg-grey-shareInput' : 'hover:bg-green '
                        } hover:text-black  email-view-dropdown-items `}
                        role="menuitem"
                        onClick={fn}
                      >
                        <div className="flex justify-center w-6 min-w-min min-h-max pr-2">
                          <Icon />
                        </div>
                        <span className={`${classname}`}>{text}</span>
                      </button>
                    ) : (
                      <div className="container px-4">
                        <hr className="my-2"></hr>
                      </div>
                    )}
                  </>
                );
              }
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CopyLinkShareDropdown;
