import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useRecoilState } from 'recoil';
import { isMouseInsideEmojiPickerState } from '@/state/uiState';
import Emoji from './components/Emoji/Emoji';
import EmojiFooter from './components/EmojiFooter/EmojiFooter';
import EmojiHeader from './components/EmojiHeader/EmojiHeader';
import SearchIcon from '../../icon/SearchIcon';

import { requiredEmojiesGroups } from './emojisGroups';
import Emojies_JSON from './emojis.json';
import './EmojiPicker.css';
import { createPortal } from 'react-dom';

const emojiGroupNames = [
  'Frequently Used',
  'Smiles & People',
  'Animals & Nature',
  'Food & Drink',
  'Activities',
  'Travel & Places',
  'Objects',
];
interface EmojiPickerProps {
  show: boolean;
  positioning: {
    targetElementRect: DOMRect;
    offset: {
      x: number;
      y: number;
    };
  };
  addIconHandler: any;
  onHide: () => any;
}

const EmojiPicker: FC<EmojiPickerProps> = ({
  show,
  positioning,
  addIconHandler,
  onHide,
}) => {
  const windowInnerWidth = window.innerWidth;

  const [isMouseInside, setIsMouseInside] = useRecoilState(
    isMouseInsideEmojiPickerState
  );

  const [activeGroupNumber, setActiveGroupNumber] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');
  const [emojiesData, setEmojiesData] = useState<any>({});
  const [isPositiongCompleted, setIsPositioningCompleted] =
    useState<boolean>(false);

  const groupsRefs = useRef<Array<HTMLDivElement | null>>([]);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);

  const determineActiveGroup = useCallback((e: any) => {
    if (!groupsRefs.current) return;
    for (let i = 0; i < groupsRefs.current.length; i++) {
      const groupitem = groupsRefs.current[i];
      const nextGroupItem = groupsRefs.current[i + 1];

      if (!groupitem) return;

      if (e.target.scrollTop >= groupitem.offsetTop) {
        if (i == groupsRefs.current.length - 1) {
          setActiveGroupNumber(i);
        } else {
          if (!nextGroupItem) return;

          if (e.target.scrollTop < nextGroupItem.offsetTop) {
            setActiveGroupNumber(i);
          }
        }
      }
    }
  }, []);

  const getEmojies = useCallback(() => {
    const emojies: any = {};

    const keys = Object.keys(requiredEmojiesGroups);

    for (const key of keys) {
      emojies[key] = [];

      for (let i = 0; i < requiredEmojiesGroups[key].length; i++) {
        emojies[key] = emojies[key].concat(
          Emojies_JSON.emojis.filter((data) =>
            data.category.includes(requiredEmojiesGroups[key][i])
          )
        );
      }

      emojies[key] = emojies[key].map((data: any) => {
        return {
          emoji: data.emoji,
          name: data.name,
        };
      });
    }

    return emojies;
  }, []);

  const windowClickOutsideEmojiContainer = (e: any) => {
    if (!isMouseInside) onHide();
  };

  useEffect(() => {
    //Load emojies
    setEmojiesData(getEmojies());
  }, []);

  useEffect(() => {
    document.removeEventListener('click', windowClickOutsideEmojiContainer);
    document.addEventListener('click', windowClickOutsideEmojiContainer);

    return () => {
      document.removeEventListener('click', windowClickOutsideEmojiContainer);
    };
  }, [onHide]);

  useLayoutEffect(() => {
    if (emojiPickerRef.current) {
      if (windowInnerWidth > 860) {
        emojiPickerRef.current.style.top =
          positioning.targetElementRect.top - positioning.offset.y + 'px';

        emojiPickerRef.current.style.left =
          positioning.targetElementRect.left - positioning.offset.x + 'px';
      }

      setIsPositioningCompleted(true);
    }
  }, [positioning]);

  useEffect(() => {
    if (searchText === '') {
      setEmojiesData(getEmojies());
    } else {
      setEmojiesData((prev: any) => {
        const groups = Object.keys(prev);

        const tempEmojiesData = JSON.parse(JSON.stringify(prev));

        for (const group of groups) {
          tempEmojiesData[group] = tempEmojiesData[group].filter((data: any) =>
            data.name.toLowerCase().includes(searchText.toLowerCase())
          );
        }

        return tempEmojiesData;
      });
    }
  }, [searchText]);

  if (!show) return null;

  return createPortal(
    <div
      ref={emojiPickerRef}
      className={`emoji-container ${isPositiongCompleted ? '' : 'hidden'}`}
      onMouseEnter={() => setIsMouseInside(true)}
      onMouseLeave={() => setIsMouseInside(false)}
    >
      <div
        className="emoji-picker"
        onMouseEnter={() => setIsMouseInside(true)}
        onMouseLeave={() => setIsMouseInside(false)}
      >
        <div className="flex flex-col">
          <EmojiHeader
            activeGroupNumber={activeGroupNumber}
            groupsRefs={groupsRefs}
          />

          <hr className="my-3" />

          <div className="relative pb-2">
            <input
              className={`search-field w-full border-solid border-2 border-gray-300 rounded-full text-sm ${
                windowInnerWidth < 595 ? 'pl-8' : 'pl-6'
              }`}
              placeholder="Search"
              type="text"
              value={searchText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchText(e.target.value)
              }
            />
            <div className="search-icon">
              <SearchIcon
                opacity={0.5}
                width={windowInnerWidth < 595 ? 15 : 10}
                height={windowInnerWidth < 595 ? 15 : 10}
              />
            </div>
          </div>

          <div
            className="relative icons-container overflow-hidden overflow-y-scroll"
            onScroll={(e) => {
              determineActiveGroup(e);
            }}
          >
            {emojiGroupNames.map((groupName, index) => (
              <div
                key={`group ${groupName} ${index}`}
                ref={(el) => (groupsRefs.current[index] = el)}
              >
                <div
                  className={
                    (activeGroupNumber === index
                      ? 'sticky -top-2.5'
                      : 'static') + ' bg-white w-full duration-200'
                  }
                >
                  <p className="py-1.5">
                    {groupName.includes('Smiles')
                      ? groupName.replace('Smiles', 'Smileys')
                      : groupName}
                  </p>
                </div>

                <div className=''>
                  {emojiesData[groupName]?.map((data: any, index: number) => (
                    <button
                      className="emoji-btn"
                      key={`main emoji ${index}`}
                      onClick={() => {
                        addIconHandler(data.emoji);
                        onHide();
                      }}
                    >
                      <Emoji
                        key={`${data.emoji} ${index}`}
                        emoji={data.emoji}
                      />
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <hr className="mt-3 mb-3.5" />

          <EmojiFooter emojiClickHandler={addIconHandler} onHide={onHide} />
        </div>
      </div>
    </div>,
    document.body
  );
};

export default memo(EmojiPicker);
