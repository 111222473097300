import { FC } from 'react';
import { Dialog } from '@headlessui/react';
import Template from './Template';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  currentCaptureInfoState,
  sortedCaptureCommentState,
} from '@/state/captureState';
// import Loading from '../Loading';
import CircleClose from '../icon/CircleClose';
import { CaptureCommentBox } from '../capture/CaptureComment';
import { currentUserIDState } from '@/state/userState';
import EmptyComments from '../icon/EmptyComments';
import dayjs from 'dayjs';
import Pencil from '../icon/Pecil';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  info: any;
};

const PreviewModal: FC<Props> = ({ isOpen, closeModal, info }) => {
  const currentUserID = useRecoilValue(currentUserIDState);
  const captureComment = useRecoilValue(sortedCaptureCommentState);
  const setCurrentCapture = useSetRecoilState(currentCaptureInfoState);
  const navigate = useNavigate();

  return (
    <Template isOpen={isOpen} closeModal={() => closeModal()}>
      <div
        className={`inline-block w-full max-w-4xl  overflow-hidden text-left align-middle transition-all transform rounded-xl bg-white px-6  pt-6 sm:mt-6 default-modal `}
      >
        <Dialog.Title as="h3" className="text-2xl text-gray-900 leading-8">
          <div className="flex">
            <div className="w-3/4">
              <h4 className="fileupload-modal-heading">{info.name}</h4>
            </div>
            <div className="w-1/4 float-right" onClick={closeModal}>
              <div className="float-right mb-5">
                <div className="flex items-center cursor-pointer">
                  <CircleClose />
                </div>
              </div>
            </div>
          </div>
          <hr />
        </Dialog.Title>
        <div className="flex" style={{ maxHeight: '70%' }}>
          <div className="w-full flex flex-col my-10 h-96 mx-10">
            <div className=" overflow-hidden">
              <img src={info.url} className="rounded-2xl" />
            </div>
            <div className="py-2 upload-grey-txt ">
              Created{' '}
              {dayjs
                .unix(info.createdAt)
                .fromNow()
                .replaceAll('minutes', 'mins').length > 11
                ? dayjs
                    .unix(info.createdAt)
                    .fromNow()
                    .replaceAll('minutes', 'mins')
                    .slice(0, 11) + '...'
                : dayjs
                    .unix(info.createdAt)
                    .fromNow()
                    .replaceAll('minutes', 'mins')}{' '}
              by{' '}
              <span className="text-black font-medium">
                {info.creator.displayName}
              </span>
            </div>
          </div>
          <div className="w-full flex flex-col items:center justify-center my-10 h-96  relative">
            <div className="comment-modal h-4/5 px-2 overflow-hidden">
              <div className="p-3">
                <h2 className="font-bold">
                  {`${info.comments ? info.comments.length : 0}`} Comments
                </h2>
              </div>
              <div className="overflow-y-scroll h-full pb-12">
                {captureComment &&
                ((captureComment.length === 1 && captureComment[0].isTemp) ||
                  captureComment.length === 0) ? (
                  <div className="flex justify-center flex-col items-center py-6">
                    <EmptyComments />
                    <p className="py-2">There are no comments yet</p>
                  </div>
                ) : (
                  captureComment.map(({ index, ...rest }) => (
                    <CaptureCommentBox key={index} index={index} {...rest} />
                  ))
                )}
              </div>
            </div>
            <div className="w-full text-xs h-2/5 bottom-card-dashboard flex flex-col items-center justify-center">
              <div>
                <div
                  className='w-full'
                  onClick={() => {
                    closeModal();
                    navigate(`/${info.creator.uid}/${info.cid}`);
                  }}
                >
                  <button className="rounded-full px-16 py-3 bg-black text-white w-full h-11">
                    Go to the Capture Board
                  </button>
                </div>
                <div
                  className='w-full'
                  onClick={() => {
                    closeModal();
                    navigate(`/editor/${info.creator.uid}/${info.cid}`);
                  }}
                >
                  <button className="rounded-full h-11 mt-3 px-24 py-3 bg-white text-black border-2 border-black font-semibold w-full flex items-center justify-center">
                    <Pencil />
                    Edit Capture
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default PreviewModal;
