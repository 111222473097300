import { FC } from 'react';

interface props {
  width?: number;
  height?: number;
  opacity?: number;
}

const CloseInCircleIcon: FC<props> = ({
  height = 16,
  width = 16,
  opacity = 1,
}) => {
  return (
    <svg
      width={height}
      height={width}
      opacity={opacity}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3626_74448)">
        <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
        <path
          d="M7.99967 14.6663C11.6816 14.6663 14.6663 11.6816 14.6663 7.99967C14.6663 4.31777 11.6816 1.33301 7.99967 1.33301C4.31777 1.33301 1.33301 4.31777 1.33301 7.99967C1.33301 11.6816 4.31777 14.6663 7.99967 14.6663Z"
          stroke="black"
          strokeLinejoin="round"
        />
        <path
          d="M9.88549 6.11426L6.11426 9.88549"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.11426 6.11426L9.88549 9.88549"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3626_74448">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseInCircleIcon;
