import React, { FC, MouseEvent, useRef, useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { User } from 'firebase/auth';

import {
  canAddNewCommentState,
  sortedCaptureCommentState,
} from '@/state/captureState';
import {
  commentPositionListState,
  currentCommentState,
  DEFAULT_CURRENT_COMMENT,
  isResolvedHidden,
  showCommentNumber,
} from '@/state/commentState';
import {
  closeWithVideoState,
  loomRecordingState,
  isMouseInsideEmojiPickerState,
  isVideoUploadedState,
  showCommentsTabState,
  emojiPickerState,
  sidebarState,
} from '@/state/uiState';
import { captureBoardScrollYState } from '@/state/uiState';
import { markerPositionListState } from '@/state/markerState';
import { currentUserInfoState } from '@/state/userState';
import { responsiveDesignState } from '@/state/responsiveState';

import { useMarker } from '@/hooks/useMarker';
import { useQuerystring } from '@/hooks/useQuerystring';
import { useComment } from '@/hooks/useComment';

import CaptureCommentReply from './CaptureCommentReply';
import CaptureCommentContent from './CaptureCommentContent';
import CaptureCommentTextarea from './CaptureCommentTextarea';
import UserAvatar from '../UserAvatar';

import ArrowForward from '../icon/ArrowForward';
import ArrowBackword from '../icon/ArrowBackword';
import EmptyComments from '../icon/EmptyComments';
import Box from '../icon/Box';
import HelpIcon from '../icon/HelpIcon';
import EmojiPicker from '../emoji/emojiPicker/EmojiPicker';

export const CaptureCommentBox: FC<Capture.SortedComment> = ({
  replies,
  index,
  ...rest
}) => {
  // const { setCommentPosition } = useComment()
  const setCaptureBoardScrollY = useSetRecoilState(captureBoardScrollYState);
  const [showReply, setShowReply] = useRecoilState(showCommentNumber);
  const [showEmojiPicker, setShowEmojiPicker] =
    useRecoilState(emojiPickerState);
  const [currentComment, setCurrentComment] =
    useRecoilState(currentCommentState);

  const markerPositionList = useRecoilValue(markerPositionListState);
  const isMouseInsideEmojiPicker = useRecoilValue(
    isMouseInsideEmojiPickerState
  );

  const { resetTempMarker } = useMarker();
  const { setCommentPosition } = useComment();
  const { getQs, setQs, removeQs } = useQuerystring();

  const bottomEl = useRef<HTMLDivElement | null>(null);

  const [showReplies, toggleShowReplies] = useState(false);
  useEffect(() => {
    if (currentComment.commentIndex === index) {
      markerPositionList[index] &&
        setCaptureBoardScrollY(markerPositionList[index].y);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerPositionList[index]]);

  useEffect(() => {
    const commentNo = parseInt(getQs('comment') || '0') - 1;
    if (commentNo >= 0 && commentNo === index) {
      setCurrentComment((old) => ({
        ...DEFAULT_CURRENT_COMMENT,
        commentIndex: commentNo,
        // type: 'REPLY',
        timestamp: dayjs().unix(),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setCommentPosition(index, {
      x: bottomEl.current?.getBoundingClientRect().x || 0,
      y: bottomEl.current?.getBoundingClientRect().top || 0,
    });
  }, []);

  useEffect(() => {
    if (rest.content && currentComment.commentIndex === index) {
      // setCurrentComment((old) => ({
      //   ...old,
      //   type: 'REPLY',
      //   timestamp: dayjs().unix(),
      // }));
      setQs('comment', index + 1);
    }
    setShowReply((old) => ({
      commentIndex: currentComment.commentIndex,
      showReply:
        old.commentIndex === currentComment.commentIndex
          ? old.showReply
          : false,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.isTemp, rest.content, currentComment.timestamp, replies]);

  const style = clsx({
    // eslint-disable-next-line no-useless-computed-key
    ['border-3 border-green-custom']: currentComment.commentIndex === index,
  });

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (showReply.showReply && showReply.commentIndex !== index) {
      setShowReply({ showReply: false, commentIndex: index });
    }
    setCurrentComment((old) => ({
      ...old,
      commentIndex: index,
      type: old.commentIndex !== index ? 'POST' : old.type,
      // timestamp: dayjs().unix(),
    }));
    !rest.isTemp && resetTempMarker();
    markerPositionList.length > index &&
      setCaptureBoardScrollY(markerPositionList[index].y);
    // }
  };

  const toggleReplyTextArea = () => {
    setCurrentComment((old) => ({
      ...DEFAULT_CURRENT_COMMENT,
      commentIndex: index,
      type: old.type === 'NEW' && old.commentIndex === index ? 'NEW' : 'NEW',
      timestamp: dayjs().unix(),
    }));
    setShowReply((old) => ({
      commentIndex: index,
      showReply: currentComment.type === 'POST' ? true : true,
    }));

    !rest.isTemp && resetTempMarker();
    markerPositionList.length > index &&
      setCaptureBoardScrollY(markerPositionList[index]?.y);
  };

  const toggleReplies = () => {
    toggleShowReplies(!showReplies);
  };

  return (
    <div
      key={index}
      // className={`cursor-pointer flex w-96 max-w-xs sm:w-full sm:max-w-none mb-4 b ${style}`}
      onClick={handleClick}
      onBlur={() => {
        !rest.isTemp && removeQs('comment');
      }}
    >
      <div
        className={`parent-card pl-4 pr-2 `}
        onClick={(e) => {
          if (showEmojiPicker.show && !isMouseInsideEmojiPicker) {
            setShowEmojiPicker({
              show: false,
              positioning: {
                targetElementRect: {
                  height: 0,
                  width: 0,
                  x: 0,
                  y: 0,
                } as DOMRect,
                offset: {
                  x: 0,
                  y: 0,
                },
              },
              handleAddIcon: () => { },
              handleHide: () => { },
            });
          }
        }}
        style={{ display: rest.content ? '' : 'none' }}
      >
        <div className={`right-inner-card p-3 mb-3  ${style}`}>
          <div className="flex flex-col w-full bg-white h-auto rounded-xl">
            <div className="" ref={bottomEl}>
              {rest.content && (
                <CaptureCommentContent
                  commentIndex={index}
                  isResolve={rest.isResolved}
                  commentReplyEmojies={rest.replyEmojies || []}
                  {...rest}
                  isMain={true}
                  totalReplies={replies?.length || 0}
                  toggleReplies={toggleReplies}
                  toggleReplyTextArea={toggleReplyTextArea}
                />
              )}

              {showReply.commentIndex === index &&
                showReply.showReply &&
                replies &&
                replies?.length > 0 ? (
                <div>
                  <hr className="my-3" />
                  <h5 className="replies my-3">Replies</h5>
                </div>
              ) : null}

              {showReply.commentIndex === index &&
                showReply.showReply &&
                replies?.map((reply, replyIndex) => {
                  if (!reply.content) return <></>;
                  return (
                    <CaptureCommentReply
                      key={"replyIndex-" + replyIndex}
                      totalReplies={replies.length || 0}
                      index={replyIndex}
                      commentIndex={index}
                      resolve={rest.isResolved}
                      replyEmojies={
                        reply.replyEmojies ? reply.replyEmojies : []
                      }
                      {...reply}
                      isMain={false}
                      toggleReplies={toggleReplies}
                      toggleReplyTextArea={toggleReplyTextArea}
                    />
                  );
                })}
            </div>
          </div>
          {currentComment.commentIndex === index &&
            !currentComment.isBeingModify &&
            currentComment.type === 'NEW' ? (
            <CaptureCommentTextarea isNew={rest.isTemp === true} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const CaptureComment = () => {
  const { setQs } = useQuerystring();

  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const canCommentUser = useRecoilValue(canAddNewCommentState);
  const captureComment = useRecoilValue(sortedCaptureCommentState);
  const isVideoUploaded = useRecoilValue(isVideoUploadedState);
  const isLoomRecording = useRecoilValue(loomRecordingState);

  const { comments } = useRecoilValue(responsiveDesignState);

  const setCloseComments = useSetRecoilState(responsiveDesignState);
  const setCaptureBoardScrollY = useSetRecoilState(captureBoardScrollYState);
  const [isResolveHidden, setResolveHidden] = useRecoilState(isResolvedHidden);
  const [showCommentsTab, setShowCommentTab] =
    useRecoilState(showCommentsTabState);
  const [currentComment, setCurrentComment] =
    useRecoilState(currentCommentState);
  const [closeWithVideo, setCloseWithVideo] =
    useRecoilState(closeWithVideoState);
  const [showEmojiPicker, setShowEmojiPicker] =
    useRecoilState(emojiPickerState);

  const bottomEl = useRef<HTMLDivElement | null>(null);

  const resloved = useRef<HTMLDivElement | null>(null);
  const unResloved = useRef<HTMLDivElement | null>(null);

  const [toggle, setToggle] = useState(true);
  const [openTab, setOpenTab] = React.useState(1);
  const [showMessageToolTip, setShowMessageToolTip] = useState(false);
  const commentPositionList = useRecoilValue(commentPositionListState);
  const markerPositionList = useRecoilValue(markerPositionListState);

  const toggleClass = ' transform translate-14px  custom-radio';
  const [totalResolveComment, setTotalResolveComment] = useState(0);
  const [totalUnResolveComment, setTotalUnResolveComment] = useState(0);

  useEffect(() => {
    if (window.location.href.includes('isEmail')) {
      setShowCommentTab(true);
    }
  }, []);

  //Scroll blocking when EnojiPicker is opened
  useEffect(() => {
    if (!bottomEl.current) return;

    if (showEmojiPicker.show) {
      bottomEl.current.style.overflowY = 'hidden';
    } else {
      bottomEl.current.style.overflowY = 'scroll';
    }
  }, [showEmojiPicker, bottomEl]);

  useEffect(() => {
    if (
      bottomEl.current &&
      !currentComment.isBeingModify &&
      currentComment.commentIndex !== -1 &&
      commentPositionList.length > currentComment.commentIndex
    ) {
      bottomEl.current?.scrollTo({
        top:
          commentPositionList[currentComment.commentIndex].y -
          bottomEl.current.getBoundingClientRect().top -
          14,
        behavior: 'smooth',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentComment.timestamp]);
  useEffect(() => {
    if (
      bottomEl.current &&
      !currentComment.isBeingModify &&
      currentComment.commentIndex !== -1 &&
      commentPositionList.length > currentComment.commentIndex
    ) {
      bottomEl.current?.scrollTo({
        top:
          commentPositionList[currentComment.commentIndex].y -
          bottomEl.current.getBoundingClientRect().top -
          14,
        behavior: 'smooth',
      });
    }

    setShowMessageToolTip(false);
    if (showCommentsTab) {
      setOpenTab(1);
      setCurrentComment((old) => ({
        ...old,
      }));

      setResolveHidden((old) => ({
        index: -1,
        isResolvedHidden: true,
      }));
      setToggle(true);
    }
  }, [showCommentsTab]);
  useEffect(() => {
    let checkFilterComment = captureComment.filter((item) => {
      return item.isTemp === false || !item.isTemp;
    });
    if (checkFilterComment.length < 0) {
      // if (checkFilterComment.length === 0 || checkFilterComment[0].isTemp) {
      //   setTotalResolveComment(0);
      //   setTotalUnResolveComment(0);
      // } else {
      //   if (checkFilterComment[0].isResolved) {
      //     setTotalResolveComment(1);
      //     setTotalUnResolveComment(0);
      //   } else {
      //     setTotalUnResolveComment(1);
      //     setTotalResolveComment(0);
      //   }
      // }
    } else {
      let totaltResolveComments = 0;
      let totalUnResolveComments = 0;
      for (let index = 0; index < checkFilterComment.length; index++) {
        if (checkFilterComment[index].isResolved) {
          totaltResolveComments = totaltResolveComments + 1;
        } else {
          totalUnResolveComments = totalUnResolveComments + 1;
        }
      }
      setTotalResolveComment(totaltResolveComments);
      setTotalUnResolveComment(totalUnResolveComments);
    }
    // console.log(`Toggle${toggle}`);
  }, [openTab, toggle, captureComment]);

  const closeComments = () => {
    setCloseComments({
      ...responsiveDesignState,
      responsive: true,
      comments: false,
      sidebar: false,
      welcome: true,
    });
    setShowCommentTab(false);

    setShowEmojiPicker({
      show: false,
      positioning: {
        targetElementRect: {
          height: 0,
          width: 0,
          x: 0,
          y: 0,
        } as DOMRect,
        offset: {
          x: 0,
          y: 0,
        },
      },
      handleAddIcon: () => { },
      handleHide: () => { },
    });
  };

  return (
    <>
      {comments || showCommentsTab ? (
        <div className="absolute top-3 right-6 sm:block sm:mt-0 sm:w-full sm:mr-0 ">
          <div className="flex flex-wrap">
            <div
              className={`w-full  ${openTab === 1 ? 'default-tab ' : 'annotaion-bg'
                }  sm:mx-auto sm:w-full`}
            >
              <ul
                className="flex relative  list-none flex-wrap pt-3 flex-row sm:hidden"
                role="tablist"
              >
                <li
                  key="index0"
                  className="-mb-px last:mr-0 flex-auto text-center"
                >
                  <span
                    className={`text-xs font-bold uppercase  rounded block leading-normal
                      ${openTab === 1
                        ? `text-white  tabs-heading`
                        : `text tabs-heading-not-active `
                      }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(1);
                      setCurrentComment((old) => ({
                        ...old,
                      }));

                      setResolveHidden((old) => ({
                        index: -1,
                        isResolvedHidden: true,
                      }));
                      setToggle(true);
                    }}
                    data-toggle="tab"
                    role="tablist"
                  >
                    <div className="flex justify-between items-center">
                      <div className="content flex mx-auto">
                        <h5 className="cursor-pointer font-bold text-gray-500 text-sm">
                          Unresolved
                        </h5>
                        <div className="item-body px-1 py-1">
                        </div>
                      </div>
                      <span className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"></span>
                    </div>
                  </span>
                </li>
                <li
                  key="index1"
                  className="-mb-px last:mr-0 flex-auto text-center"
                // style={{ visibility: 'hidden' }}
                >
                  <span
                    className={`text-xs font-bold uppercase  rounded block leading-normal
                      ${openTab === 2
                        ? `text-white  tabs-heading`
                        : `text-#000000de tabs-heading-not-active`
                      }`}
                    onClick={(e) => {
                      e.preventDefault();

                      if (isLoomRecording) return;

                      if (isVideoUploaded) {
                        setCloseWithVideo({
                          show: true,
                          handleAgreement: () => {
                            setOpenTab(2);
                            setCurrentComment((old) => ({
                              ...old,
                            }));

                            setResolveHidden((old) => ({
                              index: -1,
                              isResolvedHidden: false,
                            }));
                            setToggle(false);
                          },
                        });
                      } else {
                        setOpenTab(2);
                        setCurrentComment((old) => ({
                          ...old,
                        }));

                        setResolveHidden((old) => ({
                          index: -1,
                          isResolvedHidden: false,
                        }));
                        setToggle(false);
                      }
                    }}
                    data-toggle="tab"
                    role="tablist"
                  >
                    <div className="flex justify-between items-center">
                      <div className="content flex mx-auto">
                        <h5 className="cursor-pointer font-bold text-gray-500 text-sm annotationTxt">
                          Resolved
                        </h5>
                        <div className="item-body px-1 py-3"></div>
                      </div>
                      <a className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"></a>
                    </div>
                  </span>
                </li>
                <li key="index2" className=" ">
                  <div
                    className="comment-Arrow-position cursor-pointer"
                    // style={!isSidebarShow ? { right: '1.1rem' } : {}}
                    id="arrow-comment-capture"
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();

                      if (isLoomRecording) return;

                      console.log(isVideoUploaded, 'isVideoUploaded');

                      setCloseComments({
                        ...responsiveDesignState,
                        responsive: false,
                        comments: false,
                        sidebar: false,
                        welcome: true,
                      });

                      if (isVideoUploaded) {
                        setCloseWithVideo({
                          show: true,
                          handleAgreement: () => {
                            setShowCommentTab(!showCommentsTab);
                          },
                        });
                      } else {
                        setShowCommentTab(!showCommentsTab);
                      }
                    }}
                  >
                    <ArrowForward />
                  </div>
                </li>
              </ul>
              <div className="right-card-outer pr-2 sm:h-screen sm:border-none sm:relative">
                <div className="hidden w-full sm:flex sm:justify-between sm:items-center sm:px-4 sm:py-4">
                  <div className="flex justify-between flex-1 border-2 sm:rounded-2xl font-poppins border-black font-semibold r-tex-grey overflow-hidden">
                    <div
                      ref={unResloved}
                      onClick={(e) => {
                        resloved.current?.classList.remove('r-tab-active');
                        unResloved.current?.classList.add('r-tab-active');

                        e.preventDefault();
                        setOpenTab(1);
                        setCurrentComment((old) => ({
                          ...old,
                        }));

                        setResolveHidden((old) => ({
                          index: -1,
                          isResolvedHidden: true,
                        }));
                        setToggle(true);
                        // );
                      }}
                      className="px-3.5	py-2.5 w-1/2 rounded-tr-2xl rounded-br-2xl border-r-1  text-center border-transparent r-tab-active cursor-pointer"
                    >
                      <h5>Unresolved</h5>
                    </div>
                    <div
                      ref={resloved}
                      onClick={(e) => {
                        unResloved.current?.classList.remove('r-tab-active');
                        resloved.current?.classList.add('r-tab-active');

                        e.preventDefault();
                        setOpenTab(2);
                        setCurrentComment((old) => ({
                          ...old,
                        }));

                        setResolveHidden((old) => ({
                          index: -1,
                          isResolvedHidden: false,
                        }));
                        setToggle(false);
                      }}
                      className="px-3.5	py-2.5 w-1/2 rounded-tl-2xl rounded-bl-2xl border-l-1 border-transparent text-center cursor-pointer"
                    >
                      <h5>Resolved</h5>
                    </div>
                  </div>
                  <span
                    className="hidden sm:block pl-4"
                    onClick={closeComments}
                  >
                    <img src="/Close.svg" />
                  </span>
                </div>
                <div
                  className={`flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded bg-default-card ${!isResolveHidden.isResolvedHidden
                    ? ' margin-min-comment-bar '
                    : !canCommentUser
                      ? ' margin-min-comment-bar '
                      : ''
                    }`}
                >
                  <div className="   flex-auto ">
                    <div className="tab-content tab-space w-full">
                      <div>
                        <div
                          className="flex mx-3 h-8 mb-2 "
                          style={{ display: 'none' }}
                        >
                          <div className="flex-none w-12">
                            <div
                              className={` md:w-9 md:h-4 w-9 h-4 flex items-center bg-gray-300 rounded-full mb-3 cursor-pointer custom-radio-body mt-1 ml-3 ${!toggle ? ' custom-black-radio ' : null
                                }`}
                              style={{ display: 'none' }}
                              onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setCurrentComment((old) => ({
                                  ...old,
                                }));

                                setResolveHidden((old) => ({
                                  index: -1,
                                  isResolvedHidden: !toggle,
                                }));
                                setToggle(!toggle);
                              }}
                            >
                              <div
                                className={
                                  'bg-white md:w-5  md:h-5 h-5 w-5 rounded-full shadow-md transform  ' +
                                  (toggle ? ' custom-radio' : toggleClass)
                                }
                                style={{ display: 'none' }}
                              ></div>
                            </div>
                          </div>
                          <div
                            className="flex-auto w-48"
                            style={{ display: 'none' }}
                          >
                            <p className="switch-txt ml-5">
                              Show{' '}
                              {isResolveHidden.isResolvedHidden
                                ? 'resolved'
                                : 'unresolved'}{' '}
                              comments
                              {/* Show resolved comments */}
                            </p>
                          </div>
                        </div>
                        {(toggle && totalUnResolveComment === 0) ||
                          (!toggle && totalResolveComment === 0) ? (
                          <div className="flex">
                            <div className="mx-auto empty-annotation-txt pt-6">
                              <EmptyComments />
                            </div>
                          </div>
                        ) : null}
                        {toggle ? (
                          totalUnResolveComment === 0 ? (
                            <div className="flex">
                              <div className="mx-auto empty-annotation-txt pb-8 pt-5">
                                {totalResolveComment === 0 &&
                                  totalUnResolveComment === 0
                                  ? 'There are no comments yet'
                                  : 'There are no unresolved comments yet'}
                              </div>
                            </div>
                          ) : null
                        ) : totalResolveComment === 0 ? (
                          <div className="flex">
                            <div className="mx-auto empty-annotation-txt pb-8 pt-5">
                              There are no Resolved comments yet
                            </div>
                          </div>
                        ) : null}

                        <div
                          ref={bottomEl}
                          className={
                            openTab === 1
                              ? 'block right-card'
                              : 'block right-card'
                          }
                          id="link1"
                        >
                          <EmojiPicker
                            show={showEmojiPicker.show}
                            addIconHandler={showEmojiPicker.handleAddIcon}
                            onHide={showEmojiPicker.handleHide}
                            positioning={{
                              targetElementRect:
                                showEmojiPicker.positioning.targetElementRect,
                              offset: showEmojiPicker.positioning.offset,
                            }}
                          />

                          {showCommentsTab &&
                            captureComment.map(({ index, ...rest }) =>
                              !isResolveHidden.isResolvedHidden
                                ? captureComment[index].isResolved && (
                                  <CaptureCommentBox
                                    key={`Capture comment box resolved ${index}`}
                                    index={index}
                                    {...rest}
                                  />
                                )
                                : // captureComment[index].isResolved ? (
                                !captureComment[index].isResolved && (
                                  <CaptureCommentBox
                                    key={`Capture comment box unresolved ${index}`}
                                    index={index}
                                    {...rest}
                                  />
                                )
                            )}
                        </div>
                      </div>

                      {isResolveHidden.isResolvedHidden ? (
                        !canCommentUser ? (
                          <div className="bottom-card-Text">
                            <div
                              className={`not-allowed-comments ${currentUserInfo.isAnonymous
                                ? 'anonymous-user '
                                : 'logged-in-user'
                                }`}
                            >
                              <span>Anonymous Commenting is disabled, </span>
                              {currentUserInfo.isAnonymous ? (
                                <span>
                                  <br /> please sign in to comment
                                </span>
                              ) : (
                                <span>
                                  Please Request Access from capture owner{' '}
                                  <br />{' '}
                                  <p className="comment-text-count ml-2 mb-1">
                                    ( coming soon ){' '}
                                  </p>
                                </span>
                              )}
                            </div>
                          </div>
                        ) : (
                          <CaptureCommentTextarea isNew={true} />
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden">
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
            {/* </div> */}
            {/* <CaptureComment /> */}
          </div>
          {/* <div>
        <input
          type="checkbox"
          id="show-replies"
          name="show-replies"
          value="show-replies"
          onChange={hideResolve}
        />
        Show Unresolves
      </div> */}
          {/* <Tabs/> */}
        </div>
      ) : (
        <div
          className="ml-auto absolute top-3 right-6 sm:ml-auto sm:hidden"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setShowCommentTab(!showCommentsTab);
          }}
        >
          <div className="min-comment-bar custom-margin sm:mr-0">
            <div className="text-center mt-3 mb-2">
              <ArrowBackword />
            </div>
            <div className="right-card">
              {captureComment.map(
                ({ index, ...rest }, key) =>
                  !captureComment[index].isResolved && (
                    <div key={"captureComment-" + key}>
                      {' '}
                      <div
                        className="mt-3 relative"
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();

                          setQs('comment', index + 1);
                          setCaptureBoardScrollY(markerPositionList[index].y);
                          setResolveHidden((old) => ({
                            index: -1,
                            isResolvedHidden: !false,
                          }));
                          setToggle(true);
                          setOpenTab(1);
                          setShowCommentTab(!showCommentsTab);
                        }}
                      >
                        <div className="flex flex-col items-center justify-center py-2 bg-white border-1 border-black rounded-xl w-11 min-h-11">
                          <UserAvatar
                            uid={rest.creator.uid}
                            customClass="m-auto"
                            height={26}
                            width={26}
                          />
                          {captureComment[index].replies?.map(({ ...rest }, index) => {
                            return (
                              <UserAvatar
                                key={"avatar-user-" + index}
                                uid={rest.creator.uid}
                                customClass=" my-1 m-auto"
                                height={16}
                                width={16}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )
              )}
              {!canCommentUser ? (
                <div>
                  <div className="mt-3 relative">
                    <Box fillColor="#abff57"></Box>
                    <div
                      onMouseEnter={() => {
                        setShowMessageToolTip(true);
                      }}
                      onMouseLeave={() => {
                        setShowMessageToolTip(false);
                      }}
                    >
                      <HelpIcon
                        width={26}
                        height={26}
                        customClass={'help-icon-mini'}
                      ></HelpIcon>
                    </div>

                    {/* <div className="">
                    <div
                      className={
                        ``}
                    >
                      <span>Anonymous Commenting is disabled, </span>
                      {currentUserInfo.isAnonymous ? (
                        <span ><br /> please sign in to comment</span>
                      ) : (
                        <span>
                          Please Request Access from capture owner <br /> <p className='comment-text-count ml-2 mb-1'>( coming soon ) </p>
                        </span>
                      )}
                    </div>
                  </div> */}
                  </div>
                  <div
                    className="absolute tooltip-box-commentMessage border-2 border-white absolute whitespace-nowrap shadow-2xl font-normal tracking-wider text-white bg-black bg-opacity-70 px-2 py-1 text-xxxxs rounded-tl-md rounded-tr-2xl rounded-br-2xl rounded-bl-2xl absolute"
                    style={{ display: showMessageToolTip ? '' : 'none' }}
                  >
                    {showMessageToolTip ? (
                      <>
                        {' '}
                        <span>Anonymous Commenting is disabled, </span>
                        {currentUserInfo.isAnonymous ? (
                          <span>
                            <br /> please sign in to comment
                          </span>
                        ) : (
                          <span>
                            <br />
                            Please Request Access from capture owner <br />{' '}
                            <p className="comment-text-count-toolTip">
                              ( coming soon ){' '}
                            </p>
                          </span>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CaptureComment;
