import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';

import { codeSnippets, fonts } from 'helpers/options';
import {
  setAutoDetectLanguage,
  setCode,
  setDarkMode,
  setFontSize,
  setFontStyle,
  setLanguage,
  setTitle,
} from 'store/slice/codeEditor.slice';

export default function CodeBlock() {
  const {
    title,
    code,
    language,
    darkMode,
    autoDetectLanguage,
    fontStyle,
    fontSize,
    threeD,
  } = useSelector((state: RootState) => state.code);
  const dispatch = useDispatch();
  useEffect(() => {
    const randomSnippet =
      codeSnippets[Math.floor(Math.random() * codeSnippets.length)];
    setTitle('');
    dispatch(setCode(randomSnippet.code));
  }, []);

  // useEffect(() => {
  //   if (autoDetectLanguage) {
  //     const { language: detectedLanguage } = flourite(code, {
  //       noUnknown: true,
  //     });
  //     dispatch(setLanguage(detectedLanguage.toLowerCase() || 'plaintext'));
  //   }
  // }, [autoDetectLanguage, code]);

  return (
    <div
      style={{
        width: '100%',
        border: '2px solid',
        borderRadius: '1rem',
        boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
        backgroundColor: darkMode
          ? 'rgba(0, 0, 0, 0.75)'
          : 'rgba(255, 255, 255, 0.75)',
        borderColor: darkMode
          ? 'rgba(107, 114, 128, 0.4)'
          : 'rgba(229, 231, 235, 0.2)',
        color: darkMode ? 'white' : 'rgba(0, 0, 0, 0.75)',
        transform: `perspective(${threeD.perspective}px) rotateX(${threeD.rotateX}deg) rotateY(${threeD.rotateY}deg) rotateZ(${threeD.rotateZ}deg)`,
        transition: 'all 0.5s ease-out 0s',
      }}
    >
      <header className="grid grid-cols-6 gap-3 items-center px-4 py-3">
        <div className="flex gap-1.5">
          <div className="rounded-full h-3 w-3 bg-red-500" />
          <div className="rounded-full h-3 w-3 bg-yellow-500" />
          <div className="rounded-full h-3 w-3 bg-green" />
        </div>
        <div className="col-span-4 flex justify-center">
          <input
            type="text"
            value={title}
            placeholder="Untitled"
            onChange={(e) => dispatch(setTitle(e.target.value))}
            spellCheck={false}
            onClick={(e: any) => e.target.select()}
            className={`bg-transparent text-center ${
              darkMode ? 'text-white' : 'text-black/75'
            } text-sm font-medium focus:outline-none`}
          />
        </div>
      </header>
      <div
        className={`px-4 pb-4 ${
          darkMode
            ? 'brightness-110'
            : 'text-gray-800 brightness-50 saturate-200 contrast-200'
        }`}
      >
        <Editor
          value={code}
          onValueChange={(newCode) => dispatch(setCode(newCode))}
          padding={10}
          highlight={(code) =>
            hljs.highlight(code, { language: language || 'javascript' }).value
          }
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: fontSize,
          }}
          textareaClassName="focus:outline-none"
          onClick={(e: any) => e.target.select()}
        />
      </div>
    </div>
  );
}
