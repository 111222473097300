import youtube from '../assets/socials/youtube.png';
import facebook from '../assets/socials/facebook.png';
import instagram from '../assets/socials/instagram.png';
import twitter from '../assets/socials/twitter.png';
import linkedin from '../assets/socials/linkedin.png';
import pinterest from '../assets/socials/pinterest.png';
export const gridOverlayImage = `url(
          "data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='30' height='30' patternTransform='scale(2) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0, 0%, 100%, 0)'/><path d='M0 22.5h30v15H0zm15-15h30v15H15m-30-15h30v15h-30zm15-15h30v15H0z'  stroke-width='1' stroke='hsla(259, 100%, 100%, 0.03)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>"
        )`;

export const ColorPalettes = [
  [
    { id: 1, color: '#F56565' },
    { id: 2, color: '#F9A825' },
  ],
  [
    { id: 3, color: '#F66681' },
    { id: 4, color: '#F56565' },
  ],
  [
    { id: 5, color: '#F472B6' },
    { id: 6, color: '#F66681' },
  ],
  [
    { id: 7, color: '#FDE68A' },
    { id: 8, color: '#F0E68C' },
  ],
  [
    { id: 9, color: '#FDE68A' },
    { id: 10, color: '#F9A825' },
  ],
  [
    { id: 11, color: '#F9A825' },
    { id: 12, color: '#F472B6' },
  ],
  [
    { id: 13, color: '#C084FC' },
    { id: 14, color: '#F783AC' },
  ],
  [
    { id: 15, color: '#FD7D24' },
    { id: 16, color: '#D977F9' },
  ],
  [
    { id: 17, color: '#A3E635' },
    { id: 18, color: '#6EE7B7' },
  ],
  [
    { id: 19, color: '#4ADE80' },
    { id: 20, color: '#FDE68A' },
  ],
  [
    { id: 21, color: '#63B3ED' },
    { id: 22, color: '#ABEBC6' },
  ],
  [
    { id: 23, color: '#63B3ED' },
    { id: 24, color: '#6DD7FF' },
  ],
  [
    { id: 25, color: '#6366F1' },
    { id: 26, color: '#6DD7FF' },
  ],
  [
    { id: 27, color: '#4399ED' },
    { id: 28, color: '#9662EA' },
  ],
  [
    { id: 29, color: '#C26BE6' },
    { id: 30, color: '#63B3ED' },
  ],
  [
    { id: 31, color: '#FF6347' }, // New color
    { id: 32, color: '#FFA500' }, // New color
  ],
  [
    { id: 33, color: '#FFD700' }, // New color
    { id: 34, color: '#32CD32' }, // New color
  ],
  [
    { id: 35, color: '#8A2BE2' }, // New color
    { id: 36, color: '#00FFFF' }, // New color
  ],

  [
    { id: 37, color: '#C26BE6' },
    { id: 38, color: '#F472B6' },
  ],
  [
    { id: 39, color: '#9662EA' },
    { id: 40, color: '#B040BC' },
  ],
  [
    { id: 41, color: '#9662EA' },
    { id: 42, color: '#6366F1' },
  ],
  [
    { id: 43, color: '#B040BC' },
    { id: 44, color: '#6B46C1' },
  ],
  [
    { id: 45, color: '#3B82F6' },
    { id: 46, color: '#545D6E' },
  ],
  [
    { id: 47, color: '#22C55E' },
    { id: 48, color: '#4399ED' },
  ],
  [
    { id: 49, color: '#869AAC' },
    { id: 50, color: '#6B46C1' },
  ],
  [
    { id: 51, color: '#F9A825' },
    { id: 52, color: '#EC4899' },
  ],
  [
    { id: 53, color: '#FFD3B6' },
    { id: 54, color: '#B8A3E9' },
  ],
  [
    { id: 55, color: '#78E4E4' },
    { id: 56, color: '#A9DEF9' },
  ],
];

export const FlatColors = [
  '#FF5733',
  '#33FF57',
  '#5733FF',
  '#FF33A1',
  '#A1FF33',
  '#33A1FF',
  '#FF3366',
  '#6633FF',
  '#33FFA1',
  '#FFA133',
  '#A133FF',
  '#33FFA1',
  '#FF33A1',
  '#A1FF33',
  '#33A1FF',
  '#FF5733',
  '#33FF57',
  '#5733FF',
  '#FF33A1',
  '#A1FF33',
  '#FF33CC',
  '#CCFF33',
  '#33CCFF',
  '#FF6633',
  '#33FFCC',
  '#CC33FF',
  '#FF9933',
  '#33FF99',
  '#9933FF',
  '#FF3366',
  '#66FF33',
  '#3366FF',
  '#FF3366',
  '#6633FF',
  '#33FF99',
  '#FFCC33',
  '#33FF66',
  '#CCFF33',
  '#FF3366',
  '#FF33CC',
];
export const Images = [
  'https://images.unsplash.com/photo-1618556450994-a6a128ef0d9d?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1620121692029-d088224ddc74?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1639493115941-a70fcef4f715?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1579547621700-03c2c337370a?q=80&w=2139&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1523821741446-edb2b68bb7a0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1579546929518-9e396f3cc809?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1707742984673-ae30d982bdec?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1707742809711-ebcc97af4faf?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1614850523459-c2f4c699c52e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1614851099511-773084f6911d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1614854262178-03c96e9c8c28?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1579546929518-9e396f3cc809?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1614849286319-2e0b4fc53b93?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1579546929662-711aa81148cf?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1557683311-eac922347aa1?q=80&w=2029&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1524785281156-c3c68d1e03c5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1655998233171-ee5b130acba5?q=80&w=1998&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1620121684840-edffcfc4b878?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1620120966883-d977b57a96ec?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1635614017406-7c192d832072?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1635431289101-ef85326518f2?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1614850523306-9a6189f919dd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  '',
];

export const TABS = {
  IMAGE: 'IMAGE',
  BACKGROUND: 'BACKGROUND',
};
export const transformOriginArray = [
  'top left',
  'top center',
  'top right',
  'center left',
  'center center',
  'center right',
  'bottom left',
  'bottom center',
  'bottom right',
];

export const socialMediaPostSizes = [
  // YouTube
  {
    id: 'youtube-thumbnail',
    platform: 'YouTube',
    title: 'Video Thumbnail',
    width: 1280,
    height: 720,
    icon: youtube,
  },
  // Facebook
  {
    id: 'fb-news-Feed',
    platform: 'Facebook',
    title: 'News feed',
    width: 1200,
    height: 1200,
    icon: facebook,
  },
  {
    id: 'Stories',
    platform: 'Facebook',
    title: 'Stories',
    width: 1080,
    height: 1920,
    icon: facebook,
  },
  {
    id: 'Fb-Cover-Photo',
    platform: 'Facebook',
    title: 'Cover Photo',
    width: 1660,
    height: 624,
    icon: facebook,
  },
  {
    id: 'Fb-event-Photo',
    platform: 'Facebook',
    title: 'Event Photo',
    width: 2672,
    height: 1400,
    icon: facebook,
  },
  {
    id: 'Fb-group-Photo',
    platform: 'Facebook',
    title: 'Group Photo',
    width: 1280,
    height: 668,
    icon: facebook,
  },
  {
    id: 'Fb-event-Photo',
    platform: 'Facebook',
    title: 'Open Graph',
    width: 2400,
    height: 1260,
    icon: facebook,
  },
  // Instagram
  {
    id: 'instagram-post-squre',
    platform: 'Instagram',
    title: 'Square Post',
    width: 1080,
    height: 1080,
    icon: instagram,
  },
  {
    id: 'Instagram-Potrait-Post',
    platform: 'Instagram',
    title: 'Potrait Post',
    width: 1080,
    height: 1350,
    icon: instagram,
  },
  {
    id: 'Instagram-Stories',
    platform: 'Instagram',
    title: 'Stories',
    width: 1080,
    height: 1920,
    icon: instagram,
  },
  // Twitter
  {
    id: 'Twitter Profile Photo',
    platform: 'Twitter',
    title: 'Profile photo',
    width: 400,
    height: 400,
    icon: twitter,
  },
  {
    id: 'Twitter Landscape',
    platform: 'Twitter',
    title: 'Landscape',
    width: 1600,
    height: 900,
    icon: twitter,
  },
  {
    id: 'Twitter Portrait',
    platform: 'Twitter',
    title: 'Portrait',
    width: 1080,
    height: 1350,
    icon: twitter,
  },
  {
    id: 'Twitter Square',
    platform: 'Twitter',
    title: 'Square',
    width: 1080,
    height: 1080,
    icon: twitter,
  },
  {
    id: 'Twitter Cover Photo',
    platform: 'Twitter',
    title: 'Cover photo',
    width: 1500,
    height: 1500,
    icon: twitter,
  },
  // LinkedIn
  {
    id: 'LinkedIn Profile Photo',
    platform: 'LinkedIn',
    title: 'Profile photo',
    width: 400,
    height: 400,
    icon: linkedin,
  },
  {
    id: 'LinkedIn Landscape',
    platform: 'LinkedIn',
    title: 'Landscape',
    width: 1200,
    height: 627,
    icon: linkedin,
  },
  {
    id: 'LinkedIn Portrait',
    platform: 'LinkedIn',
    title: 'Portrait',
    width: 627,
    height: 1200,
    icon: linkedin,
  },
  {
    id: 'LinkedIn Square',
    platform: 'LinkedIn',
    title: 'Square',
    width: 1080,
    height: 1080,
    icon: linkedin,
  },
  {
    id: 'LinkedIn Cover Photo',
    platform: 'LinkedIn',
    title: 'Cover photo',
    width: 1128,
    height: 191,
    icon: linkedin,
  },
  // Pinterest
  {
    id: 'Pinterest Pin Display',
    platform: 'Pinterest',
    title: 'Pinterest Pin Display',
    width: 236,
    height: 354,
    icon: pinterest,
  }
];

export const BG_IMAGES = [
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='18' viewBox='0 0 100 18'%3E%3Cpath fill='%239C92AC' fill-opacity='0.4' d='M61.82 18c3.47-1.45 6.86-3.78 11.3-7.34C78 6.76 80.34 5.1 83.87 3.42 88.56 1.16 93.75 0 100 0v6.16C98.76 6.05 97.43 6 96 6c-9.59 0-14.23 2.23-23.13 9.34-1.28 1.03-2.39 1.9-3.4 2.66h-7.65zm-23.64 0H22.52c-1-.76-2.1-1.63-3.4-2.66C11.57 9.3 7.08 6.78 0 6.16V0c6.25 0 11.44 1.16 16.14 3.42 3.53 1.7 5.87 3.35 10.73 7.24 4.45 3.56 7.84 5.9 11.31 7.34zM61.82 0h7.66a39.57 39.57 0 0 1-7.34 4.58C57.44 6.84 52.25 8 46 8S34.56 6.84 29.86 4.58A39.57 39.57 0 0 1 22.52 0h15.66C41.65 1.44 45.21 2 50 2c4.8 0 8.35-.56 11.82-2z'%3E%3C/path%3E%3C/svg%3E",
];

export const imageShadowPresets = [
  {
    id: 0,
    level: 0,
    value: '0 0px 0px rgba(0, 0, 0, 0), 0 0px 0px rgba(0, 0, 0, 0)',
  },
  {
    id: 1,
    level: 1,
    value: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
  },
  {
    id: 2,
    level: 2,
    value: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
  },
  {
    id: 3,
    level: 3,
    value: '0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)',
  },
  {
    id: 4,
    level: 4,
    value: '0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22)',
  },
  {
    id: 5,
    level: 5,
    value: '0 24px 48px rgba(0, 0, 0, 0.36), 0 20px 15px rgba(0, 0, 0, 0.22)',
  },
  {
    id: 6,
    level: 6,
    value: '0 29px 58px rgba(0, 0, 0, 0.42), 0 25px 20px rgba(0, 0, 0, 0.22)',
  },
  {
    id: 7,
    level: 7,
    value: '0 34px 68px rgba(0, 0, 0, 0.48), 0 30px 25px rgba(0, 0, 0, 0.22)',
  },
  {
    id: 8,
    level: 8,
    value: '0 40px 80px rgba(0, 0, 0, 0.54), 0 35px 30px rgba(0, 0, 0, 0.22)',
  },
  {
    id: 9,
    level: 9,
    value: '0 45px 90px rgba(0, 0, 0, 0.60), 0 40px 35px rgba(0, 0, 0, 0.22)',
  },
  {
    id: 10,
    level: 10,
    value: '0 50px 100px rgba(0, 0, 0, 0.66), 0 45px 40px rgba(0, 0, 0, 0.22)',
  },
  {
    id: 11,
    level: 11,
    value: '0 55px 110px rgba(0, 0, 0, 0.72), 0 50px 45px rgba(0, 0, 0, 0.22)',
  },
  {
    id: 12,
    level: 12,
    value: '0 60px 120px rgba(0, 0, 0, 0.78), 0 55px 50px rgba(0, 0, 0, 0.22)',
  },
  {
    id: 13,
    level: 13,
    value: '0 65px 130px rgba(0, 0, 0, 0.84), 0 60px 55px rgba(0, 0, 0, 0.22)',
  },
];

export const imageEditorItems = [
  {
    id: 3,
    path: '/elements/arrow.svg',
  },

  {
    id: 6,
    path: '/elements/circle.svg',
  },
  {
    id: 7,
    path: '/elements/curved-arrow-share-svgrepo-com.svg',
  },
  {
    id: 13,
    path: '/elements/square.svg',
  },
];
export const alignImages = [
  {
    // Place top left
    translateX: -25,
    translateY: -25,
  },
  {
    // Place center top
    translateX: 0,
    translateY: -25,
  },
  {
    // Place top right
    translateX: 25,
    translateY: -25,
  },

  {
    // Place center left
    translateX: -25,
    translateY: 0,
  },
  {
    // Place center
    translateX: 0,
    translateY: 0,
  },
  {
    // Place center right
    translateX: 25,
    translateY: 0,
  },
  {
    // Place bottom left
    translateX: -25,
    translateY: 25,
  },
  {
    // Place bottom center
    translateX: 0,
    translateY: 25,
  },

  {
    // Place bottom right
    translateX: 25,
    translateY: 25,
  },
];
export const fontFamilyOptions = [
  'Arial',
  'Helvetica',
  'Roboto',
  'Courier New',
  'Times New Roman',
  'Georgia',
  'Garamond',
  'Comic Sans MS',
];
export const fontWeights = ['normal', 'bold', 'bolder', 'lighter'];
export const fontStyles = ['normal', 'italic', 'oblique'];
export const textDecorations = [
  'none',
  'underline',
  'overline',
  'line-through',
];
export const products = [
  {
    thumbnail: '/demos/1.png',
  },
  {
    thumbnail: '/demos/2.png',
  },
  {
    thumbnail: '/demos/3.png',
  },

  {
    thumbnail: '/demos/4.png',
  },
  {
    thumbnail: '/demos/5.png',
  },
  {
    thumbnail: '/demos/6.png',
  },
  {
    thumbnail: '/demos/7.png',
  },
  {
    thumbnail: '/demos/8.png',
  },
  {
    thumbnail: '/demos/9.png',
  },
  {
    thumbnail: '/demos/10.png',
  },
];
export const FramesID = {
  GLASS_FRAME: 1,
  DARK_GLASS_FRAME: 2,
  MAC_FRAME: 3,
  DARK_MAC_FRAME: 4,
  WINDOWS_FRAME: 5,
  DARK_WINDOWS_FRAME: 6,
  FANCY_FRAME: 7,
};

export const codePadding = [10, 40, 80, 120];
