import clsx from 'clsx';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { isAppLoadingState } from '@/state/appState';
import {
  currentCaptureInfoState,
  currentUserRoleForCaptureState,
  groupedByHostCaptureListSharedState,
} from '@/state/captureState';
import { responsiveDesignState } from '@/state/responsiveState';
import {
  isSharedWithMeReadyState,
  showMyProjectState,
  sidebarState,
} from '@/state/uiState';
import { currentUserInfoState, isAnonymousState } from '@/state/userState';

import { useAuth } from '@/hooks/useAuth';
import { User } from 'firebase/auth';

import { CaptureList } from '@/components';
import Loading from '../Loading';
import Header from './Header';
import SignInOut from './SignInOut';

import LogoCollapse from '../../assets/images/new-sidebar-logo-close.svg';
import ArrowDown from '../icon/ArrowDown';
import ArrowUpShare from '../icon/ArrowUpShare';
import AddCapture from './AddCapture';
import ProjectsDropdown from './ProjectsDropdown';
import Logo from '../../assets/images/newlogo-sidebar.svg';
import { useClerkAuth } from '@/hooks/useClerkAuth';
import { useOrganization } from '@clerk/clerk-react';

const Sidebar = forwardRef<HTMLDivElement>((props, sidebarRef) => {
  const navigate = useNavigate();
  const { linkToGoogle } = useAuth();
  useClerkAuth();

  const [showMyProject, setShowMyProjects] = useRecoilState(showMyProjectState);

  const isSidebarShow = useRecoilValue(sidebarState);
  const sidebarValue = useRecoilValue(responsiveDesignState);
  const isAnonymous = useRecoilValue(isAnonymousState);
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const appLoading = useRecoilValue(isAppLoadingState);
  const currentRole = useRecoilValue(currentUserRoleForCaptureState);
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const sharedWithMeIsReady = useRecoilValue(isSharedWithMeReadyState);
  const groupedByHostCaptureListShared = useRecoilValue(
    groupedByHostCaptureListSharedState
  );

  const ref = useRef<HTMLDivElement | null>(null);

  const [isDropdownShow, setIsDropdownShow] = useState(false);
  const [isSideBarLoading, setSideBarLoading] = useState(false);
  const [showTool, setShowTool] = useState<boolean>(false);
  const { organization } = useOrganization();

  const displayStyle = clsx({
    flex: isSidebarShow,
  });

  const handleClick = () => {
    linkToGoogle();
  };

  useEffect(() => {
    setShowMyProjects(true);
  }, []);

  const toggleShowTools = () => {
    setShowTool((prevState) => !prevState);
  };

  const handleProjectsDropdown = () => {
    if (currentUserInfo.isAnonymous) return false;
    setIsDropdownShow((isDropdownShow) => !isDropdownShow);
  };

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);

    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownShow]);

  const clickOutside = (e: Event) => {
    if (ref.current && ref?.current.contains(e.target as Node)) return false;
    if (isDropdownShow) return handleProjectsDropdown();
  };

  useEffect(() => {
    if (
      currentRole.Role === 'ADMIN' ||
      currentRole.Role === 'SUPERADMIN' ||
      currentRole.Role === 'ILLEGAL' ||
      currentCaptureInfo === null ||
      currentUserInfo.isAnonymous
    ) {
      setShowMyProjects(true);
    } else {
      setShowMyProjects(false);
    }
  }, [groupedByHostCaptureListShared]);

  useEffect(() => {
    setIsDropdownShow(false);
    if (currentUserInfo.isAnonymous) {
      setSideBarLoading(false);
      return;
    }

    if (!sharedWithMeIsReady) {
      setSideBarLoading(true);
    } else {
      setSideBarLoading(false);
    }
  }, [showMyProject, sharedWithMeIsReady, currentUserInfo.isAnonymous]);

  return (
    <div
      ref={sidebarRef}
      className={`${displayStyle}  ${isSidebarShow ? 'sidebar' : 'sidebar-collapse'
        } flex relative flex-col justify-between bg-white shadow-md sm:w-full sm:max-w-none sm:overflow-hidden sm:pr-3 sm:pl-0 sm:pb-3 sm:block ${sidebarValue.sidebar === false && 'sm:hidden'
        }`}
    >
      <div
        className={`  ${isSidebarShow ? ' ml-5 pb-2	' : ' left-0 p-5 pb-2'} `}
      >
        <a href="/">
          {!isSidebarShow ? (
            <img src={LogoCollapse} alt="instacap-logo" />
          ) : (
            <img src={Logo} alt="instacap-logo" />
          )}
        </a>
      </div>

      <div className="mb-4 px-6">
        <hr className="mt-0" />
      </div>

      <div className="flex flex-col h-full overflow-hidden sm:mt-6 sm:mb-0 sm:shadow-none">
        {!appLoading && (
          <div ref={ref} tabIndex={0}>
            {isSidebarShow && !isSideBarLoading && (
              <div className="ml-6 mb-4">
                {!isAnonymous && (
                  <div
                    className="flex row text-xs font-poppins text-gray-500 pl-3.5 mb-0.5 cursor-pointer w-fit"
                    onClick={() => {
                      navigate('/');
                      window.location.reload();
                      return false;
                    }}
                  >
                    {/* <p
                    className="cursor-pointer"
                    onClick={() => {
                      navigate('/');
                      window.location.reload();
                      return false;
                    }}
                  >
                    Dashboard
                  </p>
                  <p className="mx-1">/</p>
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      navigate('/');
                      window.location.reload();
                      return false;
                    }}
                  >
                    Website
                  </p> */}
                    {currentCaptureInfo?.publicAccess &&
                      currentCaptureInfo.creator.uid !== currentUserInfo.uid ? 'Shared Projects' : organization ? `Organization projects` : currentCaptureInfo?.group ? 'My Projects' : 'My Dashboard'
                      // currentCaptureInfo.creator.uid !== currentUserInfo.uid ? 'Shared Projects' : (currentCaptureInfo?.group ? 'My Projects' : 'My Dashboard')
                    }
                  </div>
                )}

                <div
                  className={`relative flex flex-row justify-start items-center max-w-56 sm:shadow-none`}
                  tabIndex={0}
                  onClick={handleProjectsDropdown}
                >
                  {!isAnonymous && (
                    <p className="sidebar-Capture-title text-lg font-bold truncate">
                      {currentCaptureInfo?.group
                        ? currentCaptureInfo?.projectName ||
                        'Non Project Captures'
                        : 'Non Project Captures'}
                    </p>
                  )}

                  {!currentUserInfo.isAnonymous && (
                    <span
                      id={`ShareInputViewDropDown`}
                      className={`cursor-pointer ${isSidebarShow && 'ml-1'}`}
                    >
                      {isDropdownShow ? (
                        <ArrowUpShare width={20} height={20} />
                      ) : (
                        <ArrowDown width={20} height={20} />
                      )}
                      <ProjectsDropdown isShow={isDropdownShow} />
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {isSideBarLoading && <Loading bg="bg-transparent" />}

        {!isSideBarLoading && (
          <CaptureList sharedWithMe={!showMyProject}></CaptureList>
        )}

        {!isAnonymous && showMyProject && !isSideBarLoading && (
          <div className="custom-padding custom-margin-top bg-grey sm:hidden">
            <AddCapture
              group={currentCaptureInfo?.group}
              toolTip="Add Capture"
              customClassName={isSidebarShow ? 'upload-file-txt' : ''}
              projectName={currentCaptureInfo?.projectName}
            />
          </div>
        )}

        {/* {isAnonymous && !isSidebarShow && (
          <div className="flex items-center justify-center mx-6	mt-6 mb-3 gsignin-button min-h-10 font-bold tracking-wider uppercase sm:self-center">
            <img
              className="w-5 pb-1 -mr-6"
              src="/image/google-g-icn.png"
              alt="google-go-icon"
            />
            <SignInOut />
          </div>
        )} */}

        {/* {isAnonymous && !isSidebarShow && (
          <p className="privacy-descriphttpshttpstion sm:self-center text-center sm:text-xs	">
            I agree to the{' '}
            <a
              className="pink-link"
              href="https://www.instacap.co/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms
            </a>{' '}
            &{' '}
            <a
              className="pink-link"
              href="https://www.instacap.co/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy
            </a>
          </p>
        )} */}

        {/* {isAnonymous && isSidebarShow && (
          <div className="relative">
            <img
              src="/g-button.svg"
              alt=""
              width={40}
              height={40}
              className="mx-auto mt-3 cursor-pointer"
              onClick={handleClick}
              onMouseEnter={toggleShowTools}
              onMouseLeave={toggleShowTools}
            />
            <div
              className=" google-signin-rounded px-2  border-2 border-white absolute whitespace-nowrap shadow-2xl font-normal tracking-wider text-white bg-black bg-opacity-70  py-1 text-xxxxs rounded-tl-md rounded-tr-2xl rounded-br-2xl rounded-bl-2xl"
              style={{ display: showTool ? '' : 'none' }}
            >
              Sign in
            </div>
          </div>
        )} */}

        <div className="hidden mobile-logo-sidebar sm:block ">
          <Link to={'/'}>
            <img src={LogoCollapse} alt="instacap-logo" />
          </Link>
        </div>
      </div>

      <Header />
    </div>
  );
});

export default Sidebar;
