import { useSetRecoilState, useRecoilCallback } from 'recoil';
import _ from 'lodash';
import {
  addToolObjectState,
  currentToolObjectColorState,
  currentToolObjectIndexState,
  currentToolObjectState,
  currentToolState,
  selectToolObjectState,
  toolObjectListOrderState,
  toolObjectListState,
  updateToolObjectState,
} from '@/state/toolState';

export const useToolObject = () => {
  const updateToolObject = useSetRecoilState(updateToolObjectState);
  const selectToolObject = useSetRecoilState(selectToolObjectState);

  const updateColorOrText = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (type: 'text' | 'color', value: string) => {
        const currentToolObject = {
          ...(await snapshot.getLoadable(currentToolObjectState).contents),
          [type]: value,
        };
        set(updateToolObjectState, currentToolObject);
        if (type === 'color') {
          set(currentToolObjectColorState, value);
        }
        reset(currentToolObjectColorState);
      }
  );

  const updateRectOfToolObject = useRecoilCallback(
    ({ snapshot, set }) =>
      async (rect: Rect.Info) => {
        const currentToolObject = await snapshot.getLoadable(
          currentToolObjectState
        ).contents;
        if (!currentToolObject) return;
        set(updateToolObjectState, { ...currentToolObject, ...rect });
      }
  );

  const addToolObject = useRecoilCallback(
    ({ set }) =>
      async (data: {
        position?: Rect.ClientPosition;
        rect?: Rect.Info;
        stroke?: Canvas.Path;
      }) => {
        {
          set(addToolObjectState, data);
        }
      }
  );

  const setToolState = useRecoilCallback(
    ({ set, reset, snapshot }) =>
      async () => {
        const currentTool = await snapshot.getLoadable(currentToolState)
          .contents;

        if (['MOVE', 'CHAT'].includes(currentTool)) {
          reset(currentToolObjectIndexState);
          reset(currentToolObjectState);
          return;
        }

        if (!['MOVE', 'CHAT'].includes(currentTool)) {
          set(currentToolState, 'MOVE');
        }
      }
  );

  const setToolStateAfterInsert = useRecoilCallback(
    ({ set, reset, snapshot }) =>
      async () => {
        const currentTool = await snapshot.getLoadable(currentToolState)
          .contents;

        if (!['MOVE'].includes(currentTool)) {
          set(currentToolState, 'MOVE');
          reset(currentToolObjectState);
          reset(currentToolObjectIndexState);
        }
      }
  );

  const removeToolObject = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async () => {
        const currentToolObjectIndex = await snapshot.getLoadable(
          currentToolObjectIndexState
        ).contents;
        const toolObjectList = [
          ...(await snapshot.getLoadable(toolObjectListState).contents),
        ];
        if (toolObjectList.length < 1) return;
        reset(currentToolObjectIndexState);
        reset(currentToolObjectState);

        toolObjectList.splice(currentToolObjectIndex, 1);
        set(toolObjectListState, toolObjectList);

        const toolObjectListOrder = [
          ...(await snapshot.getLoadable(toolObjectListOrderState).contents),
        ];
        const orderIndex = _.indexOf(
          toolObjectListOrder,
          currentToolObjectIndex
        );
        toolObjectListOrder.splice(orderIndex, 1);
        set(toolObjectListOrderState, toolObjectListOrder);
      }
  );

  return {
    updateToolObject,
    selectToolObject,
    removeToolObject,
    updateColorOrText,
    updateRectOfToolObject,
    setToolState,
    addToolObject,
    setToolStateAfterInsert,
  };
};
