import { FC, RefObject, useRef } from 'react';
import Draggable, { DraggableEventHandler } from 'react-draggable';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { constSelector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentCommentState,
  DEFAULT_CURRENT_COMMENT,
} from '@/state/commentState';
// import { captureIsPublicState } from '@/state/captureState';
import { hoverMarkerState } from '@/state/markerState';
import { useMarker } from '@/hooks/useMarker';
import Tick from '../icon/Tick';
import { showCommentsTabState } from '@/state/uiState';
import { useQuerystring } from '@/hooks/useQuerystring';
import { captureCommentState } from '@/state/captureState';
import { boundingRectState } from '@/state/boundingBoxState';

let CORRECTION_TOP = -36;
let CORRECTION_LEFT = 7;
let CAMERA_ROTATION_ANGLE
const getCoords = (elem: HTMLElement) => {
  return elem.getBoundingClientRect();
};
type ImgEl = RefObject<HTMLImageElement>;

const CaptureDraggableMarker: FC<{
  index: number;
  markerPos: { x: number; y: number };
  isTemp: boolean;
  ratio: { x: number; y: number };
  isDraggable: boolean;
  isResolved?: boolean;
  isLoomVideoAttached?: boolean
  ImgEl: ImgEl,
  inheritedStyle?: any,
  imgDimensions?: {width: any; height: any}
}> = ({ index, markerPos, isTemp, ratio, isDraggable, isResolved, isLoomVideoAttached, ImgEl, inheritedStyle, imgDimensions }) => {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const [currentComment, setCurrentComment] =
    useRecoilState(currentCommentState);
  const { getQs, setQs, removeQs } = useQuerystring();



  const setHoverMarker = useSetRecoilState(hoverMarkerState);
  // const captureIsPublic = useRecoilValue(captureIsPublicState);
  const { resetTempMarker, updateMarkerPosition } = useMarker();
  // const setShowCommentTab = useSetRecoilState(showCommentsTabState)
  // 'animate-none w-8 h-8 bg-gray-500 bg-opacity-90 text-white border-opacity-80 border-solid border-2 border-white shadow-2xl':

  let style = clsx({
    'animate-none w-6 h-6 bg-white   border-opacity-80 border-solid border-3 border-black ':
      currentComment.commentIndex !== index,
    ' w-6 h-6   z-50  bg-green  text-black  border-opacity-80 border-solid border-3 border-black opacity-100 ':
      currentComment.commentIndex === index,
  });
  let cursorStyle = clsx({
    'rounded-tl-3xl rounded-tr-3xl rounded-br-3xl rounded-bl-lg bubble-1-border ': true,


  })
  if (isLoomVideoAttached) {
    style = clsx({
      'animate-none       default-Camera':
        currentComment.commentIndex !== index,
      '    text-black  green-Camera opacity-100':
        currentComment.commentIndex === index,
    });
  }
  // console.log(`Image Width`, ImgEl.current?.width)
  // console.log(`Image Height `, ImgEl.current?.height)
  if (ImgEl.current) {
    let cordCapture = ImgEl.current.getBoundingClientRect()
    if (markerPos.x + 30 > cordCapture.width) {
      // console.log(`-------Check 1----------------`)
      cursorStyle = clsx({
        'rounded-tl-3xl rounded-tr-lg rounded-br-3xl rounded-bl-3xl bubble-2-border': true,

      })
      CORRECTION_TOP = 15
      CORRECTION_LEFT = -8

    } else {
      CORRECTION_TOP = -25;
      CORRECTION_LEFT = 7;
    }
    if (markerPos.y + 20 > cordCapture.height) {
      // console.log(`-------Check 2----------------`)

      cursorStyle = clsx({
        'rounded-tl-3xl rounded-tr-3xl rounded-br-lg rounded-bl-3xl bubble-3-border': true,


      })
      CORRECTION_TOP = -19
      CORRECTION_LEFT = -11

    }

    if (markerPos.y + 45 > cordCapture.height &&
      markerPos.x - 50 < 0
    ) {
      // console.log(`-------Check 3----------------`)

      cursorStyle = clsx({
        'rounded-tl-3xl rounded-tr-3xl rounded-br-3xl rounded-bl-lg bubble-1-border': true,
        'border-width': `3px 3px 3px 3px`

      })
      CORRECTION_TOP = -18;
      CORRECTION_LEFT = 5;

    }
    else if (markerPos.y - 24 < 0

    ) {
      // console.log(`-------Check 4----------------`)
      if (markerPos.x - 24 < 0) {
        cursorStyle = clsx({
          'rounded-tl-lg rounded-tr-3xl rounded-br-3xl rounded-bl-3xl bubble-4-border': true,
          'border-width': `3px 3px 3px 3px`

        })
        CORRECTION_TOP = 7;
        CORRECTION_LEFT = 7;
      } else {
        cursorStyle = clsx({
          'rounded-tl-3xl rounded-tr-lg rounded-br-3xl rounded-bl-3xl bubble-2-border': true,
          'border-width': `3px 3px 3px 3px`
        })
        CORRECTION_TOP = 7
        CORRECTION_LEFT = -22
      }
    }


  } else {
    CORRECTION_TOP = -25;
    CORRECTION_LEFT = 5.5;

  }

  // if (markerPos.y < 10) {

  //   CORRECTION_TOP = -72
  //   CORRECTION_LEFT = 20
  // }
  if (currentComment.commentIndex === index) {
    // console.log(`Marker Postions Setted`, markerPos)
  }

  // console.log(`==isLoomVideo Attached===:`, isLoomVideoAttached)
  const handleClick = (
    event: MouseEvent | React.MouseEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    !isTemp && resetTempMarker();
    setCurrentComment((old) => ({
      ...DEFAULT_CURRENT_COMMENT,
      commentIndex: index,
      timestamp: dayjs().unix(),
    }));
    setQs('comment', index + 1);
  };

  const handleMouseEnter = (event: any) => {
    if (isResolved) {
      event.target.classList.remove('opacity-30');

    }
    setHoverMarker({ index, timestamp: dayjs().unix() });
  };

  const handleMouseLeave = (event: any) => {
    if (isResolved) {
      event.target.classList.add('opacity-30');

    }
    setHoverMarker({ index: -1, timestamp: dayjs().unix() });
  };

  const onStart: DraggableEventHandler = () => {
    if (nodeRef && nodeRef.current) {
      nodeRef.current.style.cursor = 'grabbing';
      nodeRef.current.style.animation = 'none';
    }
  };

  const onStop: DraggableEventHandler = (_e, data) => {
    if (nodeRef && nodeRef.current) {
      nodeRef.current.style.cursor = 'grab';
      nodeRef.current.style.animation = '';
    }
    if (data.x === 0 && data.y === 0) return;
    const dx = data.x / ratio.x;
    const dy = data.y / ratio.y;
    updateMarkerPosition({ index, isTemp, dx, dy });
  };

  const dragHandlers = {
    onStart,
    onStop,
    onMouseDown: handleClick,
  };

  return (
    <Draggable
      nodeRef={nodeRef}
      position={{ x: 0, y: 0 }}
      disabled={!isDraggable}
      {...dragHandlers}
    >
      <div
        ref={nodeRef}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={!isLoomVideoAttached ? ` ${isResolved ? 'opacity-30 ' : ''}cursor-pointer absolute text-xs flex justify-center items-center   ${style} ${cursorStyle}` : `  ${isResolved ? 'opacity-30 ' : ''} cursor-pointer absolute text-sm flex justify-center items-center ${style} ${isResolved ? `default-Camera ` : ''}`}
        style={{
          top: `${markerPos.y +  (ImgEl.current && imgDimensions?.width > ImgEl.current.width ? 0 : CORRECTION_TOP)}px`,
          left: `${markerPos.x + (ImgEl.current && imgDimensions?.width > ImgEl.current.width ? 0 : CORRECTION_LEFT)}px`,
          ...inheritedStyle
          // backgroundColor: isResolved && !isLoomVideoAttached ? '#B0FF62' : '',
        }}
        tabIndex={0}
      >
        {!isResolved ? <span className={isLoomVideoAttached ? `buddle-text` : ''} style={isLoomVideoAttached && index < 10 ? { marginLeft: '34%' } : {}}>{index + 1}</span> : isLoomVideoAttached ? <span style={{ marginLeft: '-5px' }}><Tick height={10} width={10} /> </span> : <Tick height={10} width={10} />}
      </div>
    </Draggable >
  );
};

export default CaptureDraggableMarker;
