type Props = {
  classname: string;
  strokeColor?: string;
};

const EyeIcon = ({ classname, strokeColor }: Props) => {
  return (
    <svg
      width="26"
      height="16"
      viewBox="0 0 26 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 14.6667C19.6274 14.6667 25 7.99999 25 7.99999C25 7.99999 19.6274 1.33333 13 1.33333C6.3726 1.33333 1 7.99999 1 7.99999C1 7.99999 6.3726 14.6667 13 14.6667Z"
        stroke={strokeColor ? strokeColor : '#808080'}
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M12.6667 10.6667C14.1394 10.6667 15.3333 9.47273 15.3333 7.99999C15.3333 6.52726 14.1394 5.33333 12.6667 5.33333C11.1939 5.33333 10 6.52726 10 7.99999C10 9.47273 11.1939 10.6667 12.6667 10.6667Z"
        stroke={strokeColor ? strokeColor : '#808080'}
        stroke-width="2"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default EyeIcon;
