import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  deleteObject,
} from 'firebase/storage';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import { getUser } from './auth';
import { saveCapture, updateCapture } from './firestore';
import { ImgSize } from '@/components/capture/types';

export const saveFile = async (
  blob: Blob,
  imgSize: ImgSize,
  progressCb: Function,
  resultCb: Function,
  group: string,
  addData: object = {},
  creator?: any,
  currentUser?: any
) => {
  try {
    const storage = getStorage();
    let { uid = '', displayName, email, isAnonymous } = currentUser || {};
    if (creator) {
      //Added Field..Validation
      uid = creator.uid;
      displayName = creator.displayName || '';
      email = creator.email || '';
      isAnonymous = creator.isAnonymous;
    }
    const cid = nanoid();
    const now = dayjs().unix();

    const usersCollection = ref(storage, `captures/${uid}/${group}/${cid}`);
    const uploadTask = uploadBytesResumable(usersCollection, blob, {
      cacheControl: `public, max-age=${365 * 24 * 60 * 60 * 1000}`,
    });

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        progressCb(true, snapshot.bytesTransferred, snapshot.totalBytes);
      },
      (err) => {
        console.log(err);
      },
      async () => {
        const url = await getDownloadURL(usersCollection);

        let startDate = new Date();
        startDate.setDate(startDate.getDate() + 30);
        let timestamp = Math.floor(startDate.getTime() / 1000);

        await saveCapture(uid, cid, {
          cid,
          url,
          group: group === 'non-project' ? null : group,
          capturedUrl: `upload/${uid}/${cid}`,
          port: '',
          createdAt: now,
          creator: {
            uid,
            displayName,
            email,
          },
          view: {
            count: 0,
            viewer: [],
          },
          isDone: true,
          name: '',
          width: imgSize.width,
          height: imgSize.height,
          isDeleted: false,
          expiredAt: timestamp,
          ...addData,
        });
        resultCb(`${uid}/${cid}`, cid);
        // console.log(`Result CallBack=====================`);
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export const saveImgWithMarkers = async (
  blob: Blob,
  uid: string,
  cid: string,
) => {
  try {
    const storage = getStorage();

    const usersCollection = ref(storage, `capturesWithMarkers/${uid}/${cid}`);
    const uploadTask = uploadBytesResumable(usersCollection, blob, {
      cacheControl: `public, max-age=${365 * 24 * 60 * 60 * 1000}`,
    });

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        console.log(snapshot.bytesTransferred, snapshot.totalBytes);
      },
      (err) => {
        console.log(err);
      },
      async () => {
        const url = await getDownloadURL(usersCollection);

        await updateCapture(uid, cid, {
          imgUrlWithMarkers: url
        });
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export const getFile = async (url: string) => {
  const storage = getStorage();
  const storageRef = ref(storage, url);
  await getDownloadURL(storageRef);
};

export const remove = async (path: string) => {
  const storage = getStorage();
  const storageRef = ref(storage, `captures/${path}`);
  await deleteObject(storageRef);
};

export const replace = async (
  uid: string,
  cid: string,
  imgSize: ImgSize,
  blob: Blob,
  path: string,
  progressCb: Function,
  cb: Function
) => {
  const storage = getStorage();
  const usersCollection = ref(storage, `captures/${path}`);
  const uploadTask = uploadBytesResumable(usersCollection, blob);
  uploadTask.on(
    'state_changed',
    (snapshot) => {
      progressCb(true, snapshot.bytesTransferred, snapshot.totalBytes);
    },
    (err) => {
      console.log(err);
    },
    async () => {
      const url = await getDownloadURL(usersCollection);
      await updateCapture(uid, cid, {
        url,
        width: imgSize.width,
        height: imgSize.height,
      });

      cb(`${uid}/${cid}`);
    }
  );
};
