import { FC, useState } from 'react';
import type { ProcessServerConfigFunction } from 'filepond';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { isFileUploadingState } from '@/state/uiState';
import { useRecoilState, useRecoilValue } from 'recoil';
import UploadModelDefault from '../icon/UploadModelDefault';
import UploadModelDrag from '../icon/UploadModelDrag';

import { currentUserInfoState } from '@/state/userState';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const DashFileUpload: FC<{
  process: ProcessServerConfigFunction;
  isDragging?: boolean;
}> = ({ process, isDragging }) => {
  const [, setFileUploadingState] = useRecoilState(isFileUploadingState);
  const currentUserInfo = useRecoilValue(currentUserInfoState);
  const { plan } = currentUserInfo;
  const [isError, setisError] = useState(false);

  const defaultSvg = () => {
    return `<div class="grid grid-cols-1 cursor-pointer">
              <div class="mx-auto">
              ${isDragging || isError ? UploadModelDrag() : UploadModelDefault()
      }
              </div>
              <div class="mx-auto">
              <p class="upload-drag-txt">Drag and drop files, or <span class="pink-txt">Browse </span></p>
              </div>
              <div class="mx-auto">
              ${isError
        ? `<p class=" error-upload-file upload-grey-txt" >
                    This file type is not allowed here.
                  </p>`
        : `<p class="upload-grey-txt">Support Image and PDF files</p>`
      }

              </div>
        </div>`;
  };

  return (
    <FilePond
      labelFileProcessingError={(err) => {
        const elToHide = document.querySelector(
          'ul.filepond--list'
        ) as HTMLAnchorElement;

        elToHide.hidden = true;

        const elToShow = document.querySelector(
          '.filepond--drop-label'
        ) as HTMLAnchorElement;

        elToShow.setAttribute(
          'style',
          'display:block;border: 1px solid #ff0000; '
        );
        return '';
      }}
      instantUpload={true}
      allowRevert={false}
      oninit={() => {
        setisError(false);
        const el = document.querySelector(
          'a.filepond--credits'
        ) as HTMLAnchorElement;
        if (el) {
          el.hidden = true;
        }
      }}
      onprocessfile={() => {
        setFileUploadingState('FINISH');
      }}
      onerror={(error: any) => {
        if (error?.main != "File is too large") {
          setisError(true);
        }
        setFileUploadingState('ERROR');
      }}
      onaddfile={() => {
        setFileUploadingState('START');
      }}
      server={{ process }}
      name="files"
      labelIdle={defaultSvg()}
      labelTapToCancel={""}
      {...(plan && {
        allowFileSizeValidation: true,
        maxFileSize: plan == "pro" ? "50MB" : "5MB",
      })}
    />
  );
};
export default DashFileUpload;
