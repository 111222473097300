import { useRecoilState, useResetRecoilState } from 'recoil';
import {
  boundingRectState,
  isBoundingBoxShowState,
} from '@/state/boundingBoxState';

export const useBoundingBox = () => {
  const [rect, setRect] = useRecoilState(boundingRectState);
  const reSetRect = useResetRecoilState(boundingRectState);
  const [isBoundingBoxShow, setIsBoundingBoxShow] = useRecoilState(
    isBoundingBoxShowState
  );

  return {
    isBoundingBoxShow,
    setIsBoundingBoxShow,
    rect,
    setRect,
    reSetRect,
  };
};
