import { FC } from 'react';
import FileUploadPopup from './FileUploadPopup';
import { useRecoilValue } from 'recoil';
import UrlUpload from '../UrlUpload';
import {
  countCaptureListState,
  groupedByHostCaptureListSharedState,
  isProjectOpen,
} from '@/state/captureState';
import CaptureGrid from './CaptureGrid';
import { showMyProjectState } from '@/state/uiState';

type Props = {
  isExpired?: boolean;
  isDeleted?: boolean;
};

const CaptureBody: FC<Props> = ({ isExpired, isDeleted }) => {
  const countCaptureList = useRecoilValue(countCaptureListState);
  const countSharedList = useRecoilValue(
    groupedByHostCaptureListSharedState
  ).length;
  const currentProject = useRecoilValue(isProjectOpen);
  const showMyProject = useRecoilValue(showMyProjectState);

  return (
    <div className="w-full container-width mb-48 sm:w-full flex sm:flex-col">
      {((countCaptureList < 1 && showMyProject) ||
        (countSharedList < 1 && !showMyProject)) &&
      !currentProject.open && !isDeleted && !isExpired ? (
        <>
          <div className="w-1/2 sm:w-full flex flex-col mr-10">
            <div className="">
              <FileUploadPopup />
            </div>
          </div>
          <div className="w-1/2 sm:w-full flex flex-col">
            <div className="block-upload flex w-full py-10 px-6 justify-center bg-transparent border-2 border-dashed rounded-xl text-sm font-bold">
              <UrlUpload />
            </div>
          </div>
        </>
      ) : (
        // )
        // : currentProject.open ? (
        //   <CaptureListing
        //     currentProjectId={currentProject.gid as string}
        //     sharedWithMe={!showMyProject}
        //     group={currentProject.gid}
        //     projectName={currentProject.projectInfo?.name}
        //   />
        <CaptureGrid
          sharedWithMe={!showMyProject}
          isExpired={isExpired}
          isDeleted={isDeleted}
        />
      )}
    </div>
  );
};

export default CaptureBody;
