import { currentToolObjectState } from '@/state/toolState';
import { useState, useCallback, MouseEvent } from 'react';
import { useRecoilValue } from 'recoil';

let isMouseDown = false;

export const useDrag = () => {
  const [position, setPosition] = useState(null);
  const currentToolObject = useRecoilValue(currentToolObjectState)
  const onDrag = useCallback(
    (elementEvent: MouseEvent<HTMLElement>, zoomValue: number) => {
      const parentRect =
        elementEvent?.currentTarget?.parentElement?.getBoundingClientRect();
      const rect = elementEvent?.currentTarget?.getBoundingClientRect();

      elementEvent.currentTarget.focus();
      elementEvent.preventDefault();
      elementEvent.stopPropagation();

      let { clientX: startX, clientY: startY } = elementEvent;

      const positionY = startY - rect.top;
      const positionX = startX - rect.left;

      let element = elementEvent.currentTarget;
      if (element.dataset.isDraggable !== 'true') return;
      if (element.dataset.dragByParent === 'true') {
        // @ts-ignore
        element = element.parentElement;
      }
      const originCursor = element.style.cursor;
      element.style.cursor = 'grabbing';
      isMouseDown = true;
      const onMove = (e: globalThis.MouseEvent) => {
        if (!isMouseDown) return;
        e.stopImmediatePropagation();
        const { clientX, clientY } = e;
        const deltaX = clientX - startX;
        const deltaY = clientY - startY;
        let top =
          (e.clientY - (parentRect?.top ?? 0) + deltaY - positionY) / zoomValue;
        let left =
          (e.clientX - (parentRect?.left ?? 0) + deltaX - positionX) /
          zoomValue;

          // console.log("currentToolObject ::", currentToolObject)
        if(currentToolObject?.rotate != 0){
          top += 30
          left += 30
        }

        // element.style.top = `${top}px`;
        // element.style.left = `${left}px`;

        // @ts-ignore
        setPosition({ top, left });

        startX = clientX;
        startY = clientY;
      };

      const onUp = () => {
        element.style.cursor = originCursor;
        document.removeEventListener('mousemove', onMove);
        document.removeEventListener('mouseup', onUp);
        if (!isMouseDown) return;
        isMouseDown = false;
      };
      document.addEventListener('mousemove', onMove);
      document.addEventListener('mouseup', onUp);
    },
    []
  );
  return { onDrag, position };
};
