import { FC, useEffect, useState, useRef } from 'react';
import { Dialog } from '@headlessui/react';
import Template from './Template';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  currentCaptureInfoState,
  sortedCaptureCommentState,
} from '@/state/captureState';
// import Loading from '../Loading';
import CircleClose from '../icon/CircleClose';
import { currentUserIDState } from '@/state/userState';
import SettingContent from '../Settings/SettingContent';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  // info: any;
};

const SettingModal: FC<Props> = ({ isOpen, closeModal }) => {
  const currentUserID = useRecoilValue(currentUserIDState);
  const captureComment = useRecoilValue(sortedCaptureCommentState);
  const setCurrentCapture = useSetRecoilState(currentCaptureInfoState);
  const navigate = useNavigate();

  const listItems = ['Profile', 'Notifications', 'Slack Integration'];

  return (
    <Template isOpen={isOpen} closeModal={() => closeModal()}>
      <div
        style={{ height: '689px', maxWidth: '816px' }}
        className={`inline-block setting-modal w-full max-w-4xl overflow-hidden text-left align-middle transition-all transform rounded-xl bg-white  pt-6 sm:mt-6 default-modal `}
      >
        <Dialog.Title as="h3" className="text-2xl text-gray-900 w-full px-6">
          <div className="flex">
            <div className="w-full">
              <h4 className="fileupload-modal-heading">Settings</h4>
            </div>
            <div className="w-1/4 float-right" onClick={closeModal}>
              <div className="float-right mb-5">
                <div className="flex items-center cursor-pointer">
                  <CircleClose />
                </div>
              </div>
            </div>
          </div>
        </Dialog.Title>
        <hr />
        <SettingContent />
      </div>
    </Template>
  );
};

export default SettingModal;
