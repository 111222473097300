import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { deletingExpiredCapturesState, isAppLoadingState, isExtensionActive } from '@/state/appState';
import NewHeader from './NewHeader';
import NewCapture from './NewCapture';
import Footer from '../footer/Footer';
import { Toaster } from 'react-hot-toast';
import Loading from '../Loading';
import { useEffect, useState } from 'react';
import ComingSoon from '../ComingSoon';
import { isDashBoardState } from '@/state/uiState';
import RecentCapture from './RecentCapture';
import { currentUserInfoState } from '@/state/userState';
import { User } from 'firebase/auth';
import Projects from './Projects';
import { useClerkAuth } from '@/hooks/useClerkAuth';
import { AppSumoBanner } from './AppSumoBanner';

const Dashboard = () => {
  const isExtension = useRecoilValue(isExtensionActive);
  const isAppLoading = useRecoilValue(isAppLoadingState);
  const [deletingExpiredCaptures, setDeletingExpiredCaptures] = useRecoilState(deletingExpiredCapturesState);

  const [display, setDisplay] = useState(
    window.innerWidth <= 860 ? 'block' : 'none'
  );
  const setiIsDashBoard = useSetRecoilState(isDashBoardState);
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  useClerkAuth();

  const handleWindowSizeChange = () => {
    setDisplay(window.innerWidth <= 860 ? 'block' : 'none');
  };

  useEffect(() => {
    setiIsDashBoard(true);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  // commnet this
  if (isAppLoading) return <Loading />;

  return (
    <div>
      <div className="comingSoong" style={{ display: `${display}` }}>
        <ComingSoon />
      </div>
      <div className={`flex flex-col justify-between dashboard ${display === 'block' ? 'hidden' : ''} `}  >
        <AppSumoBanner />
        <NewHeader isExtension={isExtension} />

        {!currentUserInfo.isAnonymous && <RecentCapture />}
        {currentUserInfo.isAnonymous ? false : !currentUserInfo.isAnonymous && !deletingExpiredCaptures ? <Projects /> : <Loading/>}

        <NewCapture isExtension={isExtension} />

      </div>
    </div>
  );
};

export default Dashboard;
