import { number } from "yup";

type Props ={
  width ?:number,
  height ?:number,
  className ?: string,
}
const Pencil =(props:Props) => (
  <svg
    width={props.width || '14'}
    height={props.height  || '14'}
    className={`mr-2 cursor-pointer ${props.className ? props.className : ""}` }
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
    <path
      d="M2.33325 14H14.3333"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.66675 8.90663V11.3333H6.10581L13.0001 4.43603L10.5651 2L3.66675 8.90663Z"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinejoin="round"
    />
  </svg>
);

export default Pencil;
