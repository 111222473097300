import dayjs from 'dayjs';
import { User } from 'firebase/auth';
import {
  getFirestore,
  collection,
  onSnapshot,
  getDoc,
  doc,
  updateDoc,
  increment,
  setDoc,
  DocumentReference,
  query,
  where,
  getDocs,
  Unsubscribe,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/lib/firebase/auth';

export const getAnonymousPopulation = async () => {
  const db = getFirestore();
  const docRef = doc(db, 'instacap/anonymous') as DocumentReference<{
    population: number;
  }>;
  try {
    await updateDoc(docRef, {
      population: increment(1),
    });
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().population;
    }
  } catch (error) {
    const docRef = doc(db, 'instacap/anonymous');
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      await setDoc(docRef, { population: 1 });
    }
    return 1;
  }
};

export const setUser = async (uid: string, info: object) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, 'instacap', 'users', 'uids');
    const result = await setDoc(doc(colRef, uid), { createdAt: dayjs().unix(), ...info });
    return result;
  } catch (error) {
    console.error('Error set user: ', error);
    return error;
  }
};

export const createUser = async (info: any) => {
  try {
    const { data }: any = await httpsCallable(functions, 'createUser')(info);
    return data;
  } catch (error) {
    console.error('Error get user: ', error);
  }
};

export const getUser = async (uid: string, cb?: Function) => {
  let unsubscribe: Unsubscribe | undefined;
  try {
    if (uid) {
      if (String(uid).length > 0) {
        let result: any = false;
        const db = getFirestore();
        const colRef = collection(db, 'instacap', 'users', 'uids');
        const userDocRef = doc(colRef, uid);
        // Get initial data
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          result = userData;

          // Add real-time listener to query
          unsubscribe = onSnapshot(userDocRef, (snapshot) => {
            if (snapshot.exists()) {
              // Update user data if the document exists
              const newData = snapshot.data();
              result = newData;
            } else {
              // Document doesn't exist anymore, handle this case if needed
              result = false;
            }
            cb && cb(result);
          });

        }
        return result;
      }
    }
    return false;
  } catch (error) {
    console.error('Error get user: ', error);
  }
};

export const getUserSub = (uid: string, cb: Function) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, 'instacap', 'users', 'uids');
    const unsub = onSnapshot(
      doc(colRef, uid),
      (doc) => {
        cb({ ...doc.data(), uid });
      },
      (err) => {
        console.log(err);
      }
    );

    return () => {
      // console.log('here');
      unsub();
    };
  } catch (error: any) {
    console.error('Error get creator info: ', error);
  }
};

export const updateUser = async (uid: string, info: object) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, 'instacap', 'users', 'uids');
    await updateDoc(doc(colRef, uid), { updatedAt: dayjs().unix(), ...info });
  } catch (error: any) {
    if (error.code === 'not-found') {
      await setUser(uid, info)
    }

    console.log('Error update user-: ', error);
  }
};
export const getUserWithEmail = async (email: string) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, 'instacap', 'users', 'uids');
    const q = query(colRef, where('email', '==', email));
    const colSnap = await getDocs(q);

    let user: User[] = [];

    for await (const docSnap of colSnap.docs) {
      if (docSnap.exists()) {
        // console.log(`User Ids${docSnap.id}`);
        let data = docSnap.data();
        data.uid = docSnap.id.toString();
        if (data?.isAnonymous == false) {
          user.push(data as User);
        }
      }
    }
    return user;
  } catch (error) {
    console.log('Error get user by email: ', error);
  }
};
