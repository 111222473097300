import { FC, memo } from 'react';

interface props {
  width?: number;
  height?: number;
  opacity?: number;
  fill?: string;
  strokeColor?: string;
}

const PlaneIcon: FC<props> = ({
  height = 18,
  width = 18,
  opacity = 1,
  fill = 'none',
  strokeColor = '#808080',
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      opacity={opacity}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.83317 3.51232C6.83317 2.17135 7.61094 1.50087 7.99984 1.33325C8.38874 1.50087 9.1665 2.17135 9.1665 3.51232V6.02662L14.3332 10.3333V11.6666L8.99984 8.99992V11.9999L10.6665 14.6666L7.99984 13.6666L5.33317 14.6666L6.99984 11.9999V8.99992L1.6665 11.6666V10.3333L6.83317 6.02662V3.51232Z"
        stroke={strokeColor}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(PlaneIcon);
