import AppSumoBnnerPng from '../../assets/icons/app-sumo-banner.png';
import FirstBannerIcon from '../icon/tool/FirstBannerIcon';
import ForthBannerIcon from '../icon/tool/ForthBannerIcon';
import SecondBannerIcon from '../icon/tool/SecondBannerIcon';
import ThirdBannerIcon from '../icon/tool/ThirdBannerIcon';

export const AppSumoBanner = () => {
  const redirectToAppSumo = () => {
    window.location.href = 'https://appsumo.com/products/instacap/?p=1';
  };

  return (
    <div
      style={{
        width: '100%',
        height: '48px',
        gap: '30px',
        backgroundColor: '#F7F5EE',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottomWidth: "1px"
      }}
    >
      <FirstBannerIcon classname="" />
      <SecondBannerIcon classname="" />
      <span style={{ fontWeight: 600 }}>
        🎉 Big News! Lifetime Deal on Instacap is NOW on AppSumo! 🚀 Grab it
        while it lasts! 👉 
        <span
          className="cursor-pointer hover:underline hover:text-blue-500"
          onClick={redirectToAppSumo}
        >
          Check it out!
        </span>
         🎉
      </span>
      <ThirdBannerIcon classname="" />
      <ForthBannerIcon classname="" />
    </div>
  );
};
