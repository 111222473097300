import React, { useState } from 'react';
import { useColors } from '@/hooks/useScreenshotEditor';
import GradientPicker from '@/components/editor/LeftSidebarSection/gradientPicker';
import { setGradientDirection } from 'store/slice/editor.slice';
import { useDispatch } from 'react-redux';
import SolidColorComponent from './solidColorPicker';
import ImageComponent from './imagePicker';
import FramePicker from './framePicker';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/newlogo-white.svg';

const BackgroundSection: React.FC = () => {
  const {
    backgroundType,
    gradientColors,
    handleRemoveColor,
    handleAddColor,
    handleColorPickerChange,
    addGradientToColorPallet,
    setBackgroundColor,
    backgroundColor,
    handleBackgroundImage,
  } = useColors();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('gradient');

  const handleTabChange = (type: string) => {
    setActiveTab(type);
  };

  const handleGradientDirectionChange = (direction: string) => {
    dispatch(setGradientDirection(direction));
  };

  return (
    <div className="px-5 left-sidebar ht w-full h-full border-r border-black bg-white">
      <Link to="/">
        <img className="h-14 mt-4 w-auto" src={Logo} alt="Your Company" />
      </Link>
      <div className="">
        <div
          className="grid grid-cols-4 border-r rounded-lg bottom-card border-black gap-2 mt-5 text-black"
          style={{
            backgroundColor: '#f7f5ee',
          }}
        >
          {['gradient', 'solid', 'image', 'frame'].map((type) => (
            <div
              key={type}
              className={`p-2 m-1 rounded-md text-xs flex items-center justify-center cursor-pointer ${
                activeTab === type ? 'bg-black text-white font-semibold' : ''
              }`}
              onClick={() => {
                handleTabChange(type);
              }}
            >
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </div>
          ))}
        </div>
        {activeTab === 'gradient' ? (
          <GradientPicker
            gradientColors={gradientColors}
            handleRemoveColor={handleRemoveColor}
            handleAddColor={handleAddColor}
            handleColorPickerChange={handleColorPickerChange}
            addGradientToColorPallet={addGradientToColorPallet}
            handleGradientDirectionChange={handleGradientDirectionChange}
          />
        ) : activeTab === 'solid' ? (
          <SolidColorComponent
            setBackgroundColor={setBackgroundColor}
            backgroundColor={backgroundColor}
          />
        ) : activeTab === 'image' ? (
          <ImageComponent handleBackgroundImage={handleBackgroundImage} />
        ) : activeTab === 'frame' ? (
          <FramePicker />
        ) : null}
      </div>
    </div>
  );
};

export default BackgroundSection;
