import { useRecoilValue } from 'recoil';
import { currentUserInfoState } from '@/state/userState';
import type { User } from 'firebase/auth';
import { SignInButton } from '@clerk/clerk-react';
import { CustomUserButton } from '../clerk';

const SignIn = ({ className = "", mode = "section" }) => {
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;

  if (!currentUserInfo) return null;

  return (
    <div className={`${mode == "text" ? "" : "has-tooltip w-full h-full flex justify-center items-center"} cursor-pointer ${className}`}>
      {currentUserInfo?.isAnonymous ?
        <SignInButton mode="modal" redirectUrl="">
          <span className={`${mode == "text" ? "underline font-bold" : "capitalize"}`}>{"sign in"}</span>
        </SignInButton> :
        <CustomUserButton />
      }
    </div>
  );
};

export default SignIn;
