import { FC } from 'react';

interface props {
  width?: number;
  height?: number;
  opacity?: string;
}

const VideoFileIcon: FC<props> = ({ height = 17, width = 17, opacity = 1 }) => {
  return (
    <svg
      width={width}
      height={height}
      opacity={opacity}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33366 14.6663H12.667C13.0352 14.6663 13.3337 14.3679 13.3337 13.9997V4.66634H10.0003V1.33301H3.33366C2.96547 1.33301 2.66699 1.63148 2.66699 1.99967V13.9997C2.66699 14.3679 2.96547 14.6663 3.33366 14.6663Z"
        stroke="#FF38A4"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 1.33301L13.3333 4.66634"
        stroke="#FF38A4"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66699 7H9.16459V8.16667L11.3337 7.33333V11L9.16459 10.1667V11.3333H4.66699V7Z"
        stroke="#FF38A4"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VideoFileIcon;
