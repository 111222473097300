import { useEffect, useState } from 'react';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import Folder from './Folder';

import {
  captureListState,
  capturesDeletedState,
  groupedByHostCaptureListSharedState,
  groupedByHostCaptureListState,
  isProjectOpen,
} from '@/state/captureState';
import { useNavigate } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import plus from '../../assets/icons/addNew.svg';
import AddProjectModal from '../modal/AddProjectModal';
import { currentUserIDState, currentUserInfoState } from '@/state/userState';
import {
  deleteProject,
  getProjectSub,
  getProjects,
  saveProject,
  updateProject,
} from '@/lib/firebase/firestore';
import { Project } from 'types/project';
import {
  currentProjectListState,
  expiredProjectState,
  projectListState,
} from '@/state/projectState';
import dayjs from 'dayjs';
import { deletedProject, showMyProjectState } from '@/state/uiState';
import { date } from 'yup';
import { useGetUserSubscription } from '@/hooks/useGetUserSubscription';
import { useOrganization, useUser } from '@clerk/clerk-react';
import { map, uniqBy } from 'lodash';
import { checkDocumentExists, checkSharedDocumentExists } from '@/lib/firebase/firestore/group';

const Projects = () => {
  const navigate = useNavigate();
  const currentUserID = useRecoilValue(currentUserIDState);
  const setCurrentProjectListState = useSetRecoilState(currentProjectListState);

  const [isOpen, setIsOpen] = useState(false);
  const [currentProjects, setCurrentProjects] = useState<Project[]>([]);
  const [expiredProjectData, setExpiredProjectData] = useState({
    name: 'Expired',
    gid: 'expired',
    numItems: 0,
    uid: currentUserID,
    createdAt: 0,
  });
  const [deletedProjectData, setDeletedProjectData] = useState({
    name: 'Deleted',
    gid: 'deleted',
    numItems: 0,
    uid: currentUserID,
    createdAt: 0,
  });

  const showMyProjects = useRecoilValue(showMyProjectState);
  const groupedByHostCaptureList = useRecoilValue(
    showMyProjects
      ? groupedByHostCaptureListState
      : groupedByHostCaptureListSharedState
  );

  // console.log(groupedByHostCaptureList);

  const projectList = useRecoilValue(projectListState);
  const captureList = useRecoilValue(captureListState);
  const currentUserInfo = useRecoilValue(currentUserInfoState)

  const setProjectOpen = useSetRecoilState(isProjectOpen);
  const deletedProjectInfo = useRecoilValue(deletedProject);
  const capturesDeleted = useRecoilValue(capturesDeletedState);

  const { subscription } = useGetUserSubscription(currentUserID);
  const { organization } = useOrganization();
  const { user } = useUser();

  const setExpiredProject = useRecoilCallback(
    ({ set }) =>
      (expiredProjectData: any) => {
        const extendedExpiredProjectData = {
          gid: 'expired',
          open: true,
          captureCount: expiredProjectData.numItems,
          projectInfo: expiredProjectData,
        };
        set(expiredProjectState, extendedExpiredProjectData);
      },
    [expiredProjectData, subscription]
  );

  useEffect(() => {
    setExpiredProject(expiredProjectData);
  }, [expiredProjectData]);

  useEffect(() => {
    const projects = groupedByHostCaptureList
      .map(([_, group]) => {
        let captures = [...group];
        if (subscription === 'free' && currentUserInfo?.tier !== "1") {
          const currentDateUnix = Math.floor(new Date().getTime() / 1000);
          captures = captures.filter(
            (item) => item.expiredAt && item.expiredAt > currentDateUnix
          );
        }

        return {
          name: group[0].projectName ?? '',
          gid: group[0].group,
          numItems: uniqBy(captures, 'cid').length,
          uid: group[0].creator.uid,
        };
      })
      .filter((p) => !!p.gid);

    const currentDateUnix = Math.floor(new Date().getTime() / 1000);

    const countExpired = captureList.reduce((count, obj) => {
      if (obj.expiredAt && obj.expiredAt < currentDateUnix) {
        return count + 1;
      } else {
        return count;
      }
    }, 0);

    const expiredFolder = {
      name: 'Expired',
      gid: 'expired',
      numItems: countExpired,
      uid: currentUserID,
      createdAt: 0,
    };
    setExpiredProjectData(expiredFolder);

    const deletedFolder = {
      name: 'Deleted',
      gid: 'deleted',
      numItems: capturesDeleted.length,
      uid: currentUserID,
      createdAt: 0,
    };
    setDeletedProjectData(deletedFolder);

    projects.forEach((p) => {
      const funcPram = organization ? organization.id : currentUserID;
      // const funcPram = currentUserID;
      const newProject = projectList.find((x) => x.gid === p.gid);
      if (!newProject) {
        return saveProject(funcPram, { ...p, createdAt: dayjs().unix() });
      }
      if (p.numItems !== newProject.numItems) {
        updateProject(funcPram, newProject.gid, { numItems: p.numItems });
      }
    });
    // update numItems to 0 or delete
    projectList.forEach((g) => {
      const project = projects.find((p) => p.gid === g.gid);
      if (!project) {
        const funcPram = organization ? organization.id : currentUserID;
        // const funcPram = currentUserID;
        if (deletedProjectInfo.gid === g.gid) {
          return deleteProject(funcPram, g.gid);
        } else {
          updateProject(funcPram, g.gid, { numItems: 0 });
        }
      }
    });

    let projectListBasedOnTab = projectList;
    projectListBasedOnTab = projectListBasedOnTab.filter((project) => {
      const userOganziations = map(user?.organizationMemberships, (org) =>
        org.organization.id.replace("mem", '')
      );

      if (showMyProjects) {
        return project.uid === (organization ? organization.id : currentUserID);
        // return project.uid === currentUserID;
      } else {
        return project.uid !== (organization ? organization.id : currentUserID) && !userOganziations.includes(project.uid);
        // return project.uid !== currentUserID && !userOganziations.includes(project.uid);
      }
    });

    if(!showMyProjects){
      const checkProjectsExistence = async () => {
        const checks = await Promise.all(
          projectListBasedOnTab.map(async (project) => ({
            exists: await checkDocumentExists(project, currentUserInfo.email),
            project
          }))
        );
  
        const existingProjects = checks.filter(c => c.exists).map(c => c.project);
        
        setCurrentProjects(existingProjects);
        setCurrentProjectListState(existingProjects);
      };
    
      checkProjectsExistence();
    } else  {
      setCurrentProjects(projectListBasedOnTab as Project[]);
      setCurrentProjectListState(projectListBasedOnTab as Project[]);
    }
  }, [
    currentUserID,
    deletedProjectInfo.gid,
    groupedByHostCaptureList,
    projectList,
    showMyProjects,
  ]);

  const updateCurrentProject = (data: Project[]) => {
    setProjectOpen((prev) => {
      const updated = data.find((d) => d.gid === prev.gid);
      if (updated) return { ...prev, projectInfo: updated };
      return prev;
    });
  };

  const getProjectList = useRecoilCallback(
    ({ set }) =>
      async () => {
        const funcPram = organization ? organization.id : currentUserID;
        // const funcPram = currentUserID;

        const list = await getProjects(funcPram);
        if (list) set(projectListState, list);

        return getProjectSub(funcPram, (data: Project[]) => {
          set(projectListState, data);
          updateCurrentProject(data);
        });
      },
    [currentUserID]
  );

  useEffect(() => {
    if (!currentUserID) return;
    let unsub: Function;
    getProjectList().then((cb) => {
      unsub = cb;
    });
    return () => {
      unsub && unsub();
    };
  }, [currentUserID, getProjectList]);

  const openFolder = (folderId: string) => {
    navigate(`/project/${folderId}`);
  };

  return (
    <div className="w-full pt-8 pb-2">
      <div className="container-width">
        <div className="flex items-center justify-between mb-7">
          <div className="flex">
            <h4 className="text-2xl font-semibold ml-4">
              {showMyProjects ? organization ? `${organization.name} Projects` : 'My Projects' : 'Shared Projects'}
              {/* {showMyProjects ? 'My Projects' : 'Shared Projects'} */}
            </h4>
            {showMyProjects && (
              <Popover onClick={() => setIsOpen(true)}>
                <Popover.Button
                  className={
                    'has-tooltip disabled relative flex justify-center items-center w-full h-10  rounded-lg hover:opacity-100 sm:h-full  sm:px-3 sm:py-3 px-2'
                  }
                >
                  <img
                    src={plus}
                    className={'cursor-pointer'}
                    width="30px"
                    alt="add"
                  />
                  <span className="tooltip mt-14 tool-tip-txt shadow-2xl">
                    Add project
                  </span>
                </Popover.Button>
              </Popover>
            )}
          </div>
          {/* <button className="w-52 h-11 flex justify-between items-center px-4 rounded-3xl border-1 border-gray-300 text-base font-normal">
            Sort by date
            <ArrowDown width={20} height={20} />
          </button> */}
        </div>
        <div className="grid grid-cols-4 gap-6 md:grid-cols-2 lg:grid-cols-3 lg:gap-4">
          {currentProjects.map((data) => (
            <div key={data.gid} onClick={() => openFolder(data.gid)}>
              <Folder data={data} />
            </div>
          ))}

          {subscription === 'free' && currentUserInfo?.tier !== "1" && !organization &&
            showMyProjects &&
            Object.keys(expiredProjectData).length > 0 && (
              <>
                <div onClick={() => navigate('/expired')}>
                  <Folder data={expiredProjectData} isExpired />
                </div>
              </>
            )}

          {showMyProjects && Object.keys(deletedProjectData).length > 0 && (
            <>
              <div onClick={() => navigate('/deleted')}>
                <Folder data={deletedProjectData} isDeleted />
              </div>
            </>
          )}
        </div>
      </div>
      {isOpen && (
        <AddProjectModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />
      )}
    </div>
  );
};

export default Projects;
