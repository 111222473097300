import { FC } from "react";

interface props {
  width?: number;
  height?: number;
}

const SendBigButton: FC<props> = ({height = 16, width= 16}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    className="inline"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
    <path
      d="M14.3334 1.66675L9.90008 14.3334L7.36675 8.63341L1.66675 6.10008L14.3334 1.66675Z"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinejoin="round"
    />
    <path
      d="M14.3334 1.66675L7.3667 8.63341"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SendBigButton;
