import { getUser } from '@/lib/firebase/firestore';
import { currentUserIDState } from '@/state/userState';
import { Unsubscribe } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

export const useGetUserSubscription = (uid: string) => {
  const [subscription, setSubscription] = useState<string>('free');
  const currentUserID = useRecoilValue(currentUserIDState);
  let unsubscribe: Unsubscribe | undefined;

  const getCurrentUserSubscription = async () => {
    try {
      const meUser = await getUser(uid, (data: any) => {
        const { plan = 'free' } = data;
        setSubscription(plan);
      });
      if (meUser) {
        if (meUser?.plan) {
          setSubscription(meUser.plan);
        }
      }
    } catch (error) {
      console.error('Eroare:', error);
    }
  };

  useEffect(() => {
    getCurrentUserSubscription();

    return () => {
      setSubscription('free');
      // Clean up subscription when component unmounts or uid changes
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [uid]);

  return { subscription };
};

export enum userSubscriptionEnum {
  free = 'free',
  pro = 'pro',
  team = 'team',
}
