import { useEffect, useState } from 'react';
import * as markerjs2 from 'markerjs2';
import * as firestore from '@/lib/firebase/firestore';
import * as firebase from '@/lib/firebase/firestore';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUserInfoState } from '@/state/userState';
import { pathState } from '@/state/appState';
import { currentCaptureInfoState, imgRefState } from '@/state/captureState';
import { saveImgWithMarkers } from '@/lib/firebase/storage';
import { useMarker } from '@/components/context/MarkerContext';
import { useToolObject } from './useToolObject';
// import { useMarker } from '../useMarker';
// import { useMarker } from '@/context/MarkerContext';  // Import context

export const useMarkerTools = ({ imgRef }: any) => {
  const [savedImg, setSavedImg] = useState<string | null>(null);
  const [prevCid, setPrevCid] = useState<string | null>(null);

  const [markerState, setMarkerState] = useState<markerjs2.MarkerAreaState | null>(null);
  const { uid } = useRecoilValue(currentUserInfoState);
  const { toolMarkers, imgUrlWithMarkers } = useRecoilValue(currentCaptureInfoState);
  const { cid, uid: pathUid } = useRecoilValue(pathState);
  const setImgRef = useSetRecoilState(imgRefState);
  const { markerArea, setMarkerArea, setContextImgRef, contextCaptureInfoHeight } = useMarker();  // Use context
  const {setToolStateAfterInsert} = useToolObject();

  const replaceUndefinedWithNull = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map(replaceUndefinedWithNull);
    } else if (obj && typeof obj === 'object') {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key] = value === undefined ? null : replaceUndefinedWithNull(value);
        return acc;
      }, {} as any);
    }
    return obj;
  };

  const dataURItoBlob = (dataURI: any) => {
    const byteString = decodeBase64ToBytes(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    return new Blob([byteString], { type: mimeString });
  };

  const decodeBase64ToBytes = (base64: any) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  const saveMarkers = async ({ markerData, base64Img }: any) => {
    const { markers } = markerData;

    markerData.markers = replaceUndefinedWithNull(markers);

    const blob = dataURItoBlob(base64Img);

    saveImgWithMarkers(blob, pathUid, cid);

    await firestore.updateCapture(pathUid, cid, {
      toolMarkers: markerData,
    });

    firebase.getCaptureInfoSub(pathUid, cid, (result: any) => {
      // console.log(result);
      // setMarkerState(result.toolMarkers);
    });
  };


  const showMarkerArea = (imgRefCurrent: any) => {
    if(markerArea && markerArea.isOpen) return
    if (imgRefCurrent) {
      let newMarkerArea = markerArea;
  
      if (!markerArea) {
        newMarkerArea = new markerjs2.MarkerArea(imgRefCurrent);
        setMarkerArea(newMarkerArea);
      }
  
      const imgRect = imgRefCurrent.getBoundingClientRect();
  
      if (newMarkerArea) {
        newMarkerArea.settings.uiOffsetLeft = imgRect.left - 2;
        newMarkerArea.settings.uiOffsetTop = imgRect.top - 11; // minus toolbar height
        // newMarkerArea.uiStyleSettings.toolboxBackgroundColor = 'bg-black';
        markerArea.renderAtNaturalSize = true;
        markerArea.zoomLevel = 1;
        markerArea.zoomSteps = [1, 1.5, 2, 4, 8, 16, 32, 64, 128];


        markerArea.uiStyleSettings.zoomButtonVisible = true;
        markerArea.uiStyleSettings.zoomOutButtonVisible = true;
        
        if(imgRefCurrent.height > contextCaptureInfoHeight){
          markerArea.settings.displayMode = 'popup';
        }

        newMarkerArea.addEventListener('show', () => {
          if (markerState) {
            // Ensure the marker area is fully initialized before restoring state

            setTimeout(() => newMarkerArea.restoreState(markerState), 0);
            // newMarkerArea.restoreState(markerState)
          }
        });
  
        newMarkerArea.addEventListener('render', (event: any) => {
          // Save the marker data when rendering
          const markerData = newMarkerArea.getState();
          setMarkerState(markerData);
          saveMarkers({ markerData, base64Img: event.dataUrl });
          // Update the image source with the new data URL
          if (imgRefCurrent) {
            imgRefCurrent.src = event.dataUrl;
            setSavedImg(event.dataUrl);
          }
          setToolStateAfterInsert();
          imgRef.current.style.visibility = "visible";
        });
  
        newMarkerArea.addEventListener('beforeclose', (event : any) => {
          // Save the marker data when rendering
  
          // Update the image source with the new data URL
          if (imgRefCurrent && savedImg) {
            imgRefCurrent.src = savedImg;
          }
          setToolStateAfterInsert();
          imgRef.current.style.visibility = "visible";
        });

        newMarkerArea.show();
        imgRef.current.style.visibility = "hidden";

        const markerjs2Element = document.querySelector('.__markerjs2_ > div > div:nth-child(2)');

        const markerjs2Logo = document.querySelector('.__markerjs2_ > div > div:nth-child(2) > div > div:nth-child(3)');
        if (markerjs2Logo) {
          console.log("markerjs2Logo", markerjs2Logo)
          markerjs2Logo.style.display = 'none'; 
        } 
      }
    }
  };
  
  

  useEffect(() => {
    if (!imgRef) return;
    if (toolMarkers) {
      setMarkerState(toolMarkers);
    } else{
      const mockToolMarkers = {
        width: imgRef.current.width,
        height: imgRef.current.height,
        markers: []
      }
      setMarkerState(mockToolMarkers);
    }

    if (imgUrlWithMarkers) {
      imgRef.current.src = imgUrlWithMarkers;
      setSavedImg(imgUrlWithMarkers);
    } else{
      setSavedImg(null);
    }

    if (imgRef && imgRef.current && (!markerArea || prevCid !== cid) ) {
      const newMarkerArea = new markerjs2.MarkerArea(imgRef.current);
      setMarkerArea(newMarkerArea);
    }

    setPrevCid(cid)

    return () => {
      setMarkerArea(null)
      setContextImgRef(null)
    }
  }, [cid, imgRef]);

  return { showMarkerArea };
};
