import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUserIDState } from '@/state/userState';
import {
  captureListState,
  currentCaptureInfoState,
} from '@/state/captureState';
import { pathState } from '@/state/appState';
import {
  getCaptureInfoSub,
  sendPostForInvite,
  updateShareInvite,
} from '@/lib/firebase/firestore';
import { firebaseConfig } from '@/lib/firebase/config';
import { useCaptureInfo } from '@/hooks/useCaptureInfo';
import { getUserWithEmail } from '@/lib/firebase/firestore/user';
import { User } from 'firebase/auth';
import { isArray } from 'lodash';

import Logo from '../assets/images/newlogo-white.svg';

type props = {
  message?: string;
  body?: string;
  heading?: string;
};
let unsub: Function | null = null;
let user: User[] | undefined = [];
const GrantAccess = (props?: props) => {
  const { uid, cid, email } = useParams() as {
    uid: string;
    cid: string;
    email: string;
  };
  const [isLoaded, setIsLoaded] = useState(false);
  const [search, setSearch] = useSearchParams();
  const [caputreInfo, setCaputreInfo] = useState<
    Capture.ShareModel | undefined
  >(undefined);

  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const { getCaptureInfoSub } = useCaptureInfo();
  useEffect(() => {
    unsub && unsub();
    unsub = getCaptureInfoSub(uid, cid);
    getUserWithEmail(email).then((found) => {
      // console.log(`User Found ${found}`);
      user = found;
      setIsLoaded(true);
    });
    // console.log(
    //   `Current Capture Information ${currentCaptureInfo?.projectName}`
    // );

    return () => {
      unsub && unsub();
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (
      uid !== '' &&
      cid !== '' &&
      email != '' &&
      currentCaptureInfo != undefined &&
      currentCaptureInfo !== null
    ) {
      updateShareInvite(email || '', cid, { IniviteStatus: 'ACCEPTED' }, uid);

      sendPostForInvite(
        [email],
        {
          name: currentCaptureInfo.creator.displayName || '',
          email: currentCaptureInfo.creator.email || '',
          uid: uid,
        },
        'Your request to access the Capture has been approved.',
        'VIEW',
        {
          projectName:
            currentCaptureInfo.projectName ||
            currentCaptureInfo.group ||
            'Untitled Project',
          capName: currentCaptureInfo.name || 'Untitled Capture',
          isRequestAccepted: true,
          gotToUrl: '',
          isGroupShare: false,
        }
      );
    }
  }, [currentCaptureInfo]);
  return (
    <>
      {isLoaded && (
        <>
          {' '}
          <link rel="stylesheet" type="text/css" href="/noAccess.css" />
          <div className="container">
            <img src="/404-background.png" alt="404" className="" />
            <div className="top-logo">
              <a href="/" target="_parent">
                <img src={Logo} alt="Instacap" />
              </a>
            </div>
            <div className="centered">
              <img src="/no-capture-img.png" alt="" className="four-img" />
            </div>
            <div
              className={`not-found-heading ${props?.message
                  ? 'not-found-heading-success'
                  : 'not-found-heading-grant-access'
                }`}
            >
              {props && props.message !== '' ? (
                <>
                  {' '}
                  <h4>{props.heading}</h4>
                  <p>{props.message}</p>
                </>
              ) : (
                currentCaptureInfo !== null && (
                  <div className="access-granted-container">
                    <>
                      <h4>Successfully Access Granted</h4>
                      <p>
                        {isArray(user) && user[user.length - 1]?.displayName}{' '}
                        access is updated to view <br /> access of the Capture "
                        {currentCaptureInfo?.name ||
                          currentCaptureInfo?.filename ||
                          'Untitled Capture'}
                        " in <br />"
                        {currentCaptureInfo?.projectName ||
                          currentCaptureInfo?.group}
                        " Project.
                        <br />
                        <br />
                        you can always change the access from the <br /> Share
                        Capture list
                      </p>
                      <p> </p>
                    </>
                  </div>
                )
              )}
            </div>

            {/* <div className="footer-logo-nf">
              <a target="_parent">
                <img src="/image/instacap-logo.png" alt="instacap-logo" />
              </a>
            </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default GrantAccess;
