import { FC, useEffect, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';

interface ZoomSliderProps {
  onZooming?: (zoomValue: number) => void;
  zoomValue: number;
}

const STEP = 1;
const MIN = 50;
const MAX = 200;

const ZoomSlider: FC<ZoomSliderProps> = ({ onZooming, zoomValue }) => {
  return (
    <div>
      <Range
        values={zoomValue < MIN ? [MIN] : [zoomValue]}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={(values) => onZooming(values[0])}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '32px',
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '6px',
                width: '100px',
                borderRadius: '100px',
                backgroundColor: '#FFFFF',
                border: '1px solid black',
                background: getTrackBackground({
                  values: [zoomValue],
                  colors: ['#000000', '#ffffff'],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '20px',
              width: '20px',
              borderRadius: '100px',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '1px 1px 0px #000000',
              background: '#FFFFFF',
              border: '1px solid #000000',
            }}
          >
            <div
              className="outline-none"
              style={{
                height: '16px',
                width: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: -20,
                left: -6,
                color: '#808080',
                fontSize: '12px',
              }}
            >
              {zoomValue < MIN ? MIN : zoomValue}%
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default ZoomSlider;
