import { atom } from 'recoil';

export const captureCountState = atom<number>({
  key: 'CaptureCount',
  default: 0,
});

export const currentCaptureState = atom<string>({
  key: 'CurrentCapture',
  default: '',
});

export const isLoadingCapture = atom<boolean>({
  key: 'isLoadingCapture',
  default: false,
});
