const ArrowDownShare = () => (
  <svg
    width="20"
    height="20"
    className="inline"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
    <path
      d="M12.3335 6L8.3335 10L4.3335 6"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ArrowDownShare;
