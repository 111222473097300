import CodeCanvas from '@/components/editor/canvas/codeCanvas';
import FloatingContainerForCodeEditor from '@/components/editor/floating/floatingContainerForCodeEditor';
import EditorNavbar from '@/components/editor/navbar/editorNav';
import { useCodeCanvas, useExport } from '@/hooks/useCodeEditor';
import { useColors } from '@/hooks/useScreenshotEditor';
import GradientPicker from './editor/LeftSidebarSection/gradientPicker';

const CodeToImage = () => {
  const { handleExport, containerRef, handleCopyImageToClipboard } =
    useExport();
  const {
    addGradientToColorPallet,
    gradientColors,
    handleAddColor,
    handleColorPickerChange,
    handleRemoveColor,
    setGradientColors,
  } = useColors();
  const { handleGradientDirectionChange } = useCodeCanvas();
  return (
    <div className="page-center-content">
      <EditorNavbar
        handleExport={handleExport}
        handleCopyImageToClipboard={handleCopyImageToClipboard}
        NewCodeEditorDropdownStatus={true}
      />

      <div className="">
        <div className="editor-container">
          <main className="editor-main">
            <div
              className=""
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                marginTop: '65px',
                marginLeft: '20px',
              }}
            >
              <CodeCanvas containerRef={containerRef} />
            </div>
          </main>
        </div>
        <div className="background-section border-r border-black ">
          <GradientPicker
            gradientColors={gradientColors}
            handleRemoveColor={handleRemoveColor}
            handleAddColor={handleAddColor}
            handleColorPickerChange={handleColorPickerChange}
            addGradientToColorPallet={addGradientToColorPallet}
            handleGradientDirectionChange={handleGradientDirectionChange}
          />
        </div>
        <div className="sidebar-section-editor border-l border-black">
          <FloatingContainerForCodeEditor handleExport={handleExport} />
        </div>
      </div>
    </div>
  );
};

export default CodeToImage;
