import { FC, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { keys, utils } from '@/lib';
import {
  captureRefState,
  currentCaptureInfoState,
  isProjectOpen,
} from '@/state/captureState';
import {
  canUpdateProjectNameState,
  currentUserIDState,
  currentUserInfoState,
} from '@/state/userState';
import { isAppLoadingState, pathState } from '@/state/appState';
import { isEditTitleProjectIsOpen, showMyProjectState } from '@/state/uiState';
import ImageRenderer from '../ImageRenderer';
import Dropdown from '../header/Dropdown';
import { User } from 'firebase/auth';
import { useCaptureInfo } from '@/hooks/useCaptureInfo';
import { useInvitation } from '@/hooks/useInvitation';
import PreviewModal from '../modal/PreviewModal';
import RenameCaptureModal from '../modal/RenameCaptureModal';
import Button from '../Button';
import { useGetUserSubscription } from '@/hooks/useGetUserSubscription';

const CaptureItem: FC<{
  info: Capture.Info;
  index: number;
  url: string;
  disabled?: boolean;
  isDeleted?: boolean;
  isExpired?: boolean;
}> = ({ info, index, disabled, isDeleted, isExpired }) => {
  const borderRef = useRef<HTMLDivElement | null>(null);
  const [preview, setPreview] = useState(false);
  const [isDropdownShow, setIsDropdownShow] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const appLoading = useRecoilValue(isAppLoadingState);
  const textareaEl = useRef<HTMLSpanElement | null>(null);
  const [isTooltip, setIsTooltip] = useState(false);
  const currentUserInfo = useRecoilValue(currentUserInfoState) as any;
  const [isEditTitleOpen, setEditTitleOpen] = useRecoilState(
    isEditTitleProjectIsOpen
  );
  const canUpdateProjectName = useRecoilValue(canUpdateProjectNameState);
  const { updatePorjectName, getCaptureInfoSub } = useCaptureInfo();
  const setCurrentCaptureInformation = useSetRecoilState(
    currentCaptureInfoState
  );
  // let isEditProjectAccess: boolean | undefined
  const { isProjectEditAccess } = useInvitation();
  const showMyProject = useRecoilValue(showMyProjectState);
  const [isEditProjectAccess, setIsEditProjectAccess] = useState<
    boolean | undefined
  >();
  const setCurrentpath = useSetRecoilState(pathState);
  const setCaptureRef = useSetRecoilState(captureRefState);
  const currentProject = useRecoilValue(isProjectOpen);

  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);

  const currentUserID = useRecoilValue(currentUserIDState);
  const { subscription } = useGetUserSubscription(currentUserID);

  useEffect(() => {
    const unsub = getCaptureInfoSub(info.creator.uid, info.cid);
    return () => {
      unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.cid, info.creator.uid]);

  // projectName = projectName.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

  //@ts-ignore
  utils.special[keys['backspace']] = true;
  //@ts-ignore
  utils.special[keys['shift']] = true;
  //@ts-ignore
  utils.special[keys['ctrl']] = true;
  //@ts-ignore
  utils.special[keys['alt']] = true;
  //@ts-ignore
  utils.special[keys['delete']] = true;
  //@ts-ignore
  utils.navigational[keys['upArrow']] = true;
  //@ts-ignore
  utils.navigational[keys['downArrow']] = true;
  //@ts-ignore
  utils.navigational[keys['leftArrow']] = true;
  //@ts-ignore
  utils.navigational[keys['rightArrow']] = true;

  const clickOutside = (e: Event) => {
    // inside clicku
    if (ref.current && ref?.current.contains(e.target as Node)) return false;

    // outside click && already opened
    if (isDropdownShow) return handleClickTripleDots(e);
  };
  const clickOutsideTitle = async (e: Event) => {
    // inside clicku
    if (textareaEl.current && textareaEl?.current.contains(e.target as Node))
      return false;

    // outside click && already opened
    if (isEditTitleOpen.open) return await closeIsEditTitleIsOpen();
  };

  const closeIsEditTitleIsOpen = async () => {
    // setIsAppLoading(true)
    // console.log(`Click Out Side from Edit Text`)

    if (
      textareaEl.current &&
      info.projectName !== textareaEl.current.innerText
    ) {
      let projectName = textareaEl.current.innerText.trimEnd();
      // if (projectName === info.group) {
      //   textareaEl.current.innerText = info.group;
      //   return
      // }
      // console.log(`Project Name While Saving With Out Enter:${projectName}`);
      // console.log(
      //   `Previous Project Name in Information properties:${info.projectName}`
      // );
      if (projectName === '') {
        textareaEl.current.innerText = 'Untitled Project';
        // return
      } else {
        textareaEl.current.innerText = projectName;
      }
      await updatePorjectName(info.creator.uid, info.group, {
        projectName,
      });

      // setIsAppLoading(false)
    }
    setEditTitleOpen((old) => ({
      open: false,
      index: -1,
    }));
  };

  useEffect(() => {
    isProjectEditAccess(
      info.creator.uid,
      info.group,
      showMyProject,
      info.cid
    ).then((ok) => {
      setIsEditProjectAccess(ok);
    });
  }, [info.cid]);

  useEffect(() => {
    if (isDropdownShow) {
      setCurrentpath({
        cid: info.cid,
        uid: info.creator.uid,
        gid: info.group,
      });
      setCurrentCaptureInformation(info);
    } else {
      // setCurrentpath({
      //   cid: '',
      //   uid: '',
      //   gid: ''
      // })
      // setCurrentCaptureInformation(undefined)
    }
    document.addEventListener('mousedown', clickOutside);

    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownShow]);
  useEffect(() => {
    if (
      textareaEl.current &&
      isEditTitleOpen.open === true &&
      isEditTitleOpen.index === index
    ) {
      // console.log(`Edit Title Open Index Number is ${isEditTitleOpen.index} Index ${index}`)
      const selection = window.getSelection();
      const range = document.createRange();
      selection?.removeAllRanges();
      range.selectNodeContents(textareaEl.current);
      range.collapse(false);
      selection?.addRange(range);
      textareaEl.current.focus();
    }
    document.addEventListener('mousedown', clickOutsideTitle);

    return () => {
      document.removeEventListener('mousedown', clickOutsideTitle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditTitleOpen.open]);

  const handleClickTripleDots = (event: any) => {
    setIsTooltip(false);
    event.stopPropagation();
    event.preventDefault();

    setIsDropdownShow((isDropdownShow) => !isDropdownShow);
  };

  let max = 15;
  const handleKeyUp = async (event: any) => {
    // setIsAppLoading(true)
    if (!canUpdateProjectName) return false;

    const el = event.currentTarget;
    let hasSelection = false;
    let len = event.currentTarget.innerText.trim().length;
    let selection = window.getSelection();
    let isSpecial = utils.isSpecial(event);
    let isNavigational = utils.isNavigational(event);
    if (len > max) {
      setIsTooltip(true);
    } else {
      // console.log(`Show tool Tip....false`);
      setIsTooltip(false);
    }

    if (selection) {
      hasSelection = !!selection.toString();
    }

    if (isSpecial || isNavigational) {
      return true;
    }
    if (len > max && !hasSelection && event.key !== 'Enter') {
      event.preventDefault();
      return false;
    }

    let projectName = el.innerText.trim();
    if (event.key === 'Enter') {
      setEditTitleOpen({ open: false, index: -1 });
      if (projectName === '') {
        el.innerText = 'Untitled Project';
        // return
      } else {
        el.innerText = projectName;
      }
      await updatePorjectName(info.creator.uid, info.group, {
        projectName,
      });
    }
  };
  useEffect(() => {
    if (preview) {
      setCurrentCaptureInformation(info);
    }
  }, [preview]);
  // const borderEl = borderRef.current;

  useEffect(() => {
    if (borderRef !== null && borderRef.current) {
      // @ts-ignore
      setCaptureRef(borderRef);
    }
  }, [borderRef, setCaptureRef]);

  return (
    // <Link to={`/${info.creator.uid}/${info.cid}`}>
    <div id={"d-" + info.cid} className={`relative ${disabled || isExpired ? 'opacity-25' : ''}`}>
      {info?.expiresInXDays && subscription === "free" && currentUserInfo?.tier !=="1" && (info?.expiresInXDays ?? 0) < 8 && isExpired != true &&
        <div className='absolute z-10 -top-3 w-full	flex justify-center	'>
          <Button
            label={`Expires ${(info?.expiresInXDays ?? 0) > 1 ? `in ${info.expiresInXDays} days` : ((info?.expiresInXDays ?? 0) === 1 ? "tommorow" : "today")}`}
            style={{ textWrap: 'nowrap', height: 24 }}
            backgroundColor="red"
            textColor="white"
            className="text-xs font-semibold px-3 py-2.5 gap-2"
            childrenLeft
          />
        </div>
      }
      <div
        className={`px-6 py-4 bg-white flex-col justify-items-center border rounded-xl cursor-pointer hover:green-updated sm:max-w-none relative hover:shadow-sm`}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          if (disabled || isDeleted || isExpired) return;
          setCurrentpath({
            uid: info.creator.uid,
            cid: info.cid,
            gid: '',
          });
          setPreview(true);
        }}
      >
        <a href={`/${info.creator.uid}/${info.cid}`}>
          <div className="flex items-center mb-2">
            <div
              className="relative flex flex-row justify-start items-center"
              onMouseEnter={() => {
                if (!canUpdateProjectName) return false;
                // setIsTooltip(true);
              }}
              onMouseLeave={() => {
                if (!canUpdateProjectName) return false;
                // setIsTooltip(false);
              }}
            >
              <span
                onClick={(event: any) => {
                  // event.stopPropagation()
                  // event.preventDefault()
                  // if (!canUpdateProjectName) return false
                  if (isEditTitleOpen.open && index === isEditTitleOpen.index) {
                    setEditTitleOpen({ open: true, index: index });
                  }
                }}
                contentEditable={
                  isEditTitleOpen.open && isEditTitleOpen.index === index
                    ? true
                    : false
                }
                onKeyPress={handleKeyUp}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    setIsTooltip(false);
                  }
                }}
                ref={textareaEl}
                tabIndex={0}
                suppressContentEditableWarning={true}
                defaultValue={''}
                className={`mt-1  truncate ... max-w-40  font-poppins text-sm	 font-black
                    has-tooltip
  
                       ${isEditTitleOpen.open === true &&
                    isEditTitleOpen.index === index
                    ? 'sidebar-title-input cursor-auto border-red-span-input cursor-auto'
                    : 'cursor-default transparent-border'
                  }
                    `}
              >
                {info.name}
              </span>
              <div
                className="tooltip-edit tooltip-box border-2 border-white absolute whitespace-nowrap shadow-2xl font-normal tracking-wider text-white bg-black bg-opacity-70 px-2 py-1 text-xxxxs rounded-tl-md rounded-tr-2xl rounded-br-2xl rounded-bl-2xl"
                style={{
                  display:
                    !currentUserInfo.isAnonymous &&
                      isTooltip &&
                      isEditTitleOpen.open &&
                      isEditTitleOpen.index === index
                      ? ''
                      : 'none',
                }}
              >
                Max-Limit-reached
              </div>
            </div>

            {!appLoading && isEditProjectAccess && !disabled && (
              <div
                className={`ml-auto text-bold relative ${isDropdownShow ? 'three-dot-sidebar-hover' : ''
                  }`}
                ref={ref}
                tabIndex={0}
                onClick={handleClickTripleDots}
              >
                <Dropdown
                  isDropdownShow={isDropdownShow}
                  isDashboard={true}
                  renameModal={() => setIsRenameModalOpen(true)}
                  isDeleted={isDeleted}
                  cid={info.cid}
                />
                {
                  <svg
                    className="three-dot-sidebar w-4 h-4"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 0L0 0L0 16L16 16L16 0Z"
                      fill="white"
                      fillOpacity="0.01"
                    />
                    <path
                      d="M8 5C8.55228 5 9 4.55228 9 4C9 3.44772 8.55228 3 8 3C7.44772 3 7 3.44772 7 4C7 4.55228 7.44772 5 8 5Z"
                      fill="black"
                    />
                    <path
                      d="M8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9Z"
                      fill="black"
                    />
                    <path
                      d="M8 12.6665C8.55228 12.6665 9 12.2188 9 11.6665C9 11.1142 8.55228 10.6665 8 10.6665C7.44772 10.6665 7 11.1142 7 11.6665C7 12.2188 7.44772 12.6665 8 12.6665Z"
                      fill="black"
                    />
                  </svg>
                }
              </div>
            )}
          </div>
          {/* Image */}
          <div
            // onClick={handleClick}
            className={`overflow-hidden grid-capture-img h-24 w-3/4 m-auto flex justify-center items-center rounded-lg bg-gray-400 border-2 border-solid border-gray-100 sidebar-img`}
          >
            <ImageRenderer
              className="object-cover w-full mx-auto bg-gray-200 "
              cid={info.cid}
              src={info.url}
              key={info.url}
              alt=""
            />

            {/* <div className={`absolute right-16 top-11`}>
                <NotificationDot />
              </div> */}
          </div>

          <div className="truncate ... w-full py-2">
            <div className="flex flex-row justify-center items-center truncate ... comments-sidebar-section">
              <div className="coment-text pink-txt">
                {info.comments ? info.comments?.length : 0} Comments
              </div>
              <div className="sidebar-comment-time">
                &nbsp;<span className="sidebar-dot">.</span>{' '}
                {dayjs
                  .unix(info.createdAt)
                  .fromNow()
                  .replaceAll('minutes', 'mins').length > 11
                  ? dayjs
                    .unix(info.createdAt)
                    .fromNow()
                    .replaceAll('minutes', 'mins')
                    .slice(0, 11) + '...'
                  : dayjs
                    .unix(info.createdAt)
                    .fromNow()
                    .replaceAll('minutes', 'mins')}
              </div>
            </div>
          </div>
        </a>
      </div>
      <PreviewModal
        isOpen={preview}
        closeModal={() => setPreview(false)}
        info={info}
      />
      <RenameCaptureModal
        isOpen={isRenameModalOpen}
        info={info}
        closeModal={() => setIsRenameModalOpen(false)}
      />
    </div>
    // </Link>
  );
};

export default CaptureItem;
