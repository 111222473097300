import { FC, useEffect, useState } from 'react';

import Template from './Template';
import { Dialog } from '@headlessui/react';
import CircleClose from '../icon/CircleClose';
import { Project } from 'types/project';
import ArrowUpShare from '../icon/ArrowUpShare';
import ArrowDown from '../icon/ArrowDown';
import { useRecoilValue } from 'recoil';
import { projectListState } from '@/state/projectState';
import { useCaptureInfo } from '@/hooks/useCaptureInfo';
import { currentUserIDState } from '@/state/userState';
import { useOrganization } from '@clerk/clerk-react';

interface MoveCaptureModalProps {
  isOpen: boolean;
  closeModal: () => void;
  cid: string;
  gid: string;
}

const MoveCaptureModal: FC<MoveCaptureModalProps> = ({
  isOpen,
  closeModal,
  cid,
  gid,
}) => {
  const [showList, setShowList] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  const projectList = useRecoilValue(projectListState);
  const currentUserID = useRecoilValue(currentUserIDState);
  const { organization } = useOrganization()

  const { updateCaptureInfo } = useCaptureInfo();

  useEffect(() => {
    const currentProject = projectList.find((p) => p.gid === gid);
    if (currentProject) {
      setSelectedProject(currentProject);
    } else {
      setSelectedProject(null);
    }
  }, [gid, projectList, isOpen]);

  const onSubmit = () => {
    const userId = organization ? organization.id : currentUserID;
    // const userId = currentUserID;

    updateCaptureInfo(userId, cid, {
      group: selectedProject?.gid ?? null,
      projectName: selectedProject?.name ?? '',
    });
    closeModal();
  };

  return (
    <Template isOpen={isOpen} closeModal={closeModal}>
      <div
        className={`inline-block w-full max-w-xl overflow-visible text-left align-middle transition-all transform rounded-xl bg-white py-6 sm:mt-6 default-modal`}
      >
        <Dialog.Title as="h3" className="text-2xl text-gray-900 leading-8 px-6">
          <div className="flex mb-6">
            <div className="w-3/4 flex flex-col">
              <h4 className="fileupload-modal-heading">Move Captures</h4>
              <span className="text-xs">Organize your captures.</span>
            </div>
            <div className="w-1/4 float-right" onClick={closeModal}>
              <div className="float-right mb-5">
                <div className="flex items-center cursor-pointer">
                  <CircleClose />
                </div>
              </div>
            </div>
          </div>
        </Dialog.Title>
        <div className="w-full h-px opacity-10 bg-black" />
        <div className="w-full flex flex-col items-center px-6 pt-6 md:pt-24">
          <div className="relative w-full">
            <div
              className="flex items-center justify-between py-2 px-5 w-full text-sm text-gray-900 bg-gray-50 rounded-3xl border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onClick={() => setShowList((prev) => !prev)}
            >
              {selectedProject ? selectedProject.name : 'Non-Project Captures'}
              {showList ? (
                <ArrowUpShare width={20} height={20} />
              ) : (
                <ArrowDown width={20} height={20} />
              )}
            </div>
            {showList && (
              <div className="absolute left-0 top-10 max-h-72 overflow-auto w-full bg-white border border-solid border-black divide-gray-100 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <ul className="py-2">
                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      setSelectedProject(null);
                      setShowList(false);
                    }}
                  >
                    <button className="text-black font-medium px-5 py-2 h-12 group flex items-center w-full text-sm hover:bg-grey-200 dropdown-item sider-bar-text">
                      Non-Project Captures
                    </button>
                  </li>
                  {projectList.map((project) => (
                    <li
                      key={project.gid}
                      className="cursor-pointer"
                      onClick={() => {
                        setSelectedProject(project);
                        setShowList(false);
                      }}
                    >
                      <button className="text-black font-medium px-5 py-2 h-12 group flex items-center w-full text-sm hover:bg-grey-200 dropdown-item sider-bar-text">
                        {project.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="w-full flex items-center mt-8 gap-4 justify-end">
            <button
              className="font-semibold sm:hidden hover:opacity-60 border border-black border-solid py-2 w-28 rounded-3xl"
              onClick={closeModal}
              style={{ boxShadow: '1px 1px 0px #000000' }}
            >
              Cancel
            </button>
            <button
              className={`h-10 w-28 text-white rounded-lg justify-center upload-default upload-success`}
              onClick={onSubmit}
            >
              Move
            </button>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default MoveCaptureModal;
