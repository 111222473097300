import { FC } from 'react';

interface props {
  width?: number;
  height?: number;
  opacity?: number;
}

const CreateCaptureIcon: FC<props> = ({
  width = 16,
  height = 16,
  opacity = 1,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      opacity={opacity}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13 2H3C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2Z"
        stroke="black"
        stroke-width="1.33333"
        stroke-linejoin="round"
      />
      <path
        d="M2 9.33344L5.56437 6.0661C5.81297 5.8382 6.19263 5.83187 6.4487 6.05137L10.6667 9.66677"
        stroke="black"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.33325 8.00004L10.9244 6.40887C11.159 6.1743 11.5304 6.1479 11.7958 6.34697L13.9999 8.00004"
        stroke="black"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 6.66675V10.0001"
        stroke="black"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 6.66675V10.0001"
        stroke="black"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CreateCaptureIcon;
