import React from 'react';
import { Switch as HeadlessSwitch, Listbox } from '@headlessui/react';
import Switch from 'react-switch';

import { useCodeCanvas, useColors } from '@/hooks/useCodeEditor';
import GradientPicker from '../LeftSidebarSection/gradientPicker';
import CustomSlider from '../slider/customSlider';
import { languages, themes } from 'helpers/options';
import { cn } from '@/lib/utils';
import { MoveDown, MoveLeft, MoveRight, MoveUp } from 'lucide-react';

const FloatingContainerForCodeEditor = ({ handleExport }: any) => {
  const {
    backgroundToggleDispatch,
    background,
    darkmodeToggleDispatch,
    darkMode,
    padding,
    setPaddingDispatch,
    theme,
    language,
    setLanguageDispatch,
    fontSize,
    setFontSizeDispatch,
    watermark,
    setWatermarkDispatch,
    gradientDirection,
    handleGradientDirectionChange,
    handleRotateX,
    handleRotateY,
    threeD,
  } = useCodeCanvas();

  const handleRotateLeft = () => {
    handleRotateY(threeD.rotateY - 10);
  };

  const handleRotateRight = () => {
    handleRotateY(threeD.rotateY + 10);
  };

  const handleRotateUp = () => {
    handleRotateX(threeD.rotateX + 10);
  };

  const handleRotateDown = () => {
    handleRotateX(threeD.rotateX - 10);
  };
  return (
    <div className="flex flex-col px-4 border border-t-0 border-b-0 border-slate-800 h-screen w-full md:w-[400px] bg-[#191922] no-scrollbar bg-white">
      <label
        className="text-[15px] font-semibold text-black mr-2 mt-4 md:mt-[60px]"
        htmlFor="airplane-mode"
      >
        Control Panel
      </label>
      <div className="mt-4">
        <div className="mt-2">
          <CustomSlider
            label="Padding"
            value={padding}
            onChange={setPaddingDispatch}
            min={20}
            max={200}
            step={1}
          />
        </div>
        <div className="">
          <CustomSlider
            label="Font Size"
            value={fontSize}
            onChange={setFontSizeDispatch}
            min={10}
            max={28}
            step={1}
          />
        </div>
        <div className="flex my-3 relative z-30 bg-white">
          <label className="text-xs flex items-center font-medium text-black w-full mb-2">
            Language
          </label>
          <Listbox value={language} onChange={setLanguageDispatch}>
            <Listbox.Button className="w-40 border border-black rounded-md">
              {language ? language : 'Select Theme'}
            </Listbox.Button>
            <Listbox.Options className="dark absolute bg-white px-3 w-full border border-black">
              {Object.entries(languages).map(([name, theme]) => (
                <Listbox.Option key={name} value={name}>
                  <div className="flex gap-2 items-center">
                    <span className="capitalize">{name}</span>
                  </div>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
        <div className="bottom-card  p-3 mt-6">
          <div className="flex flex-row items-center justify-between w-full mt-3">
            <h1 className="text-xs flex items-center  font-medium  text-black w-full mb-2">
              3D Rotation
            </h1>
          </div>
          <div className=" mt-4 flex flex-row justify-center items-center  rounded-2xl ">
            <button
              className="bg-white border border-black rounded-md p-3 m-1 cursor-pointer flex justify-center items-center "
              onClick={handleRotateLeft}
            >
              <MoveLeft size={8} />
            </button>

            <div className="flex flex-col justify-center items-center">
              <button
                className="bg-white border border-black rounded-md p-3 m-1 cursor-pointer flex justify-center items-center "
                onClick={handleRotateUp}
              >
                <MoveUp size={8} />
              </button>

              <button
                className="bg-white border border-black rounded-md p-3 m-1 cursor-pointer flex justify-center items-center "
                onClick={handleRotateDown}
              >
                <MoveDown size={8} />
              </button>
            </div>

            <button
              className="bg-white border border-black rounded-md p-3 m-1 cursor-pointer flex justify-center items-center "
              onClick={handleRotateRight}
            >
              <MoveRight size={8} />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mt-4 md:gap-0 md:mt-0">
          <div className="flex flex-col items-start justify-start">
            <label
              className="text-[12px] font-medium text-black mr-2"
              htmlFor="airplane-mode"
            >
              Background
            </label>
            <div className="mt-2">
              <Switch
                onChange={backgroundToggleDispatch}
                checked={background}
                offColor="#767577"
                onColor="#000000"
                offHandleColor="#f4f3f4"
                onHandleColor="#f4f3f4"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={10}
                width={48}
                className="react-switch"
              />
            </div>
          </div>
          <div className="flex flex-col items-start justify-start">
            <label
              className="text-[12px] font-medium text-black mr-2"
              htmlFor="airplane-mode"
            >
              Dark mode
            </label>
            <div className="mt-2">
              <Switch
                onChange={darkmodeToggleDispatch}
                checked={darkMode}
                offColor="#767577"
                onColor="#000000"
                offHandleColor="#f4f3f4"
                onHandleColor="#f4f3f4"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={10}
                width={48}
                className="react-switch"
              />
            </div>
          </div>
          <div className="flex flex-col items-start justify-start">
            <label
              className="text-[12px] font-medium text-black mr-2"
              htmlFor="airplane-mode"
            >
              Watermark
            </label>
            <div className="mt-2">
              <Switch
                onChange={() => {
                  setWatermarkDispatch(!watermark);
                }}
                checked={watermark}
                offColor="#767577"
                onColor="#000000"
                offHandleColor="#f4f3f4"
                onHandleColor="#f4f3f4"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={10}
                width={48}
                className="react-switch"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloatingContainerForCodeEditor;
