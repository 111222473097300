import { Fragment, FC } from 'react';
import { Dialog, Transition } from '@headlessui/react';

type Params = {
  isOpen: boolean;
  closeModal: () => void;
};

const Template: FC<Params> = ({ isOpen, closeModal, children }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="flex items-center justify-center min-h-screen text-center">
          <Dialog.Overlay className="fixed inset-0 custom-modal-bg" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen " aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            // enter="ease-out duration-1000"
            // enterFrom="opacity-0 scale-25"
            // enterTo="opacity-100 scale-100"
            // leave="ease-in duration-1000"
            // leaveFrom="opacity-100 scale-100"
            // leaveTo="opacity-50 scale-25"
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Template;
