const EmptyComments = () => (
    <svg width="100" height="86" viewBox="0 0 100 86" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M93.1131 51.0147C94.4643 48.4407 95.2381 45.5143 95.2381 42.4028C95.2381 34.4944 90.2869 27.7625 83.3321 25.1096C83.3321 25.1012 83.3333 25.0929 83.3333 25.0833C83.3333 11.2302 72.1405 0 58.3333 0C46.6857 0 36.9274 8.00397 34.1512 18.8209C32.394 18.2416 30.5214 17.9167 28.5714 17.9167C19.4143 17.9167 11.8774 24.8361 10.8452 33.7454C4.65 35.0605 0 40.5741 0 47.1806C0 54.7665 6.12976 60.9167 13.6905 60.9167C13.9012 60.9167 14.1095 60.9023 14.3167 60.8904C14.3119 61.0994 14.2857 61.3037 14.2857 61.5139C14.2857 75.0374 25.2119 86 38.6905 86C46.3536 86 53.1845 82.4501 57.6595 76.9055C60.2524 79.5631 63.8571 81.2222 67.8571 81.2222C73.3929 81.2222 78.1833 78.0569 80.5548 73.4368C81.8071 73.837 83.1393 74.0556 84.5238 74.0556C91.756 74.0556 97.619 68.1729 97.619 60.9167C97.619 56.9619 95.869 53.4239 93.1131 51.0147Z" fill="#ABFF57" />
        <path d="M72.768 73.4581C72.768 73.4581 68.4346 73.4581 65.4763 73.4581C62.518 73.4581 60.1191 71.0513 60.1191 68.0831C60.1191 65.2965 62.2406 63.0318 64.9465 62.7619C64.9203 62.5457 64.881 62.3343 64.881 62.1109C64.881 59.1427 67.2799 56.7359 70.2382 56.7359C71.9501 56.7359 73.456 57.5553 74.437 58.8095C74.6037 55.6609 77.1775 53.1526 80.3572 53.1526C83.6441 53.1526 86.3096 55.8269 86.3096 59.1248C86.3096 59.6575 86.218 60.164 86.087 60.6561C86.8513 59.728 87.9918 59.1248 89.2858 59.1248C91.406 59.1248 93.1358 60.7194 93.3989 62.7738C93.6144 62.7476 93.8251 62.7081 94.0477 62.7081C97.006 62.7081 99.4049 65.115 99.4049 68.0831C99.4049 71.0513 97.006 73.4581 94.0477 73.4581C91.0894 73.4581 82.7382 73.4581 82.7382 73.4581H76.3394V74.0554H72.768V73.4581Z" fill="white" />
        <path d="M80.3573 52.5554C83.968 52.5554 86.9049 55.5021 86.9049 59.1249C86.9049 59.144 86.9049 59.1619 86.9049 59.181C87.6215 58.757 88.4406 58.5276 89.2858 58.5276C91.4656 58.5276 93.3465 60.0338 93.8787 62.1169C93.9346 62.1134 93.9906 62.111 94.0477 62.111C97.3299 62.111 100 64.7901 100 68.0832C100 71.3763 97.3299 74.0554 94.0477 74.0554H76.3394C76.0108 74.0554 75.7442 73.7879 75.7442 73.4582C75.7442 73.1285 76.0108 72.861 76.3394 72.861H94.0477C96.6739 72.861 98.8096 70.7181 98.8096 68.0832C98.8096 65.4483 96.6739 63.3054 94.0477 63.3054C93.9085 63.3054 93.7739 63.3257 93.6394 63.3436L93.4715 63.3663C93.4465 63.3699 93.4227 63.3711 93.3977 63.3711C93.1049 63.3711 92.8477 63.1501 92.8084 62.8491C92.5811 61.0658 91.0668 59.7221 89.2858 59.7221C88.2323 59.7221 87.2346 60.2011 86.5465 61.036C86.4299 61.1769 86.2596 61.2534 86.087 61.2534C85.9942 61.2534 85.9013 61.2319 85.8156 61.1865C85.5668 61.0587 85.4418 60.7732 85.5132 60.5021C85.6501 59.9825 85.7144 59.5453 85.7144 59.1249C85.7144 56.1614 83.3108 53.7499 80.3573 53.7499C77.5227 53.7499 75.1823 55.9859 75.0311 58.8418C75.018 59.0902 74.8525 59.304 74.6156 59.3793C74.5561 59.3984 74.4954 59.4079 74.4346 59.4079C74.2561 59.4079 74.0823 59.3255 73.968 59.1786C73.0501 58.0057 71.6918 57.3332 70.2382 57.3332C67.612 57.3332 65.4763 59.476 65.4763 62.111C65.4763 62.2507 65.4965 62.3857 65.5144 62.5207L65.537 62.6891C65.5561 62.8491 65.5108 63.0092 65.4108 63.1346C65.3108 63.26 65.1644 63.3401 65.0061 63.3556C62.5584 63.6004 60.7144 65.6334 60.7144 68.0832C60.7144 70.7181 62.8501 72.861 65.4763 72.861H82.2918C82.6204 72.861 82.887 73.1285 82.887 73.4582C82.887 73.7879 82.6204 74.0554 82.2918 74.0554H65.4763C62.1942 74.0554 59.5239 71.3763 59.5239 68.0832C59.5239 65.2201 61.5346 62.8121 64.2894 62.2579C64.287 62.2089 64.2858 62.1599 64.2858 62.111C64.2858 58.8179 66.9561 56.1388 70.2382 56.1388C71.6382 56.1388 72.968 56.6356 74.0275 57.5291C74.7394 54.6576 77.3156 52.5554 80.3573 52.5554Z" fill="black" />
        <path d="M17.8569 45.77C17.851 53.0967 22.3677 60.0269 30.1331 64.6064C30.1331 68.5695 28.9784 72.9579 27.5355 75.747C27.0224 76.7384 27.8296 77.8767 28.9367 77.7548C36.1034 76.9665 42.8938 73.4238 46.5022 69.8798H50.595C68.676 69.8798 83.3331 59.0857 83.3331 45.77C83.3331 32.4543 68.676 21.6589 50.595 21.6589C32.5141 21.6589 17.8569 32.4543 17.8569 45.77Z" fill="#F7F5EE" />
        <path d="M28.7782 78.5993C28.0425 78.5993 27.3508 78.2397 26.9306 77.627C26.4651 76.9449 26.4151 76.0981 26.7973 75.3623C28.2282 72.5936 29.2104 68.5193 29.2949 65.0793C21.483 60.3231 17.0175 53.3093 17.0234 45.7699C17.0234 32.0147 32.083 20.824 50.5949 20.824C69.1068 20.824 84.1663 32.0147 84.1663 45.7711C84.1663 59.5276 69.1068 70.7159 50.5949 70.7159H46.8377C43.2699 74.0854 36.4746 77.7667 29.0282 78.5861C28.9437 78.5945 28.8603 78.5993 28.7782 78.5993ZM50.5949 22.495C33.0032 22.495 18.6901 32.9356 18.6901 45.7688C18.6901 45.7688 18.6901 45.7688 18.6901 45.7699C18.6842 52.8315 23.0092 59.4344 30.5556 63.8849C30.8103 64.0354 30.9675 64.3089 30.9675 64.6063C30.9675 68.4441 29.9103 72.9686 28.2758 76.1315C28.1473 76.3812 28.2401 76.583 28.3056 76.681C28.3639 76.7646 28.5211 76.9545 28.8449 76.9235C36.0734 76.1291 42.6282 72.5136 45.9199 69.2826C46.0758 69.1297 46.2854 69.0437 46.502 69.0437H50.5949C68.1865 69.0437 82.4996 58.6031 82.4996 45.7699C82.4996 32.9368 68.1865 22.495 50.5949 22.495Z" fill="black" />
        <path d="M30.3573 40.0136C30.3573 40.0136 32.2228 40.0136 34.5239 40.0136C36.8251 40.0136 38.6906 38.1419 38.6906 35.8331C38.6906 33.7058 37.1013 31.9702 35.0537 31.7063C35.087 31.4924 35.1192 31.2786 35.1192 31.0553C35.1192 28.7464 33.2537 26.8747 30.9525 26.8747C29.7239 26.8747 28.6311 27.4182 27.8692 28.2663C27.412 26.1067 25.5037 24.4858 23.2144 24.4858C20.5847 24.4858 18.4525 26.6251 18.4525 29.2636C18.4525 29.4918 18.4882 29.7103 18.5192 29.9301C18.0097 29.5192 17.3716 29.2636 16.6668 29.2636C15.2049 29.2636 13.9942 30.3231 13.7442 31.7182C13.5311 31.6848 13.318 31.6525 13.0954 31.6525C10.7942 31.6525 8.92871 33.5242 8.92871 35.8331C8.92871 38.1419 10.7942 40.0136 13.0954 40.0136C15.3966 40.0136 22.0239 40.0136 22.0239 40.0136V40.6108H30.3573V40.0136Z" fill="white" />
        <path d="M34.524 40.6111H30.3573C30.0287 40.6111 29.7621 40.3436 29.7621 40.0139C29.7621 39.6842 30.0287 39.4167 30.3573 39.4167H34.524C36.493 39.4167 38.0954 37.809 38.0954 35.8334C38.0954 34.0465 36.7561 32.5271 34.9787 32.299C34.8192 32.2775 34.6752 32.1939 34.5787 32.0649C34.4823 31.9359 34.4418 31.7734 34.4668 31.6146C34.4942 31.4306 34.524 31.2467 34.524 31.0556C34.524 29.08 32.9216 27.4722 30.9525 27.4722C29.9406 27.4722 29.0025 27.8963 28.3109 28.6667C28.1621 28.8327 27.9287 28.9032 27.7156 28.8435C27.499 28.7849 27.3335 28.6106 27.2871 28.3908C26.8811 26.4749 25.168 25.0834 23.2144 25.0834C20.9168 25.0834 19.0478 26.9586 19.0478 29.2639C19.0478 29.4347 19.0728 29.5984 19.0966 29.7632C19.1311 30.0057 19.0216 30.2864 18.8085 30.4082C18.5954 30.53 18.3371 30.5491 18.1466 30.3963C17.7121 30.0463 17.2014 29.8611 16.6668 29.8611C15.5168 29.8611 14.5335 30.6865 14.3299 31.8248C14.2728 32.1437 13.9775 32.3575 13.6525 32.3086C13.4692 32.2799 13.2859 32.25 13.0954 32.25C11.1264 32.25 9.52397 33.8577 9.52397 35.8334C9.52397 37.809 11.1264 39.4167 13.0954 39.4167H31.5478C31.8764 39.4167 32.143 39.6842 32.143 40.0139C32.143 40.3436 31.8764 40.6111 31.5478 40.6111H13.0954C10.4692 40.6111 8.3335 38.4683 8.3335 35.8334C8.3335 33.1984 10.4692 31.0556 13.0954 31.0556C13.1656 31.0556 13.2335 31.058 13.3025 31.0627C13.7954 29.6461 15.1323 28.6667 16.6668 28.6667C17.0823 28.6667 17.4871 28.7396 17.8704 28.8817C18.0668 26.0951 20.3883 23.8889 23.2144 23.8889C25.3942 23.8889 27.3406 25.2351 28.1585 27.2023C28.968 26.6075 29.9466 26.2778 30.9525 26.2778C33.5787 26.2778 35.7144 28.4206 35.7144 31.0556C35.7144 31.1129 35.7133 31.1691 35.7097 31.2252C37.7847 31.7603 39.2859 33.6463 39.2859 35.8334C39.2859 38.4683 37.1502 40.6111 34.524 40.6111Z" fill="black" />
        <path d="M41.0714 43.5969C40.4399 43.5969 39.8343 43.8486 39.3878 44.2966C38.9413 44.7446 38.6904 45.3522 38.6904 45.9858C38.6904 46.6194 38.9413 47.227 39.3878 47.675C39.8343 48.123 40.4399 48.3747 41.0714 48.3747C41.7028 48.3747 42.3085 48.123 42.755 47.675C43.2015 47.227 43.4523 46.6194 43.4523 45.9858C43.4523 45.3522 43.2015 44.7446 42.755 44.2966C42.3085 43.8486 41.7028 43.5969 41.0714 43.5969Z" fill="#FF38A4" />
        <path d="M50.5953 43.5969C49.9638 43.5969 49.3582 43.8486 48.9117 44.2966C48.4652 44.7446 48.2144 45.3522 48.2144 45.9858C48.2144 46.6194 48.4652 47.227 48.9117 47.675C49.3582 48.123 49.9638 48.3747 50.5953 48.3747C51.2268 48.3747 51.8324 48.123 52.2789 47.675C52.7254 47.227 52.9763 46.6194 52.9763 45.9858C52.9763 45.3522 52.7254 44.7446 52.2789 44.2966C51.8324 43.8486 51.2268 43.5969 50.5953 43.5969Z" fill="#FF38A4" />
        <path d="M60.1192 43.5969C59.4878 43.5969 58.8822 43.8486 58.4356 44.2966C57.9891 44.7446 57.7383 45.3522 57.7383 45.9858C57.7383 46.6194 57.9891 47.227 58.4356 47.675C58.8822 48.123 59.4878 48.3747 60.1192 48.3747C60.7507 48.3747 61.3563 48.123 61.8028 47.675C62.2493 47.227 62.5002 46.6194 62.5002 45.9858C62.5002 45.3522 62.2493 44.7446 61.8028 44.2966C61.3563 43.8486 60.7507 43.5969 60.1192 43.5969Z" fill="#FF38A4" />
    </svg>
);
export default EmptyComments;
