import { OAuthCredential, UserCredential } from 'firebase/auth';

export enum LocalStorageItems {
  GOOGLE_ACCOUNTS = 'INSTACAP_GOOGLE_ACCOUNTS_CREDENTIALS',
}

export type GoogleAccountsStorageType = {
  userCredential: UserCredential;
  authUserCredential: OAuthCredential | null;
};
