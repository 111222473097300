import React, { createContext, useContext, useState } from 'react';
import * as markerjs2 from 'markerjs2';

// Define a type for the context value
interface MarkerContextType {
  markerArea: any;
  setMarkerArea: React.Dispatch<any>;
  contextImgRef: any;
  setContextImgRef: React.Dispatch<any>;
  contextCaptureInfoHeight: any;
  setContextCaptureInfoHeight: React.Dispatch<any>;
  showHeaderToolbar: any;
  setShowHeaderToolbar: React.Dispatch<any>;
}

// Initialize the context with the defined type
const MarkerContext = createContext<MarkerContextType | null>(null);

export const MarkerProvider = ({ children }: any) => {
  const [markerArea, setMarkerArea] = useState<any | null>(null);
  const [contextImgRef, setContextImgRef] = useState<any | null>(null);
  const [contextCaptureInfoHeight, setContextCaptureInfoHeight] = useState<
    any | null
  >(null);
  const [showHeaderToolbar, setShowHeaderToolbar] = useState<boolean>(false);

  return (
    <MarkerContext.Provider
      value={{
        markerArea,
        setMarkerArea,
        contextImgRef,
        setContextImgRef,
        setContextCaptureInfoHeight,
        contextCaptureInfoHeight,
        showHeaderToolbar,
        setShowHeaderToolbar,
      }}
    >
      {children}
    </MarkerContext.Provider>
  );
};

export const useMarker = () => {
  const context = useContext(MarkerContext);
  if (context === null) {
    throw new Error('useMarker must be used within a MarkerProvider');
  }
  return context;
};
