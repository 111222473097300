import { getUserWithEmail } from '@/lib/firebase/firestore/user';
import {
  currentCaptureInfoState,
  getCurrentUserAccessType,
} from '@/state/captureState';
import { currentUserInfoState } from '@/state/userState';
import { User } from 'firebase/auth';
import { FC, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import EmailViewDropdown from '../header/EmailViewDropdown';
import ArrowDown from '../icon/ArrowDown';
import ArrowUpShare from '../icon/ArrowUpShare';
import UserAvatar from '../UserAvatar';
import { useOrganization } from '@clerk/clerk-react';
import { pathState } from '@/state/appState';
type EmailViewDropdown = {
  isEmailViewDropdownShow: boolean;
  index: number;
};
const ShareModalList: FC<{
  object: Capture.ShareModel;
  index: number;
  setIsProcessing: Function;
  isProcessing: boolean;
  listLenght: number;
}> = ({ object, index, setIsProcessing, isProcessing, listLenght }) => {
  const refDropDown = useRef<HTMLDivElement | null>(null);
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const [emailViewDropdownShow, setIsEmailViewDropdownShow] =
    useState<EmailViewDropdown>({ isEmailViewDropdownShow: false, index: -1 });
  const {organization} = useOrganization()
  const {uid :pathUid} = useRecoilValue(pathState)

  const currentAccessType = useRecoilValue(getCurrentUserAccessType);
  const currentUserID = useRecoilValue(currentUserInfoState) as User;
  let canSeeDropDown =
    currentAccessType === 'CAPTURE-OWNER' ||
    currentAccessType === 'EDIT-CAPUTRE-ACCESS' ||
    currentAccessType === 'EDIT-PROJECT-ACCESS' ||
    currentAccessType === 'VIEW-PROJECT-ACCESS';
  let canSeeUpdateDropDown =
    currentAccessType === 'CAPTURE-OWNER' ||
    currentAccessType === 'EDIT-CAPUTRE-ACCESS' ||
    currentAccessType === 'EDIT-PROJECT-ACCESS';
  // console.log(`Index Number:${index}`)

  // console.log(`Data At Index:${JSON.stringify(object)}`)

  let currentHeight: any = 0;
  const [displayName, setDisplayName] = useState('');
  const [uid, setUid] = useState('');
  useEffect(() => {
    // console.log(`Display Name is Showing:${displayName}`)
    // console.log(`UID`, object.uid)
  }, [displayName]);
  const handleClickEmailViewDropdown = (index: number) => {
    // setIsEmailViewDropdownShow((emailViewDropdownShow) => {
    if (canSeeDropDown) {
      if (object.uid === currentUserID.uid || !canSeeUpdateDropDown) return;
      setIsEmailViewDropdownShow({
        isEmailViewDropdownShow: !emailViewDropdownShow.isEmailViewDropdownShow,
        index: index,
      });
    } else if(organization && organization.id === pathUid){
      setIsEmailViewDropdownShow({
        isEmailViewDropdownShow: !emailViewDropdownShow.isEmailViewDropdownShow,
        index: index,
      });
    } else {
      return false;
    }
  };
  const clickOutside = (e: MouseEvent) => {
    if (refDropDown.current && refDropDown?.current.contains(e.target as Node))
      return false;
    // outside click && already opened
    if (
      emailViewDropdownShow.isEmailViewDropdownShow === true &&
      emailViewDropdownShow.index !== -1
    )
      return handleClickEmailViewDropdown(-1);
  };
  useEffect(() => {
    currentHeight = document
      .querySelector('.share-content')
      ?.getBoundingClientRect().height;

    document.addEventListener('mousedown', clickOutside);

    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailViewDropdownShow.isEmailViewDropdownShow]);

  const blockedWindow = (e: any) => {
    // console.log(document.querySelector('.share-content').offsetHeight);

    if (listLenght <= 1)
      // @ts-ignore
      document.querySelector('.share-content').style.minHeight = `${
        listLenght * 250
      }px`;
    setTimeout((): any => {
      // console.log(document.querySelector('.share-content')?.clientHeight);

      const sp: any = document.querySelector('.share-content')?.scrollTop;
      // console.log(e.clientY);
      if (e.clientY > currentHeight && index > 1)
        document.querySelector('.share-content')?.scrollTo(0, sp + 120);
    }, 10);
  };
  useEffect(() => {
    getUserWithEmail(object.email).then((users) => {
      if (users && users.length > 0) {
        setDisplayName(users[users.length - 1].displayName || '');
        setUid(users[users.length - 1].uid);
      } else {
        setDisplayName(object.email);
        setUid(object.uid || '');
      }
    });
  }, [object.email]);
  return (
    <div key={index} className={`flex pb-6 relative`}>
      {displayName && (
        <div>
          <UserAvatar
            uid={uid ? uid : ''}
            width={34}
            height={34}
            customClass="user-avatar-custom"
          ></UserAvatar>
        </div>
      )}
      <div className={`flex-1 w-32 `}>
        <h4 className="share-modal-name py-1 ml-2">
          {displayName === '' && (
            <div className="font-rubik  flex justify-center items-center">
              <div
                style={{ borderTopColor: 'transparent' }}
                className="w-4 h-4 border-1 border-black border-solid rounded-full animate-spin-fast"
              ></div>
            </div>
          )}
          {displayName != ''
            ? `${displayName}${object.uid === currentUserID.uid ? '(you)' : ''}`
            : `${displayName}${
                object.uid === currentUserID.uid ? '(you)' : ''
              }`}
          {displayName !== '' && object.IniviteStatus !== 'ACCEPTED'
            ? ` (${object.IniviteStatus.toLocaleLowerCase()})`
            : ''}
        </h4>
      </div>

      {displayName && (
        <div
          ref={refDropDown}
          key={index}
          className="ml-auto flex"
          tabIndex={1}
        >
          <div className="flex items-center">
            <span
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleClickEmailViewDropdown(index);
                blockedWindow(event);
              }}
              className={`share-modal-options mr-3  ${
                isProcessing ? 'cursor-wait' : "cursor-pointer"
              }`}
            >
              {!object.isGroupShare
                ? object.Role === 'VIEW'
                  ? 'Can view'
                  : object.Role === 'EDIT'
                  ? 'Can edit'
                  : object.Role === 'ADMIN'
                  ? 'Entire Project Access'
                  : 'Can View'
                : object.Role === 'EDIT'
                ? 'EDIT Project Access'
                : 'View Project Access'}
            </span>
            {currentUserID.uid !== object.uid && canSeeUpdateDropDown && (
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  handleClickEmailViewDropdown(index);
                  blockedWindow(event);
                }}
                className="cursor-pointer "
              >
                {emailViewDropdownShow.isEmailViewDropdownShow === true &&
                emailViewDropdownShow.index === index ? (
                  <ArrowUpShare width={16} height={16} />
                ) : (
                  <ArrowDown />
                )}
                {/* <ArrowDown /> */}
              </span>
            )}
          </div>
          <EmailViewDropdown
            groupName={currentCaptureInfo?.group || ''}
            object={object}
            isEmailViewDropdownShow={
              emailViewDropdownShow.isEmailViewDropdownShow &&
              emailViewDropdownShow.index === index
            }
            setIsProcessing={setIsProcessing}
            isProcessing={isProcessing}
            onClose={() => setIsEmailViewDropdownShow((prev) => ({
              ...prev,
              isEmailViewDropdownShow: false,
            }))}
          />
        </div>
      )}
    </div>
  );
};

export default ShareModalList;
