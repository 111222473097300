import dayjs from 'dayjs';
import {
  getFirestore,
  collection,
  getDocs,
  onSnapshot,
  query,
  orderBy,
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
  deleteDoc,
  setDoc,
} from 'firebase/firestore';
import _, { isArray } from 'lodash';
import { getUserWithEmail } from './user';
import * as caputre from '@/lib/firebase/firestore/capture';
import { shareListWithRoles } from '@/state/captureState';

const DB_PREFIX = 'instacap';
type SharedWithMe = Capture.SharedWithMe;

export const saveShareInvite = async (data: Capture.ShareModel) => {
  try {
    //Adding Share Invite Model
    const db = getFirestore();
    const colRef = collection(
      db,
      'instacap',
      'shares',
      'invite',
      'meta',
      data.cid
    );

    // console.log(`Collection Refference ${colRef.path}`);
    let users = await getUserWithEmail(data.email);
    if (
      users &&
      users.length !== 0 &&
      users[users.length - 1].uid !== undefined
    ) {
      data.uid = users[users.length - 1].uid;
      data.displayName = users[users.length - 1].displayName || '';
    }
    data.email = data.email.toLowerCase();
    const docRef = doc(colRef, data.email);
    await setDoc(docRef, data);
    await setShareWithMeInvite(data, data.cid, data.creator.uid, data.email);
    return () => {
      deleteDoc(docRef);
    };
  } catch (error) {
    console.error('Error adding document: ', error);
  }
};

//Here Updating or Inserting New Field if doesnt exits So it will Add New Name against UID and CID which will update
//New Field Name in Current Capture Information.
export const updateShareInvite = async (
  email: string,
  cid: string,
  info: object,
  uid: string
) => {
  try {
    //if Status of that Email is Pending than it will Update Share Invite Status to Accepted
    //If status of that Email is requested than it will not update Apart owner of Capture will update
    //When Owner of Capture Will Approve this it will Change Status to Accpeted By Owner
    const db = getFirestore();
    const colRef = collection(db, 'instacap', 'shares', 'invite', 'meta', cid);
    let reffer = doc(colRef, email);
    console.log(`Reffffff-`, reffer);
    let updateResponse = await updateDoc(doc(colRef, email), {
      updatedAt: dayjs().unix(),
      ...info,
    });
    console.log(`Update Response`, updateResponse);
    await setShareWithMeInvite(info, cid, uid, email);
  } catch (error) {
    console.log('Error update user: ', error);
  }
};

export const getListSharesInvite = async (cid: string, cb: Function) => {
  try {
    if (cid === '') return;
    const db = getFirestore();
    const colRef = collection(
      db,
      `${DB_PREFIX}`,
      'shares',
      'invite',
      'meta',
      cid
    );

    const q = query(colRef, orderBy('createdAt', 'desc'));

    const colSnap = await getDocs(q);

    const result: Capture.ShareModel[] = [];

    colSnap.forEach((docSnap) => {
      if (docSnap.exists()) {
        result.push(docSnap.data() as Capture.ShareModel);
      }
    });
    // console.log(`Share Invite List ${JSON.stringify(result)}`);

    //Here We Will Create One More List Like Group Ai List At Host to Show Capture Shared With me ...
    //Like if group of Caputre then it will Show Group of Caputre ...
    //In Which on side bar it will show thumbnails like if its simple Capture than capture image will
    //Occur in thumbnail Image.
    //If it will group it will fetch all capture in that group and will show as well in List Shared
    //With Me

    cb(result);
  } catch (error) {
    console.log(error);
  }
};

export const setShareWithMeInvite = async (
  data: any,
  cid: string,
  uid: string,
  email: string
) => {
  try {
    const db = getFirestore();
    const docRef = doc(db, `instacap/shares/sharedWithMe/${email}`);
    const docSnap = await getDoc(docRef);

    let sharedWithMe: SharedWithMe = {
      cid: cid,
      uid: uid,
      Role: data.Role || 'VIEW',
      groupName: data.groupName,
      isGroupShare: data.isGroupShare,
      IniviteStatus: data.IniviteStatus,
    };
    if (docSnap.exists()) {
      let shareModels = (await docSnap.data()
        .invitations) as Capture.SharedWithMe[];
      var index = _.findIndex(shareModels, {
        cid: cid,
        uid: uid,
      });
      if (index !== -1) {
        let sharedWithMeUpdate: SharedWithMe = {
          ...shareModels[index],
          groupName: data?.groupName || shareModels[index].groupName,
          isGroupShare:
            data?.isGroupShare !== undefined
              ? data?.isGroupShare
              : shareModels[index].isGroupShare !== undefined
              ? shareModels[index].isGroupShare
              : false,
          IniviteStatus:
            data?.IniviteStatus !== ''
              ? data?.IniviteStatus
              : shareModels[index].IniviteStatus,
          Role: data?.Role || shareModels[index].Role,
        };
        shareModels[index] = sharedWithMeUpdate;
        await setDoc(docRef, {
          updatedAt: dayjs().unix(),
          invitations: shareModels,
        });
      } else {
        await updateDoc(docRef, {
          updatedAt: dayjs().unix(),
          invitations: arrayUnion(sharedWithMe),
        });
      }
    } else {
      await setDoc(docRef, {
        updatedAt: dayjs().unix(),
        invitations: [sharedWithMe],
      });
    }
    return () => {
      deleteDoc(docRef);
    };
  } catch (err) {
    console.error('Setting Shared With Me error:', err);
  }
};

export const getListSharesWithMeCapture = async (
  email: string,
  setStateFunction: Function,
  cb: Function
) => {
  try {
    const db = getFirestore();

    const docRef = doc(db, `instacap/shares/sharedWithMe/${email}`);
    const docSnap = await getDoc(docRef);
    const result: Capture.SharedWithMe[] = [];
    if (docSnap.exists()) {
      let resp = docSnap.data() as any;
      result.push(...resp.invitations);
      setStateFunction(shareListWithRoles, result);
    }
    // console.log(`Lenght of Shared List With Roles ${result.length}`);
    let caputreList = await getCapturelist(result);
    cb(caputreList);
  } catch (error) {
    console.log(error);
  }
};
export const getSubShared = (
  email: string,
  setStateFunction: Function,
  cb: Function
) => {
  try {
    const db = getFirestore();
    let result: Capture.SharedWithMe[] = [];
    const unsub = onSnapshot(
      doc(db, 'instacap', 'shares', 'sharedWithMe', email),
      async (doc) => {
        if (doc.exists()) {
          let data = doc.data() as any;
          if (data && isArray(data?.invitations)) {
            result = data?.invitations;
            setStateFunction(shareListWithRoles, result);
            let list = await getCapturelist(result);
            cb(list);
          }
        }
      }
    );
    return () => {
      unsub();
    };
  } catch (error) {
    console.log(error);
    return () => {};
  }
};

const getCapturelist = async (data: SharedWithMe[]) => {
  try {
    let list: Capture.Info[];
    list = [];
    //Now List Will be unique base on UID and CID and thumbnail will be of selected ITEM
    for await (const object of data) {
      let found = _.findIndex(list, (element) => {
        return element.cid === object.cid && element.creator.uid === object.uid;
      });
      if (found === -1) {
        await caputre.getCaputreInfo(
          object.uid,
          object.cid,
          object.isGroupShare,
          object.groupName,
          (info: any) => {
            if (isArray(info)) {
              list.push(...info);
            } else {
              if (info) list.push(info);
            }
          }
        );
      }
    }
    return list;
  } catch (error) {
    console.log(`Error on Finding Capture ${error}`);
  }
};

export const updateShareInviteRole = async (
  email: string,
  cid: string,
  info: Capture.ShareModel,
  uid: string
) => {
  try {
    //if Status of that Email is Pending than it will Update Share Invite Status to Accepted
    //If status of that Email is requested than it will not update Apart owner of Capture will update
    //When Owner of Capture Will Approve this it will Change Status to Accpeted By Owner
    const db = getFirestore();
    const colRef = collection(db, 'instacap', 'shares', 'invite', 'meta', cid);

    await setDoc(doc(colRef, email), {
      ...info,
    });
    await setShareWithMeInvite(info, cid, uid, email);
  } catch (error) {
    console.log('Error update user: ', error);
  }
};

export const deleteSharingCaptureForUser = async (
  email: string,
  cid: string
) => {
  const db = getFirestore();
  const inviteRef = doc(db, 'instacap', 'shares', 'invite', 'meta', cid, email);
  await deleteDoc(inviteRef);

  const sharedWithMeRef = doc(db, 'instacap', 'shares', 'sharedWithMe', email);
  const sharedWithMeSnapshot = await getDoc(sharedWithMeRef);

  if (sharedWithMeSnapshot.exists()) {
    const sharedWithMeData = sharedWithMeSnapshot.data();
    const invitationIndex = sharedWithMeData.invitations.findIndex(
      (invitation: any) => invitation.cid === cid
    );

    if (invitationIndex > -1) {
      sharedWithMeData.invitations.splice(invitationIndex, 1);
      await updateDoc(sharedWithMeRef, sharedWithMeData);
    }
  }
};
