import { FC } from 'react';
import Loader from './Loader';

type Args = {
    backgroundColor?: string;
    label?: string;
    onClick?: () => void;
    isLoading?: boolean;
    isDisabled?: boolean;
    className?: string;
    style?: any;
    textColor?: string;
    childrenLeft?: boolean;
};

const Button: FC<Args> = ({ backgroundColor = "white", label = "", isLoading = false, isDisabled = false, onClick, className = "", style = {}, textColor = null, childrenLeft = false, children }) => {

    const loader = <div className={`absolute inset-0 flex items-center tr justify-center transform ${isLoading ? 'scale-100 opacity-1' : 'opacity-0 scale-90'}`}><Loader color={"black"} className='-mt-px' size={24} /></div>;

    return (
        <button
            className={`flex items-center py-2 sm:hidden border-100 hover:opacity-100 relative cursor-pointer disabled:opacity-50 !cursor-default ${className}`}
            style={{ border: "1px solid #000000", boxShadow: "1px 1px 0px #000000", ...style, backgroundColor: backgroundColor }}
            onClick={onClick}
            disabled={isDisabled || isLoading}
        >
            {childrenLeft ?
                <>
                    {!isLoading && children}
                    <span style={{ color: isLoading ? "transparent" : (textColor ? textColor : "initial") }}>{label}</span>
                </> :
                <>
                    <span style={{ color: isLoading ? "transparent" : (textColor ? textColor : "initial") }}>{label}</span>
                    {!isLoading && children}
                </>
            }
            {isLoading && loader}
        </button>
    );
};

export default Button;
