import { useRecoilCallback, useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import { pathState } from '@/state/appState';
import {
  setCaptureInvite,
  getUser,
  sendPostForInvite,
  saveShareInvite,
  getListSharesInvite,
  setGId,
} from '@/lib/firebase/firestore';
import _ from 'lodash';
import {
  currentCaptureInfoState,
  currentCaptureShareInvitListState,
  shareListWithRoles,
} from '@/state/captureState';

export const useInvitation = () => {
  const setInvitation = useRecoilCallback(
    ({ snapshot }) => async (shareModels: Capture.ShareModel[] | undefined) => {
      const release = snapshot.retain();
      try {
        // console.log(`Sending Invitation`);
        const path = await snapshot.getLoadable(pathState).contents;
        const user = (await getUser(path.uid)) as Capture.Creator;
        const currentCaptureInfo = (await snapshot.getLoadable(
          currentCaptureInfoState
        ).contents) as Capture.Info;
        // console.log(`Guest User`);
        const { displayName, email } = user;

        const creator = {
          name: displayName || '',
          email: email ? email : '',
          uid: path.uid,
        };

        const now = dayjs().unix();
        let emails = _.map(shareModels, 'email');
        const invitation: Capture.Invitation = {
          emails,
          creator,
          createdAt: now,
        };
        const data: Capture.InvitationData = {
          invitation,
          updatedAt: now,
        };
        let url = '';
        if (
          shareModels !== undefined &&
          shareModels.length > 0 &&
          shareModels[0].isGroupShare &&
          shareModels[0].isGroupShare !== undefined
        ) {
          const urlInfo = new URL(window.location.href);
          const groupId = await setGId(path.uid, currentCaptureInfo.group);
          url = `${urlInfo.origin}/${path.uid}/group/${groupId}/${path.cid}`;
        }
        await sendPostForInvite(
          emails,
          {
            name: displayName ? displayName : '',
            email: email ? email : '',
            uid: path.uid,
          },
          shareModels !== undefined ? shareModels[0]?.message : '',
          shareModels !== undefined ? shareModels[0]?.Role : '',
          {
            projectName:
              currentCaptureInfo?.projectName ||
              currentCaptureInfo?.group ||
              'Untitled Project',
            capName: currentCaptureInfo?.name || 'Untitled Capture',
            isRequestAccepted: false,
            gotToUrl: url,
            // @ts-ignore
            isGroupShare:
              shareModels !== undefined ? shareModels[0]?.isGroupShare : false,
          }
        );
        if (shareModels && shareModels?.length > 0) {
          for await (let shareModel of shareModels) {
            await saveShareInvite(shareModel);
          }
        }

        let response = await setCaptureInvite(path.uid, path.cid, data);
        release();
        return response;
      } catch (err) {
        release();
        console.error('Sending the board invitetion error:', err);
      }
    }
  );
  const setCurrentCaptureShareInviteList = useRecoilCallback(
    ({ set, snapshot }) => async (cid: string) => {
      try {
        // const path = await snapshot.getLoadable(pathState).contents;
        await getListSharesInvite(cid, (result: any) => {
          if (result) {
            //Here Set State for Current Capture Share Invites
            set(currentCaptureShareInvitListState, result);
            // setCurrentCaptureShareInvitListState(result);
          }
        });
      } catch (err) {
        console.error('Sending the board invitetion error:', err);
      }
    }
  );
  const isProjectEditAccess = useRecoilCallback(
    ({ set, snapshot }) => async (
      uid: string,
      group: string,
      showMyProjects?: boolean,
      cid?: string
    ) => {
      try {
        const sharedList = (await snapshot.getLoadable(shareListWithRoles)
          .contents) as Capture.ShareModel[];

        if (showMyProjects) return true;
        if (!cid) {
          // console.log(`Cid Not Provided Now Checking WIth Group:`);
          // console.log(`Share List We Had? ${sharedList.length}`);
          let foundGroup = _.find(sharedList, {
            groupName: group,
            uid: uid,
            Role: 'EDIT',
          });
          // console.log(
          //   `Found Group:${JSON.stringify(foundGroup)} Decided To Show or Not ${
          //     foundGroup && foundGroup.isGroupShare ? false : true
          //   }`
          // );
          return foundGroup && foundGroup.isGroupShare;
        } else {
          // console.log(`Cid  Provided Now Checking WIth Group and CID:`);
          let foundGroup = _.filter(sharedList, {
            groupName: group,
            isGroupShare: true,
            uid: uid,
            Role: 'EDIT',
            cid: cid,
          });
          // console.log(`Decided To Show or Not ${!foundGroup ? false : true}`);
          return !foundGroup || foundGroup.length === 0 ? false : true;
        }
      } catch (err) {
        console.error('Sending the board invitetion error:', err);
      }
    }
  );

  return {
    setInvitation,
    setCurrentCaptureShareInviteList,
    isProjectEditAccess,
  };
};
