import { FC, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  captureIsPublicState,
  captureIsPublicGroupState,
  currentCaptureIsPdfState,
} from '@/state/captureState';
import Settings from '../icon/Settings';
import SignOut from '../icon/SignOut';
import { useAuth } from '@/hooks/useAuth';
import { profileModalState } from '@/state/userState';

const AvatarDropDown: FC<{
  isDropdownShow: boolean;
  setDropdownShow: Function;
}> = ({ isDropdownShow, setDropdownShow }) => {
  const captureIsPublic = useRecoilValue(captureIsPublicState);
  const captureIsPublicGroup = useRecoilValue(captureIsPublicGroupState);
  const currentCaptureIsPdf = useRecoilValue(currentCaptureIsPdfState);
  const { signOut, updatePlan } = useAuth();
  const setProfileModal = useSetRecoilState(profileModalState);

  const list = useMemo(
    () => {
      const data = [
        {
          text: `Settings`,
          fn: () => {
            setDropdownShow(false);
            setProfileModal(true);
            updatePlan();
          },
          needPermission: false,
          htmlContent: '',
          isLineBreak: false,
          Icon: Settings,
        },
        {
          text: `Log Out`,
          fn: () => {
            signOut();
          },
          needPermission: false,
          classname: '',
          htmlContent: '',
          isLineBreak: false,
          Icon: SignOut,
        },
      ];

      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [captureIsPublicGroup, currentCaptureIsPdf]
  );

  return (
    <>
      {isDropdownShow && (
        <div
          className="z-20 absolute top-18  w-56 mt-2 -ml-2 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none avatar-dropdown"
          role="menu"
          tabIndex={1}
        >
          <div className="py-2" role="none">
            {list.map(
              ({ text, fn, needPermission, Icon, isLineBreak }, index) => {
                if (captureIsPublic && needPermission) return null;
                return (
                  <div key={`avatart-drop-down-${index}`}>
                    {!isLineBreak ? (
                      <button
                        type="button"
                        key={index}
                        className={` pl-4 text-gray-900 group flex rounded-md items-center w-full   text-sm hover:bg-green hover:text-black   dropdown-item avatar-dropdown-text   `}
                        role="menuitem"
                        onClick={fn}
                      >
                        <div
                          className={`flex justify-center w-6 min-w-min min-h-max mr-1 `}
                        >
                          <Icon width={16} height={16} />
                        </div>
                        <span className={` `}>{text}</span>
                      </button>
                    ) : (
                      <div className="container-dropdown px-4">
                        <hr className="my-2"></hr>
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AvatarDropDown;
