import { atom } from 'recoil';

export const modifyCommentState = atom<{ content: string; index: number }>({
  key: 'ModifyComment',
  default: { content: '', index: -1 },
});

export const commentTypeState = atom<Capture.CommentType>({
  key: 'CommentType',
  default: 'POST',
});

export const isShowAddCommentState = atom<boolean>({
  key: 'isShowAddCommentState',
  default: false,
});

export const DEFAULT_CURRENT_COMMENT: Capture.CurrentComment = {
  commentIndex: -1,
  replyIndex: -1,
  content: '',
  isPost: false,
  isBeingModify: false,
  type: 'POST',
  modifyType: 'WRITE',
  timestamp: 0,
  isResolved: false,
  isLoomVideoAttached: false,
  isMain: false,
};

export const currentCommentState = atom<Capture.CurrentComment>({
  key: 'CurrentComment',
  default: {
    ...DEFAULT_CURRENT_COMMENT,
  },
});
export const isResolvedHidden = atom<{
  index: number;
  isResolvedHidden: boolean;
}>({
  key: 'HideResolve',
  default: { isResolvedHidden: true, index: -1 },
});
export const isNotResolvedShowAvatar = atom<boolean>({
  key: 'ShowTickOrAvatar',
  default: false,
});
export const showCommentNumber = atom<{
  commentIndex: Number;
  showReply: Boolean;
}>({
  key: 'ShowCommentNumber',
  default: { commentIndex: -1, showReply: false },
});
export const commentPositionListState = atom<{ x: number; y: number }[]>({
  key: 'CommentPositionList',
  default: [],
});
