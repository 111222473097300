import { useRecoilValue } from 'recoil';
import { currentUserInfoState } from '@/state/userState';
import type { User } from 'firebase/auth';
import { SignUpButton } from '@clerk/clerk-react';

const SignUp = ({ className = "", mode = "section" }) => {
    const currentUserInfo = useRecoilValue(currentUserInfoState) as User;

    if (currentUserInfo?.isAnonymous) {
        return (
            <div className={`cursor-pointer ${className}`}>
                <SignUpButton mode="modal" redirectUrl="">
                    {mode != "text" ?
                        <button className="py-2 lg:h-16 mx-4 sm:hidden rounded-3xl border-3 border-black">
                            <span className={`${mode == "text" ? "underline font-bold" : "capitalize font-bold px-8 text-sm"}`}>{"Sign Up for Free"}</span>
                        </button> :
                        <span className={`${mode == "text" ? "underline font-bold" : "capitalize"}`}>{"Sign Up for Free"}</span>
                    }
                </SignUpButton>
            </div>
        );
    }

    return <></>
};

export default SignUp;
