const RightMove = () => (
  <svg
    className="my-auto cursor-pointer"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2100_67709)">
      <path d="M24.5 0H0.5V24H24.5V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M10 6L16 12L10 18"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2100_67709">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default RightMove;
