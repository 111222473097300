import { FC, memo } from 'react';

interface props {
  width?: number;
  height?: number;
  opacity?: number;
  fill?: string;
  strokeColor?: string;
}

const ClockIcon: FC<props> = ({
  height = 18,
  width = 18,
  opacity = 1,
  fill = 'none',
  strokeColor = '#808080',
}) => {
  return (
    <svg
      width={width}
      height={height}
      opacity={opacity}
      fill={fill}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3502_23180)">
        <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
        <path
          d="M7.99967 14.6666C11.6816 14.6666 14.6663 11.6818 14.6663 7.99992C14.6663 4.31802 11.6816 1.33325 7.99967 1.33325C4.31777 1.33325 1.33301 4.31802 1.33301 7.99992C1.33301 11.6818 4.31777 14.6666 7.99967 14.6666Z"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinejoin="round"
        />
        <path
          d="M8.00284 4L8.00244 8.00293L10.8289 10.8294"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3502_23180">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(ClockIcon);
