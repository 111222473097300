import React, { FC, memo, useMemo } from 'react';

//Use one of the options
interface EmojiProps {
  emoji?: any;
  code?: number;
}

const Emoji: FC<EmojiProps> = ({ code, emoji }) => {
  const emojiSize = useMemo(
    () => (window.innerWidth > 595 ? '25px' : '40px'),
    []
  );

  return (
    <span
      role="img"
      aria-label="1"
      style={{ width: emojiSize, height: emojiSize }}
    >
      {code && String.fromCodePoint(code)}
      {emoji && emoji}
    </span>
  );
};

export default memo(Emoji);
