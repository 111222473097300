/* eslint-disable jsx-a11y/alt-text */
import { FC, useEffect, useRef, useState } from 'react';
import { useIntersection } from '@/hooks/useIntersection';
import { useRecoilState } from 'recoil';
import {
  isLoadingThumbnailCidState,
  isLoadingThumbnailState,
} from '@/state/captureState';
import './ImageRenderer.scss';

const ImageRenderer: FC<{
  className: string;
  src: string;
  alt: string;
  cid?: string;
}> = ({ className, src, cid, ...rest }) => {
  const [isInView, setIsInView] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [isLoadingThumbnail, setIsLoadingThumbnail] = useRecoilState(
    isLoadingThumbnailState
  );
  const [isLoadingThumbnailCid, setIsLoadingThumbnailCid] = useRecoilState(
    isLoadingThumbnailCidState
  );

  const imgRef = useRef(null);

  useEffect(() => {
    if (isLoadingThumbnail && isLoadingThumbnailCid === cid) {
      setShowLoading(true);

      const timeout = setTimeout(() => {
        setShowLoading(false);
        setIsLoadingThumbnail(false);
        setIsLoadingThumbnailCid('');
      }, 2800);

      return () => clearTimeout(timeout);
    } else {
      setShowLoading(false);
    }
  }, [isLoadingThumbnail, isLoadingThumbnailCid]);

  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  return (
    <div ref={imgRef}>
      {showLoading ? (
        <div className="custom-loader"></div>
      ) : (
        isInView && (
          <img
            className={`${className}`}
            src={src}
            key={src}
            data-src={src}
            loading="lazy"
            {...rest}
          />
        )
      )}
    </div>
  );
};

export default ImageRenderer;
