import { MouseEvent, FC, useEffect, RefObject } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { useMixpanel } from '@/hooks/useMixpanel';
import { UserInfo } from 'firebase/auth';

const CaptureCommentEmailInput: FC<{
  isClicked: boolean;
  isComment: boolean;
  inputEl: RefObject<HTMLInputElement>;
  setshowError?: Function;
  showError: Boolean;
  height?: Number;
  width?: Number;
}> = ({
  isClicked,
  inputEl,
  setshowError,
  height,
  width,
  showError,
  isComment,
}) => {
    const { isNeedEmail, setAnonymousEmail, getUserInfo, setAnonymousDisplayName } = useAuth();
    const { setUser } = useMixpanel();

    useEffect(() => {
      if (!isNeedEmail || !inputEl || !inputEl.current) return;
      if (isClicked) {
        if (inputEl.current?.value) {
          // setAnonymousDisplayName(inputEl.current?.value);
          // const userInfo = getUserInfo() as UserInfo;
          // // console.log(userInfo);
          // if (userInfo?.uid) {
          //   setUser(
          //     inputEl.current?.value,
          //     userInfo.displayName || '',
          //     userInfo.uid
          //   );
          // }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClicked]);
    if (!isNeedEmail) return null;
    return (
      <div className={`w-3/3 ${isComment ? 'px-4' : ''}  pt-4`}>
        <div className={`bg-grey-light`}>
          <div
            className="relative flex w-full flex-wrap items-stretch"
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              event.stopPropagation();
            }}
            onKeyDown={() => {
              setshowError && setshowError(false);
            }}
          >
            <input
              ref={inputEl}
              type="email"
              placeholder="Enter Name"
              className={`relative p-2.5 pl-5 pr-3 w-full bg-white border-black shadow outline-none text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline email-input ${showError ? 'border-red' : ''
                }`}
              style={{ height: `${height ? height : 40}px` }}
            />
          </div>
        </div>
      </div>
    );
  };

export default CaptureCommentEmailInput;
