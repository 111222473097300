type props = {
  width?: number
  height?: number
  color?: string
}
const Tick = (props: props) => (
  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width={props.width || "34"} height={props.height || "34"} preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
    <path fill={props.color || "#FF38A4"}
      d="m9 19.414l-6.707-6.707l1.414-1.414L9 16.586L20.293 5.293l1.414 1.414z" />
  </svg>
);

export default Tick;
