import { useCallback } from 'react';
import dayjs from 'dayjs';
import { saveFile } from '@/lib/firebase/storage';
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { TypedArray } from 'pdfjs-dist/types/src/display/api';
import { ImgSize } from '@/components/capture/types';
import { currentCaptureInfoState } from '@/state/captureState';
import { useRecoilValue } from 'recoil';
import { saveShareInvite } from '@/lib/firebase/firestore';
import _ from 'lodash';
import { currentUserIDState, currentUserInfoState } from '@/state/userState';
import { useOrganization } from '@clerk/clerk-react';

export const useUpload = (
  group?: string,
  projectName?: string,
  currentCaptureShareInviteList?: Capture.ShareModel[]
) => {
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const currentUserInfo = useRecoilValue(currentUserInfoState);
  const currentUserID = useRecoilValue(currentUserIDState);
  const { organization } = useOrganization()

  const uploadPDF = useCallback(
    async (blob: Blob, filename: string, done: Function, progressCallback?: Function) => {
      const currentUser = { ...currentUserInfo }

      if (organization) {
        currentUser.uid = organization.id
      }

      const readFileData = (blob: Blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsArrayBuffer(blob);
          reader.onload = (e) => {
            // @ts-ignore
            resolve(new Uint8Array(e.target.result));
          };
          reader.onerror = (error) => reject(error);
        });
      };

      const convertPdfToSingleImage = async (blob: Blob) => {
        const data = (await readFileData(blob)) as TypedArray;
        const pdf = await pdfjs.getDocument(data).promise;
        const canvasArray: HTMLCanvasElement[] = [];
        const SPACING = 10;
        const LINE_HEIGHT = 2;

        // Calculate total number of pages
        const totalPages = pdf.numPages;
        let completedPages = 0;

        // Iterate through each page of the PDF
        for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const viewport = page.getViewport({ scale: 1 });
          const canvas = document.createElement('canvas');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const context = canvas.getContext('2d');

          // Render the page to the canvas
          // @ts-ignore
          await page.render({ canvasContext: context, viewport: viewport }).promise;

          // Store the canvas and context for later use
          canvasArray.push(canvas);

          // Update progress
          completedPages++;
          const progress = Math.round((completedPages / totalPages) * 100);
          if (progressCallback) progressCallback(progress);
        }

        // Calculate the total width and height required for all pages
        let totalHeight = 0;
        let totalWidth = 0;
        for (let i = 0; i < canvasArray.length; i++) {
          totalHeight += canvasArray[i].height;
          totalWidth = Math.max(totalWidth, canvasArray[i].width);
        }

        // Create a new canvas to compose all pages and lines into a single image
        const composedCanvas = document.createElement('canvas');
        composedCanvas.height =
          totalHeight +
          (pdf.numPages - 1) * SPACING +
          (pdf.numPages - 1) * LINE_HEIGHT;
        composedCanvas.width = totalWidth;
        const composedContext = composedCanvas.getContext('2d');

        // Composite each page onto the composed canvas
        let offsetY = 0;
        for (let i = 0; i < canvasArray.length; i++) {
          // @ts-ignore
          composedContext.drawImage(canvasArray[i], 0, offsetY);
          offsetY += canvasArray[i].height;
          if (i < canvasArray.length - 1) {
            offsetY += SPACING;

            // @ts-ignore
            composedContext.fillStyle = 'black';
            // @ts-ignore
            composedContext.fillRect(0, offsetY, totalWidth, LINE_HEIGHT);
            offsetY += LINE_HEIGHT;
          }
        }

        // Convert the composed canvas to a single image
        await new Promise((resolve) => {
          composedCanvas.toBlob((blob) => {
            if (!blob) return;
            saveFile(
              blob,
              { width: composedCanvas.width, height: composedCanvas.height },
              () => { },
              async (path: string, cid: string) => {
                done(path);
              },
              !group ? `non-project` : group,
              {
                projectName: projectName || filename,
                name: filename,
                publicAccess: !group
                  ? false
                  : currentCaptureInfo?.publicAccess !== undefined
                    ? currentCaptureInfo?.publicAccess
                    : false,
              },
              !group ? undefined : currentCaptureInfo?.creator,
              currentUser,
            );
            resolve(true);
          }, 'image/jpeg');
        });

        // Clean up: remove individual canvases
        canvasArray.forEach((canvas) => canvas.remove());
        // Clean up: remove the composed canvas
        composedCanvas.remove();
      };

      // @ts-ignore
      await convertPdfToSingleImage(blob);
    },
    [group, projectName, currentCaptureShareInviteList]
  );

  const uploadImage = useCallback(
    async (
      blob: Blob,
      filename: string,
      imgSize: ImgSize,
      progress: Function,
      done: Function
    ) => {
      const currentUser = { ...currentUserInfo }
      if (organization) {
        currentUser.uid = organization.id
      }

      let cid = await saveFile(
        blob,
        imgSize,
        progress,
        async (path: string, cid: string) => {
          if (group) {
            await saveShareInviteOnNewCapture(cid);
          }
          done(path);
        },
        !group ? `non-project` : group || filename,
        {
          projectName: projectName || filename,
          name: filename,
          publicAccess: !group
            ? false
            : currentCaptureInfo?.publicAccess !== undefined
              ? currentCaptureInfo?.publicAccess
              : false,
        },
        !group ? undefined : currentCaptureInfo?.creator,
        currentUser
      );
      // setAddingNew(true);
    },
    [group, projectName, currentCaptureShareInviteList]
  );
  const saveShareInviteOnNewCapture = async (cid: string) => {
    try {
      console.log(
        `Current Capture Share Invite List Lenght:${currentCaptureShareInviteList?.length}`
      );
      if (!cid) return;
      if (!currentCaptureShareInviteList) return;
      let shareModels = currentCaptureShareInviteList.filter((item) => {
        return item.isGroupShare === true;
      });
      if (shareModels && shareModels?.length > 0) {
        for await (var shareModel of shareModels) {
          let json = JSON.stringify(shareModel);
          let jsonParse = JSON.parse(json);
          jsonParse.cid = cid;

          await saveShareInvite(jsonParse as Capture.ShareModel);
        }
      }
    } catch (error) {
      console.log(
        `Error on Updating Share Invite on New Added Capture ${error}`
      );
    }
  };
  return {
    uploadPDF,
    uploadImage,
    saveShareInviteOnNewCapture,
  };
};
