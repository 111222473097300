import { useAuth } from '@/hooks/useAuth';
import {
  currentUserIDState,
  currentUserInfoState,
  isdisplayNameSettedState,
} from '@/state/userState';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Save from '../../assets/icons/Save.svg';
import UserAvatar from '../UserAvatar';

const Profile = () => {
  const { setDisplayName } = useAuth();

  const currentUserID = useRecoilValue(currentUserIDState);
  const userInfo = useRecoilValue(currentUserInfoState) as Capture.Creator;

  const [updating, setIsUpdating] = useState(false);
  const [Name, setName] = useState(userInfo?.displayName);
  const [linkedinUrl, setLinkedUrl] = useState(userInfo?.linkedinUrl || '');
  const [twitterUrl, setTwitterUrl] = useState(userInfo?.twitterUrl || '');
  const [join, setJoin] = useState('');

  useEffect(() => {
    let date = new Date(parseInt(userInfo.createdAt));
    let month = date.getMonth();
    let year = date.getFullYear();
    let months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let currentMonth = months[month];
    setJoin(currentMonth + ' ' + year);
  }, [userInfo]);
  const setIsDisplayNameSetted = useSetRecoilState(isdisplayNameSettedState);

  const handleSubmit = async (e: any) => {
    //Here We Update Name from the text area element\
    e.preventDefault();
    let valid = true;
    if (linkedinUrl) {
      const validUrl = linkedinValidation(linkedinUrl);
      if (!validUrl) {
        toast.error('Invalid Linkedin Url. Please, try again!');
        valid = false;
      }
    }
    if (twitterUrl) {
      const validUrl = twitterValidation(twitterUrl);
      if (!validUrl) {
        toast.error('Invalid Twitter Url. Please, try again!');
        valid = false;
      }
    }

    if (Name && valid) {
      setIsUpdating(true);
      await setDisplayName(userInfo.uid, Name, linkedinUrl, twitterUrl)
        .then((e) => {
          setIsUpdating(false);
          toast.success('Profile Updated Successfully.');
          setIsDisplayNameSetted(true);
        })
        .catch((e) => console.log(e));
    }
  };

  const linkedinValidation = (url: string) => {
    const expression =
      '/(?:http://)?(?:www.)?linkedin.com/(?:(?:w)*#!/)?(?:pages/)?(?:[w-]*/)*([w-]*)/';

    const regex = new RegExp(expression);
    return regex.test(url);
  };

  const twitterValidation = (url: string) => {
    const expression =
      '/(?:http://)?(?:www.)?twitter.com/(?:(?:w)*#!/)?(?:pages/)?(?:[w-]*/)*([w-]*)/';

    const regex = new RegExp(expression);
    return regex.test(url);
  };

  return (
    <div className="w-full relative">
      <div className="mx-8 mt-4">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-medium">Profile</h1>
          <p className="upload-grey-txt">Joined {join}</p>
        </div>
        <div className="flex justify-center my-4">
          <UserAvatar
            customClass="none"
            height={120}
            width={120}
            uid={currentUserID}
          />
        </div>
        <div className="flex justify-center my-6 hidden">
          <button className="upload-success h-10 px-6 flex justify-center items-center">
            <img src={Save} alt="upload" /> &nbsp; Upload
          </button>
        </div>
      </div>
      <form className="profile-form ml-8" onSubmit={handleSubmit}>
        <div className="flex input-row">
          <div className="input-block">
            <label className="form-label">Display Name</label>
            <input
              type="text"
              className="w-11/12 block p-2 pl-4 text-sm text-gray-900 bg-gray-50 rounded-3xl border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              autoFocus={false}
              required
              maxLength={25}
              placeholder="Enter your Display Name"
              defaultValue={`${userInfo?.displayName}`}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <span>
              {Name?.length == 25 && (
                <small className="text-red-600">maximum limit reached</small>
              )}
            </span>
          </div>{' '}
          <div className="input-block">
            <label className="form-label">Email</label>
            <input
              type="email"
              className="w-11/12 cursor-not-allowed pl-4 block p-2 text-sm text-gray-500 bg-gray-50 rounded-3xl border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              autoFocus={false}
              placeholder=""
              defaultValue={`${userInfo?.email}`}
              disabled
            />
          </div>
        </div>
        <h2 className="text-base font-medium social-text">Other Social</h2>
        <div className="flex input-row">
          <div className="input-block">
            <label className="form-label">Linkedin URL</label>
            <input
              type="text"
              className=" block p-2 w-11/12 text-sm pl-4 text-gray-900 bg-gray-50 rounded-3xl border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              autoFocus={false}
              maxLength={256}
              placeholder="Enter your Linkedin url"
              defaultValue={userInfo?.linkedinUrl}
              onChange={(e) => {
                setLinkedUrl(e.target.value);
              }}
            />
          </div>{' '}
          <div className="input-block">
            <label className="form-label">Twitter URL</label>
            <input
              type="text"
              className=" block p-2 w-11/12 text-sm pl-4 text-gray-900 bg-gray-50 rounded-3xl border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              autoFocus={false}
              placeholder="Enter your Twitter url"
              defaultValue={userInfo?.twitterUrl}
              onChange={(e) => {
                setTwitterUrl(e.target.value);
              }}
            />
          </div>
        </div>
        <button
          type="submit"
          className="upload-success h-10 px-6 flex justify-center items-center profile-save-btn"
        >
          {updating ? (
            <div className="font-rubik px-6 flex justify-center items-center">
              <div
                style={{ borderTopColor: 'transparent' }}
                className="w-4 h-4 border-1 border-black border-solid rounded-full animate-spin-fast"
              ></div>
            </div>
          ) : (
            <>
              <img src={Save} alt="save" /> &nbsp; Save
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default Profile;
