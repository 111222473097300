import dayjs from 'dayjs';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { User } from 'firebase/auth';

import { currentUserInfoState } from '@/state/userState';
import { currentCaptureInfoState } from '@/state/captureState';
import { useCreator } from '@/hooks/useCreator';
import { firstCharToUpper } from '@/lib';
import UserAvatar from '../UserAvatar';
import EyeIcon from '../icon/EyeIcon';
import Profile from '../modal/Profile';

const Creator = () => {
  const creator = useCreator();
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const currentCaptureInfo = useRecoilValue(
    currentCaptureInfoState
  ) as Capture.Info;
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };

  if (!creator) return null;

  let displayName = creator.email
    ? `${firstCharToUpper(creator.email)} (guest)`
    : creator.displayName;

  if (!currentUserInfo.isAnonymous && creator.uid === currentUserInfo.uid) {
    displayName = currentUserInfo.displayName || creator.displayName;
  } else if (!creator.displayName?.startsWith('Guest-')) {
    displayName = creator.displayName;
  }
  return (
    <div className="flex flex-row cursor-default">
      <div className="flex flex-col justify-center items-end px-4">
        <span className="has-tooltip views-txt max-w-56">
          <EyeIcon /> {`${creator.viewCount} Views`}
        </span>
        <span className="text-xs user-updated-time">
          Updated {`${dayjs.unix(creator.captureCreatedAt).fromNow()}`}
          <span className="underline italic text-gray-500"></span>
        </span>
      </div>
      <div className="flex -space-x-3 overflow-hidden items-center">
        <span className="owner-avatar">
          <UserAvatar
            uid={currentCaptureInfo.creator.uid}
            customClass="inline-block h-9 w-9 rounded-full black-border"
          />
          {isOpen && <Profile isOpen={isOpen} closeModal={closeModal} />}
        </span>
        {currentCaptureInfo.view.viewer.length > 1 && (
          <UserAvatar
            uid={
              currentCaptureInfo.view.viewer[
                currentCaptureInfo.view.viewer.length - 1
              ]
            }
            customClass="inline-block h-9 w-9 rounded-full black-border "
          />
        )}
        {currentCaptureInfo.view.viewer.length > 2 && (
          <UserAvatar
            uid={
              currentCaptureInfo.view.viewer[
                currentCaptureInfo.view.viewer.length - 2
              ]
            }
            customClass="inline-block h-9 w-9 rounded-full black-border "
          />
        )}
        <div
          className="relative w-9 h-9 black-border bg-white rounded-full flex justify-center items-center text-center"
          style={{ display: currentCaptureInfo.view.count > 3 ? '' : 'none ' }}
        >
          <span className="absolute  left-0 top-0 users-count"></span>
          <span className="counter-value">
            {currentCaptureInfo.view.count > 3
              ? '+' + (currentCaptureInfo.view.count - 3)
              : null}
          </span>
        </div>{' '}
      </div>
    </div>
  );
};

export default Creator;
