import { initializeApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  signInAnonymously,
  updateProfile,
  setPersistence,
  browserLocalPersistence,
  GoogleAuthProvider,
  signInWithCredential,
  OAuthProvider,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';
import { firebaseConfig } from './config';
import { getAnonymousPopulation, setUser, updateUser } from './firestore';
import {
  isExtensionAnonymous,
  withExtension,
  extensionSignOut,
  extensionSignIn,
} from '../chromeExtension';
import type { UserInfo } from 'firebase/auth';
import localStorageService from '../localStorage/localStorage.service';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { httpsCallable } from 'firebase/functions';
import { functions as firebaseFunctions } from '@/lib/firebase/auth';

const firebaseApp = initializeApp(firebaseConfig);
export const functions = getFunctions(firebaseApp);

if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

let fromClickingSignIn = false;

let hasAuth = false;

export const getUser = () => {
  return getAuth().currentUser;
};

export const init = (
  userCb: Function,
  notUserCb: Function,
  setLinkModal: Function,
  authProblemCb: Function
) => {
  // console.log(
  //   `Firebase init Function is Starting Exectuion Having Variable${hasAuth}`
  // );
  const auth = getAuth();

  setPersistence(auth, browserLocalPersistence)
    .then((data) => {
      const unsub = onAuthStateChanged(
        auth,
        async (user) => {
          try {
            if (user) {
              hasAuth = true;
              const uid = user.uid;
              if (
                !fromClickingSignIn &&
                !user.isAnonymous &&
                withExtension &&
                isExtensionAnonymous
              ) {
                setLinkModal();
              }
              if (!user.displayName) {
                const result = await getAnonymousPopulation();
                const displayName = `Guest-${result}`;
                await updateProfile(user, {
                  displayName,
                });
                setUser(uid, { displayName });
              }

              userCb(user);
            } else {
              // console.log(hasAuth, 'Not User', user);
              if (hasAuth) {
                // authProblemCb();
                return notUserCb();
              }
              signInAnonymously(auth).catch((err) => {
                console.log('Sign in error:', err.message);
                if (
                  err.message.includes(
                    `Failed to read the 'localStorage' property from 'Window'`
                  )
                ) {
                  alert(
                    'Oops, looks like you have blocked cookies, which means we can’t load this screen. Please turn cookies back on to use Instacap. Thanks!'
                  );
                }
              });
            }
          } catch (error) {
            console.log(error);
          } finally {
            fromClickingSignIn = false;
          }
        },
        (err) => console.log('Error auth: ', err)
      );
      return unsub;
    })
    .catch((err) => {
      console.log(`Error While Setting Persistance Storage`);
      authProblemCb();
    });
};

export const linkToGoogle = async (defaultCb: Function, linkCb: Function) => {
  fromClickingSignIn = true;
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  const { currentUser } = auth;
  console.log(`Link Call Back User========================`, currentUser);

  if (!currentUser) return;

  try {
    if (currentUser) {
      provider.setCustomParameters({ prompt: 'select_account' });
      const userCredential = await signInWithPopup(auth, provider);
      const { user } = userCredential;

      const authUserCredential =
        GoogleAuthProvider.credentialFromResult(userCredential);

      if (user.providerData[0].displayName) {
        await updateProfile(user, {
          displayName: user.providerData[0].displayName,
        });
        const userInfo = user.toJSON() as UserInfo;

        const displayName = userInfo.displayName || '';
        const email = userInfo.email || '';
        const phoneNumber = userInfo.phoneNumber || '';
        const photoURL = userInfo.photoURL || '';
        // @ts-ignore

        // set free subscription (=1) if user doesn't have any subscription
        const subscription = userInfo.subscription || 1;

        await updateUser(user.uid, {
          displayName,
          email,
          phoneNumber,
          photoURL,
          subscription,
        });
      }

      //Saving Google Account credentials to LocalStorage
      localStorageService.addGoogleAccount(user, {
        userCredential,
        authUserCredential,
      });

      if (withExtension) {
        extensionSignIn(
          OAuthProvider.credentialFromResult(userCredential)?.toJSON()
        );
      }

      linkCb(user);
    }
  } catch (error: any) {
    console.error('google auth error', error);
    // @ts-ignore
    const credential = OAuthProvider.credentialFromError(error);

    if (credential) {
      signInWithCredential(auth, credential);
      if (withExtension) {
        extensionSignIn(credential.toJSON());
      }
    }
  } finally {
    console.log('Default callback =================');
    defaultCb();
  }
};

export const switchAccountWithCredentials = async (
  userCredential: UserCredential
) => {
  const auth = getAuth();
  const { currentUser } = auth;

  if (!currentUser) return;

  try {
    const authCredential =
      GoogleAuthProvider.credentialFromResult(userCredential);
    const userCredentialCur = await signInWithCredential(auth, authCredential!);

    const authUserCredential =
      GoogleAuthProvider.credentialFromResult(userCredentialCur);

    localStorageService.addGoogleAccount(currentUser, {
      userCredential: userCredentialCur,
      authUserCredential,
    });
  } catch (error) {
    console.error('Switch Account Error:', error);
    linkToGoogle(
      () => {
        window.location.reload();
      },
      () => { }
    );
  }
};

export const signOut = async (cb: Function) => {
  try {
    hasAuth = false;
    if (withExtension) {
      extensionSignOut();
    }

    const auth = getAuth();
    await auth.signOut();
    cb();
  } catch (error) {
    console.log(error);
  }
};
