import { FC, useEffect, RefObject, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { captureCommentState, currentCaptureInfoState } from '@/state/captureState';
import { imageLoadedState, sidebarState } from '@/state/uiState';
import CaptureDraggableMarker from './CaptureDraggableMarker';
import { currentUserIDState } from '@/state/userState';
import { useMarker } from '@/hooks/useMarker';
import { showCommentsTabState } from '@/state/uiState';
import { currentToolState } from '@/state/toolState';

type ImgEl = RefObject<HTMLImageElement>;

const CaptureMarkerItem: FC<
  Capture.Marker & {
    index: number;
    imgEl: ImgEl;
    updatedAt: number;
    isTemp: boolean;
    isDraggable: boolean;
    isResolved?: boolean;
    isLoomVideoAttached?: boolean;
    customStyle?: any;
    imgDimensions ?: any;
  }
> = ({
  box,
  x,
  y,
  index,
  imgEl,
  updatedAt,
  isTemp,
  isDraggable,
  isResolved,
  isLoomVideoAttached,
  customStyle,
  imgDimensions
}) => {
    const [markerPos, setMarkerPos] = useState({ x, y });
    const [ratio, setRatio] = useState({ x: 0.0, y: 0.0 });
    const { setMarkerPosition } = useMarker();

    const sidebar = useRecoilValue(sidebarState);
    const showCommentsTab = useRecoilValue(showCommentsTabState);
    const imageLoaded = useRecoilValue(imageLoadedState);
    const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);

    const calcPosition = (x: number, y: number) => {

      if (imgEl.current) {
        if(imgEl.current.width == 0 && imgEl.current.height == 0) return
        if(!currentCaptureInfo?.width || !currentCaptureInfo?.height) return

        const { width, height } = box;
        const imageW = imgEl.current.width;
        const imageH = imgEl.current.height;

        let calcX;
        let calcY;
        let scaleX;
        let scaleY;


        if(imgDimensions.width > imageW){
           scaleX = imgDimensions.width / currentCaptureInfo?.width;
           scaleY = imgDimensions.height / currentCaptureInfo?.height;
          // calculate marker position based on scale
           calcX = x * scaleX;
           calcY = y * scaleY;
        }else{
           calcX = x * (imageW / width);
           calcY = y * (imageH / height);
        }

        if(imgDimensions.width > imageW){
          setRatio({  
            x: imgDimensions.width / currentCaptureInfo?.width, 
            y: imgDimensions.height / currentCaptureInfo?.height 
          });
        }else{
            setRatio({ x: imageW / width, y: imageH / height });
        }

        // set useState
        setMarkerPos({ x: calcX, y: calcY });
        // set to state store
        setMarkerPosition(index, { x: calcX, y: calcY });
      }
    };

    useEffect(() => {
      calcPosition(x, y);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sidebar, showCommentsTab, imageLoaded, imgEl, imgDimensions.width]);

    useEffect(() => {
      calcPosition(x, y);
      const resizeHandler = () => {
        calcPosition(x, y);
      };
      window.addEventListener('resize', resizeHandler);
      return () => {
        window.removeEventListener('resize', resizeHandler);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedAt]);

    return (
      <CaptureDraggableMarker
        index={index}
        markerPos={markerPos}
        isTemp={isTemp}
        ratio={ratio}
        isDraggable={isDraggable}
        isResolved={isResolved}
        isLoomVideoAttached={isLoomVideoAttached}
        ImgEl={imgEl}
        imgDimensions={imgDimensions}
        {...customStyle && { customStyle: customStyle }}
      />
    );
  };

const CaptureMarker: FC<{ imgEl: ImgEl, style: any, imgDimensions: any }> = ({ imgEl, style, imgDimensions }) => {
  const currentUserID = useRecoilValue(currentUserIDState);
  const captureComment = useRecoilValue(captureCommentState);
  const currentTool = useRecoilValue(currentToolState);

  return (
    <>
      {captureComment.map(
        ({ marker, updatedAt, isTemp, creator, isResolved, videoHTML }, index) => (

          <CaptureMarkerItem
            key={index}
            index={index}
            imgEl={imgEl}
            updatedAt={updatedAt}
            isTemp={!!isTemp}
            isDraggable={currentUserID === creator.uid && currentTool !== "MOVE"}
            isResolved={isResolved}
            isLoomVideoAttached={!!videoHTML}
            imgDimensions={imgDimensions}
            {...marker}
            {...style && { customStyle: style }}
          />
        )
      )}
    </>
  );
};

export default CaptureMarker;
