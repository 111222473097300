import './lib/chromeExtension';
import React from 'react';
import ReactDOM from 'react-dom';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ClerkLoaded, ClerkLoading, ClerkProvider } from '@clerk/clerk-react';
import { SubscriptionProvider } from 'use-stripe-subscription';
import Loading from './components/Loading';

dayjs.extend(relativeTime);

export const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}

ReactDOM.render(
  <React.StrictMode>
    {/* <SubscriptionProvider stripePublishableKey="pk_test_51ONdEYDJyVIBw12Ev6D4JMSRHp85NV5xAq68PnhcjaJOZ81Es3N66T4yIlEtn09xdYuQrkNbTrHUQWT2IDEGbtMp006CiFmeiS"> */}
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <ClerkLoading>
        <Loading />
      </ClerkLoading>
      <ClerkLoaded>
        <App />
      </ClerkLoaded>
    </ClerkProvider>
    {/* </SubscriptionProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
