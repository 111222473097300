import { useRecoilCallback, useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import * as firebase from '@/lib/firebase/firestore';
import { pathState } from '@/state/appState';
import { tempMarkerState } from '@/state/markerState';
import { currentCaptureInfoState } from '@/state/captureState';
import { currentUserInfoState } from '@/state/userState';
import {
  commentTypeState,
  modifyCommentState,
  currentCommentState,
  commentPositionListState,
} from '@/state/commentState';
import { useIntercom } from './useIntercom';
import toast from 'react-hot-toast';

export const useComment = () => {
  const intercom = useIntercom();
  const [commentPositionList, setCommentPositionList] = useRecoilState(
    commentPositionListState
  );
  // @ts-ignore
  const setCaptureComment = useRecoilCallback(
    ({ reset, set, snapshot }) => async ({
      content,
      videoHTML = '',
      email = '',
      displayName = '',
    }) => {
      const path = await snapshot.getLoadable(pathState).contents;
      const tempMarker = (await snapshot.getLoadable(tempMarkerState)
        .contents) as Capture.Comment;
      const currentComment = await snapshot.getLoadable(currentCommentState)
        .contents;
      const currentUserInfo = await snapshot.getLoadable(currentUserInfoState)
        .contents;
      const currentCaptureInfo = await snapshot.getLoadable(
        currentCaptureInfoState
      ).contents;
      // console.log('Current Comment State:', currentComment);

      const now = dayjs().unix();
      if (tempMarker) {
        // console.log('Temprary Marker===========trmpMarker', tempMarker);
        // console.log('Name in Case of Temprary', displayName);
        // console.log(`Email in Case of Temprary Marker:${email}`);
        let comment = { ...tempMarker, content, videoHTML, updatedAt: now };

        if ((email || displayName) && !comment.creator.email) {
          // tempMarker.creator.displayName = displayName;
          comment = {
            ...tempMarker,
            content,
            videoHTML,
            updatedAt: now,
            replyEmojies: [],
            // creator: {
            //   ...tempMarker.creator,
            //   displayName,
            //   email,
            //   isAnonymous: currentUserInfo.isAnonymous,
            // },
            creator: {
              ...(currentUserInfo.isAnonymous ? {
                displayName,
                email,
                uid: null,
                isAnonymous: currentUserInfo.isAnonymous,
              } : { tempMarker })
            }
          };
        }

        delete comment.isTemp;
        // console.log('Adding New Comment------------------------', comment);
        firebase.setCaptureComment(path.uid, path.cid, comment);
        intercom.newComment(comment, currentComment.commentIndex);
        reset(tempMarkerState);
      } else {
        const {
          commentIndex,
          isPost,
          isBeingModify,
          replyIndex,
          modifyType,
          isResolved,
          type,
        } = currentComment as Capture.CurrentComment;
        // console.log(
        //   isResolved,
        //   'Current Comment Type:',
        //   isPost,
        //   modifyType,
        //   isBeingModify
        // );
        const { comments } = currentCaptureInfo;
        const newComments = [...comments];
        if (isBeingModify) {
          if (isPost) {
            if (modifyType === 'DELETE') {
              newComments.splice(commentIndex, 1);
            } else if (modifyType === 'TOGGLE_RESOLVE') {
              // console.log('Updating state Toggle Resolve');
              newComments[commentIndex] = {
                ...comments[commentIndex],
                isResolved: !isResolved,
                updatedAt: now,
              };
            } else {
              newComments[commentIndex] = {
                ...comments[commentIndex],
                content,
                updatedAt: now,
              };
            }
          } else {
            if (modifyType === 'EDIT') {
              newComments[commentIndex] = {
                ...comments[commentIndex],
                updatedAt: now,
                replies: [...comments[commentIndex].replies],
              };
              const updatedReply = {
                ...newComments[commentIndex].replies[replyIndex],
                updatedAt: now,
                content,
              };
              newComments[commentIndex].replies.splice(
                replyIndex,
                1,
                updatedReply
              );
            } else if (modifyType === 'DELETE') {
              // console.log('Modify Type:delete', modifyType);
              const updatedReplies = [...newComments[commentIndex].replies];
              updatedReplies.splice(replyIndex, 1);
              newComments[commentIndex] = {
                ...comments[commentIndex],
                replies: [...updatedReplies],
                updatedAt: now,
              };
            }
          }
        } else {
          const reply = {
            content,
            createdAt: now,
            updatedAt: now,
            creator: {
              uid: currentUserInfo.uid ?? null,
              displayName: currentUserInfo.isAnonymous
                ? displayName
                : currentUserInfo.displayName,
              email: currentUserInfo.email || email || '',
              isAnonymous: currentUserInfo.isAnonymous,
            },
          };

          newComments[commentIndex] = {
            ...comments[commentIndex],
            updatedAt: now,
            replies: [...comments[commentIndex].replies, reply],
          };
        }

        if (modifyType === 'DELETE') {
          toast.success('Successfully Deleted');
        }

        if (type !== 'NEW') {
          // reset(currentCommentState);
          if (modifyType === 'TOGGLE_RESOLVE') {
            reset(currentCommentState);
          } else {
            const currentStatePayload = {
              ...currentComment,
              isPost: false,
              isBeingModify: false,
              isResolved: newComments[commentIndex]?.isResolved,
            } 
            if(modifyType === 'DELETE'){
              currentStatePayload.modifyType = "WRITE"
            }

            set(currentCommentState, currentStatePayload);
          }
        }

        // console.log(newComments);

        firebase.setCaptureCommentReply(path.uid, path.cid, newComments);

        intercom.newReply(newComments[commentIndex], commentIndex);
      }
    }
  );

  const setCaptureCommentReply = useRecoilCallback(
    ({ snapshot }) => async ({ index, content }) => {
      const path = await snapshot.getLoadable(pathState).contents;
      const currentCaptureInfo = await snapshot.getLoadable(
        currentCaptureInfoState
      ).contents;

      const currentUserInfo = await snapshot.getLoadable(currentUserInfoState)
        .contents;

      const commentType = await snapshot.getLoadable(commentTypeState).contents;
      const modifyComment = await snapshot.getLoadable(modifyCommentState)
        .contents;

      const now = dayjs().unix();

      const { comments } = currentCaptureInfo;
      const newComments = [...comments];

      if (commentType === 'SAVE') {
        // console.log('New Comment is Saving Here')
        newComments[index] = {
          ...comments[index],
          updatedAt: now,
          replies: [...comments[index].replies],
        };
        // console.log(newComments[index].replies[modifyComment.index]);
        const newReply = {
          ...newComments[index].replies[modifyComment.index],
          updatedAt: now,
          content,
        };

        newComments[index].replies.splice(modifyComment.index, 1, newReply);
      } else if (commentType === 'REPLY') {
        const reply = {
          content,
          createdAt: now,
          updatedAt: now,
          creator: {
            uid: currentUserInfo.uid ?? null,
            displayName: currentUserInfo.displayName || '',
            email: currentUserInfo.email || '',
            isAnonymous: currentUserInfo.isAnonymous,
          },
        };
        newComments[index] = {
          ...comments[index],
          updatedAt: now,
          replies: [...comments[index].replies, reply],
        };
      }

      firebase.setCaptureCommentReply(path.uid, path.cid, newComments);
      intercom.newReply(newComments[index], index);
    }
  );
  const setCommentPosition = (
    index: number,
    position: { x: number; y: number }
  ) => {
    setCommentPositionList((pos) => [
      ...pos.slice(0, index),
      position,
      ...pos.slice(index),
    ]);
  };

  const getCommentPosition = (index: number) => {
    return commentPositionList[index];
  };

  const addCommentEmoji = useRecoilCallback(
    ({ snapshot }) => async ({ emoji = '', commentIndex = -1 }) => {
      if (commentIndex < 0 || emoji === '') return;

      const path = await snapshot.getLoadable(pathState).contents;
      const currentCaptureInfo = await snapshot.getLoadable(
        currentCaptureInfoState
      ).contents;
      const currentUserInfo = await snapshot.getLoadable(currentUserInfoState)
        .contents;

      const now = dayjs().unix();

      const { comments } = currentCaptureInfo;

      const newComments = JSON.parse(JSON.stringify(comments));

      const creator = {
        uid: currentUserInfo.uid ?? null,
        displayName: currentUserInfo.displayName || '',
        email: currentUserInfo.email || '',
        isAnonymous: currentUserInfo.isAnonymous,
      };

      //Check for existing emojiReply field
      if (Array.isArray(comments[commentIndex].replyEmojies)) {
        const currentEmojiCounter = comments[commentIndex].replyEmojies.find(
          (emojiData: Capture.ReplyEmoji) => emojiData.emoji === emoji
        );
        const currentEmojiCounterIndex = comments[
          commentIndex
        ].replyEmojies.indexOf(currentEmojiCounter);

        //Check if creator already has this emoji reply
        if (currentEmojiCounterIndex > -1) {
          const creatorIndex = newComments[commentIndex].replyEmojies[
            currentEmojiCounterIndex
          ].creators.find(
            (creatorData: Capture.Creator) => creatorData.uid == creator.uid
          );

          if (!creatorIndex) {
            let newReplyEmojies = [...newComments[commentIndex].replyEmojies];

            newReplyEmojies = newReplyEmojies.map(
              (emojiData: Capture.ReplyEmoji, index: number) => {
                if (index === currentEmojiCounterIndex) {
                  let newEmojiData = { ...emojiData };

                  // console.log(newReplyEmojies);

                  newEmojiData.count += 1;
                  newEmojiData.creators = [...emojiData.creators, creator];

                  return newEmojiData;
                } else {
                  return emojiData;
                }
              }
            );

            newComments[commentIndex] = {
              ...comments[commentIndex],
              updatedAt: now,
              replyEmojies: newReplyEmojies,
              replies: [...comments[commentIndex].replies],
            };
          }
        } else {
          const replyEmoji = {
            emoji,
            count: 1,
            creators: [creator],
          } as Capture.ReplyEmoji;

          newComments[commentIndex] = {
            ...comments[commentIndex],
            replyEmojies: [...comments[commentIndex].replyEmojies, replyEmoji],
            updatedAt: now,
            replies: [...comments[commentIndex].replies],
          };
        }
      } else {
        const replyEmoji = {
          emoji,
          count: 1,
          creators: [creator],
        } as Capture.ReplyEmoji;

        newComments[commentIndex] = {
          ...comments[commentIndex],
          replyEmojies: [replyEmoji],
          updatedAt: now,
          replies: [...comments[commentIndex].replies],
        };
      }

      // console.log(newComments);

      firebase.setCaptureCommentReply(path.uid, path.cid, newComments);

      intercom.newReply(newComments[commentIndex], commentIndex);
    }
  );

  const deleteCommentEmoji = useRecoilCallback(
    ({ snapshot }) => async ({ emoji = '', commentIndex = -1 }) => {
      if (emoji === '' || commentIndex < 0) return;

      const path = await snapshot.getLoadable(pathState).contents;
      const currentCaptureInfo = await snapshot.getLoadable(
        currentCaptureInfoState
      ).contents;
      const currentUserInfo = await snapshot.getLoadable(currentUserInfoState)
        .contents;

      const now = dayjs().unix();

      const { comments } = currentCaptureInfo;

      const newComments = JSON.parse(JSON.stringify(comments));

      //Reply emojies is not array
      if (!Array.isArray(comments[commentIndex].replyEmojies)) return;

      const currentEmojiCounter = comments[commentIndex].replyEmojies.find(
        (emojiData: Capture.ReplyEmoji) => emojiData.emoji === emoji
      );

      const currentEmojiCounterIndex = comments[
        commentIndex
      ].replyEmojies.indexOf(currentEmojiCounter);

      //Emoji counter is not found
      if (typeof currentEmojiCounter === 'undefined') return;

      const creator = newComments[commentIndex].replyEmojies[
        currentEmojiCounterIndex
      ].creators.find(
        (creatorData: Capture.Creator) =>
          creatorData.uid === currentUserInfo.uid
      );

      const creatorIndex = newComments[commentIndex].replyEmojies[
        currentEmojiCounterIndex
      ].creators.indexOf(creator);

      //Creator is not found
      if (typeof creator === 'undefined' || creatorIndex < 0) return;

      let newReplyEmojies = [...newComments[commentIndex].replyEmojies];

      newReplyEmojies = newReplyEmojies.map(
        (emojiData: Capture.ReplyEmoji, index: number) => {
          if (index === currentEmojiCounterIndex) {
            const newEmojiData = { ...emojiData };
            const newCreators = [...emojiData.creators];

            newEmojiData.count--;

            newCreators.splice(creatorIndex, 1);
            newEmojiData.creators = newCreators;

            return newEmojiData;
          } else {
            return emojiData;
          }
        }
      );

      newReplyEmojies = newReplyEmojies.filter(
        (replyEmojiData: Capture.ReplyEmoji) => replyEmojiData.count > 0
      );

      newComments[commentIndex] = {
        ...comments[commentIndex],
        updatedAt: now,
        replyEmojies: newReplyEmojies,
      };

      // console.log(newComments);

      firebase.setCaptureCommentReply(path.uid, path.cid, newComments);

      intercom.newReply(newComments[commentIndex], commentIndex);
    }
  );

  const addReplyEmoji = useRecoilCallback(
    ({ snapshot }) => async ({
      emoji,
      commentIndex,
      replyCreator,
      createdAt,
      updatedAt,
    }) => {
      if (commentIndex < 0 || emoji === '') return;

      const path = await snapshot.getLoadable(pathState).contents;
      const currentCaptureInfo = await snapshot.getLoadable(
        currentCaptureInfoState
      ).contents;
      const currentUserInfo = await snapshot.getLoadable(currentUserInfoState)
        .contents;

      const now = dayjs().unix();

      const { comments } = currentCaptureInfo;

      const newComments = JSON.parse(JSON.stringify(comments));

      const creator = {
        uid: currentUserInfo.uid ?? null,
        displayName: currentUserInfo.displayName || '',
        email: currentUserInfo.email || '',
        isAnonymous: currentUserInfo.isAnonymous,
      };

      const reply = comments[commentIndex].replies.find(
        (replyData: Capture.Reply) =>
          replyData.createdAt === createdAt &&
          replyData.updatedAt === updatedAt &&
          replyData.creator.uid === replyCreator
      );

      const replyIndex = comments[commentIndex].replies.indexOf(reply);

      //Reply not found
      if (replyIndex < 0) return;

      if (
        Array.isArray(
          newComments[commentIndex].replies[replyIndex].replyEmojies
        )
      ) {
        const currentEmojiCounter = newComments[commentIndex].replies[
          replyIndex
        ].replyEmojies.find(
          (emojiData: Capture.ReplyEmoji) => emojiData.emoji === emoji
        );

        const currentEmojiCounterIndex = newComments[commentIndex].replies[
          replyIndex
        ].replyEmojies.indexOf(currentEmojiCounter);

        if (currentEmojiCounterIndex > -1) {
          const currentCreator = newComments[commentIndex].replies[
            replyIndex
          ].replyEmojies[currentEmojiCounterIndex].creators.find(
            (creatorData: Capture.Creator) => creatorData.uid == creator.uid
          );

          if (!currentCreator) {
            let newReplyEmojies = [
              ...newComments[commentIndex].replies[replyIndex].replyEmojies,
            ];

            newReplyEmojies = newReplyEmojies.map(
              (emojiData: Capture.ReplyEmoji, index: number) => {
                if (index === currentEmojiCounterIndex) {
                  let newEmojiData = { ...emojiData };

                  // console.log(newReplyEmojies);

                  newEmojiData.count += 1;
                  newEmojiData.creators = [...emojiData.creators, creator];

                  return newEmojiData;
                } else {
                  return emojiData;
                }
              }
            );

            newComments[commentIndex].replies[replyIndex] = {
              ...comments[commentIndex].replies[replyIndex],
              updatedAt: now,
              replyEmojies: newReplyEmojies,
            };
          }
        } else {
          const replyEmoji = {
            emoji,
            count: 1,
            creators: [creator],
          } as Capture.ReplyEmoji;

          newComments[commentIndex].replies[replyIndex] = {
            ...comments[commentIndex].replies[replyIndex],
            replyEmojies: [
              ...comments[commentIndex].replies[replyIndex].replyEmojies,
              replyEmoji,
            ],
            updatedAt: now,
          };
        }
      } else {
        const replyEmoji = {
          emoji,
          count: 1,
          creators: [creator],
        } as Capture.ReplyEmoji;

        newComments[commentIndex].replies[replyIndex] = {
          ...comments[commentIndex].replies[replyIndex],
          replyEmojies: [replyEmoji],
          updatedAt: now,
        };
      }

      // console.log(newComments[commentIndex].replies);

      firebase.setCaptureCommentReply(path.uid, path.cid, newComments);

      intercom.newReply(newComments[commentIndex], commentIndex);
    }
  );

  const deleteReplyEmoji = useRecoilCallback(
    ({ snapshot }) => async ({
      emoji = '',
      commentIndex = -1,
      replyCreator,
      userUID,
      createdAt,
      updatedAt,
    }) => {
      if (emoji === '' || commentIndex < 0) return;

      const path = await snapshot.getLoadable(pathState).contents;
      const currentCaptureInfo = await snapshot.getLoadable(
        currentCaptureInfoState
      ).contents;
      const currentUserInfo = await snapshot.getLoadable(currentUserInfoState)
        .contents;

      const now = dayjs().unix();

      const { comments } = currentCaptureInfo;

      const newComments = JSON.parse(JSON.stringify(comments));

      const reply = comments[commentIndex].replies.find(
        (replyData: Capture.Reply) =>
          replyData.createdAt === createdAt &&
          replyData.updatedAt === updatedAt &&
          replyData.creator.uid === replyCreator
      );

      const replyIndex = comments[commentIndex].replies.indexOf(reply);

      // console.log(replyIndex);

      //Reply not found
      if (replyIndex < 0) return;

      //Reply emojies is not array
      if (
        !Array.isArray(
          newComments[commentIndex].replies[replyIndex].replyEmojies
        )
      )
        return;

      const currentEmojiCounter = comments[commentIndex].replies[
        replyIndex
      ].replyEmojies.find(
        (emojiData: Capture.ReplyEmoji) => emojiData.emoji === emoji
      );

      const currentEmojiCounterIndex = comments[commentIndex].replies[
        replyIndex
      ].replyEmojies.indexOf(currentEmojiCounter);

      //Emoji counter is not found
      if (typeof currentEmojiCounter === 'undefined') return;

      const creator = newComments[commentIndex].replies[
        replyIndex
      ].replyEmojies[currentEmojiCounterIndex].creators.find(
        (creatorData: Capture.Creator) => creatorData.uid === userUID
      );

      const creatorIndex = newComments[commentIndex].replies[
        replyIndex
      ].replyEmojies[currentEmojiCounterIndex].creators.indexOf(creator);

      //Creator is not found
      if (typeof creator === 'undefined' || creatorIndex < 0) return;

      let newReplyEmojies = [
        ...newComments[commentIndex].replies[replyIndex].replyEmojies,
      ];

      newReplyEmojies = newReplyEmojies.map(
        (emojiData: Capture.ReplyEmoji, index: number) => {
          if (index === currentEmojiCounterIndex) {
            const newEmojiData = { ...emojiData };
            const newCreators = [...emojiData.creators];

            newEmojiData.count--;

            newCreators.splice(creatorIndex, 1);
            newEmojiData.creators = newCreators;

            return newEmojiData;
          } else {
            return emojiData;
          }
        }
      );

      newReplyEmojies = newReplyEmojies.filter(
        (replyEmojiData: Capture.ReplyEmoji) => replyEmojiData.count > 0
      );

      newComments[commentIndex].replies[replyIndex] = {
        ...comments[commentIndex].replies[replyIndex],
        updatedAt: now,
        replyEmojies: newReplyEmojies,
      };

      // console.log(newComments);

      firebase.setCaptureCommentReply(path.uid, path.cid, newComments);

      intercom.newReply(newComments[commentIndex], commentIndex);
    }
  );

  return {
    setCaptureCommentReply,
    setCaptureComment,
    setCommentPosition,
    getCommentPosition,
    addCommentEmoji,
    deleteCommentEmoji,
    addReplyEmoji,
    deleteReplyEmoji,
  };
};
