import { FC, memo, useMemo } from 'react';

import SettingsIcon from '@/components/icon/SettingsIcon';
import Emoji from '../Emoji/Emoji';

interface EmojiFooterProps {
  emojiClickHandler: (code: number) => any;
  onHide: () => any;
}

const EmojiFooter: FC<EmojiFooterProps> = ({ emojiClickHandler, onHide }) => {
  const screenWidth = useMemo(() => window.innerWidth, [])
  const quickRepliesCodes = useMemo(() => [9989, 128077, 128285], []);

  return (
    <div className="flex flex-row items-center p-0">
      <p className={`align-middle ${screenWidth <= 595 && 'leading-6'}`}>Quick Replies</p>

      <div className="flex flex-row items-center gap-1 pl-3">
        {quickRepliesCodes.map((code, index) => (
          <button
            key={`quick reply ${index}`}
            className="emoji-btn p-0.5 px-1 pt-0"
            onClick={() => {
              emojiClickHandler(code);
              onHide();
            }}
          >
            <Emoji code={code} />
          </button>
        ))}
      </div>

      {/* Deleted for now. Maybe need to add in future */}
      {/* <button>
        <SettingsIcon />
      </button> */}
    </div>
  );
};

export default memo(EmojiFooter);
