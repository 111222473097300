import { CannyProvider, CannyWidget } from 'react-canny';

const APP_ID = process.env.REACT_APP_ID || '628440866bb7820b745c4415';
const BOARD_TOKEN = process.env.REACT_APP_BOARD_TOKEN || '560c5fb8-4053-6ad7-88ec-49fd11e411e0';

const Feedback = () => {
  return (
    <CannyProvider appId={APP_ID}>
      <CannyWidget
        basePath=''
        boardToken={BOARD_TOKEN} />
    </CannyProvider>
  );
}

export default Feedback;
