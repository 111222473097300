import { atom } from 'recoil';

export const sidebarState = atom({
  key: 'Sidebar',
  default: false,
});
export const imageLoadedState = atom({
  key: 'ImageLoaded',
  default: false,
});

export const syncWithExtensionState = atom({
  key: 'SyncWithExtension',
  default: false,
});

export const captureBoardScrollYState = atom({
  key: 'CaptureBoardScrollY',
  default: 5,
});
export const isFileUploadingState = atom<Capture.FileUploadingStates>({
  key: 'IsFileUploading',
  default: 'NO-ACTION-PERFORM',
});
export const isImageReplaceState = atom<Boolean>({
  key: 'ImageReplaceState',
  default: false,
});

export const showCommentsTabState = atom<Boolean>({
  key: 'showCommentTab',
  default: false,
});
export const deletedCaptureInfo = atom<{ isDeleted: Boolean; cid: String }>({
  key: 'DeletedCaptureInfo',
  default: { isDeleted: false, cid: '' },
});
export const deletedProject = atom<{ isDeleted: Boolean; gid: String }>({
  key: 'DeletedProject',
  default: { isDeleted: false, gid: '' },
});
export const exactMatchNotFound = atom<Boolean>({
  key: 'ExactMatchNotFound',
  default: false,
});
export const isDeletedModelOpen = atom<boolean>({
  key: 'isDeletedModelOpen',
  default: false,
});
export const isEditTitleProjectIsOpen = atom<{ open: boolean; index: Number }>({
  key: 'IsEditTitleProjectIsOpen',
  default: { open: false, index: -1 },
});
export const showMyProjectState = atom<boolean>({
  key: 'showMyProjects',
  default: true,
});
export const emojiPickerState = atom<{
  show: boolean;
  positioning: {
    targetElementRect: DOMRect;
    offset: {
      x: number;
      y: number;
    };
  };
  handleAddIcon: any;
  handleHide: () => void;
}>({
  key: 'emojiPicker',
  default: {
    show: false,
    positioning: {
      targetElementRect: {
        height: 0,
        width: 0,
        x: 0,
        y: 0,
      } as DOMRect,
      offset: {
        x: 0,
        y: 0,
      },
    },
    handleAddIcon: () => {},
    handleHide: () => {},
  },
});
export const isMouseInsideEmojiPickerState = atom<boolean>({
  key: 'isMouseInsideEmojiPicker',
  default: false,
});
export const isVideoUploadedState = atom<boolean>({
  key: 'isVideoUploaded',
  default: false,
});
export const loomRecordingState = atom<boolean>({
  key: 'loomRecordingState',
  default: false,
});
export const closeWithVideoState = atom<{
  show: boolean;
  handleAgreement: () => void;
}>({
  key: 'closeWithVideo',
  default: {
    show: false,
    handleAgreement: () => {},
  },
});
export const isSharedWithMeReadyState = atom<boolean>({
  key: 'isSharedWithMeReady',
  default: false,
});
export const isDashBoardState = atom<boolean>({
  key: 'isDashBoard',
  default: false,
});
